import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { ANTD_COMPONENTS } from 'tt-ui-lib/core';
import styles from './NavbarLink.module.scss';

const { Dropdown } = ANTD_COMPONENTS;

const NavbarLink = ({ href, Icon, itemIsMenu = false, menuItems, IconActive, onClick }) => {
  const location = useLocation();

  const [activeLink, setActiveLink] = useState(location.pathname);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    <div className={styles.navbarItem}>
      {onClick ? (
        <span
          onClick={onClick}
          className={clsx(styles.navbarLink, activeLink === href ? styles.navbarLinkActive : '')}
        >
          <Icon
            className={clsx(styles.linkIcon, activeLink === href ? styles.linkIconActive : '')}
          />
        </span>
      ) : (
        <>
          {!itemIsMenu ? (
            <Link
              to={!href ? '/menu' : href}
              className={clsx(
                styles.navbarLink,
                activeLink === href ? styles.navbarLinkActive : ''
              )}
              underline="none"
            >
              <Icon
                className={clsx(styles.linkIcon, activeLink === href ? styles.linkIconActive : '')}
                style={{ width: 20, height: 20 }}
              />
            </Link>
          ) : (
            <div className={styles.btnBox}>
              <Dropdown
                open={open}
                onOpenChange={setOpen}
                // eslint-disable-next-line react/no-unstable-nested-components
                dropdownRender={() => (
                  <div onClick={() => setOpen(false)} className={styles.dropdown}>
                    {menuItems}
                  </div>
                )}
                trigger="click"
              >
                <button
                  type="button"
                  className={styles.menuButton}
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                >
                  {open ? (
                    <IconActive style={{ width: 20, height: 20 }} />
                  ) : (
                    <Icon style={{ width: 20, height: 20 }} />
                  )}
                </button>
              </Dropdown>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NavbarLink;
