import React, { useEffect, useState } from 'react';
import styles from './NewRegisterPage.module.scss';
import { Button, Loader, Table, Input, Modal, openNotification } from 'tt-ui-lib/core';
import {
  LeftIcon,
  // MetaMaskTokensIcon,
  AddIcon,
  EditIcon,
  LitterBinIcon,
  SearchIcon,
  CopyIcon,
} from 'tt-ui-lib/icons';
import NewEntityModal from './NewEntityModal';
// import RegisterFormModal from './RegisterFormModal';
import BalanceView from '../CertificatePage/Components/BalanceView';
import EditEntityModal from './EditEntityModal';
import { handleErrorMessages } from './utils';

const showSuccess = (msg) =>
  openNotification({
    message: msg,
    type: 'success',
  });

const showError = (msg) =>
  openNotification({
    message: msg?.message || msg,
    type: 'error',
  });

const getterHash = (val) =>
  val && val.length > 10 ? `${val.substring(0, 5)}...${val.substring(val.length - 3)}` : '-';

const copyToClipboard = (test) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(test).then(
      () => showSuccess('Success copied to clipboard'),
      (err) => showError(`Could not copy text: ${err?.message}`)
    );
  } else {
    showError("Can't copy to clipboard;");
  }
};

const NewRegisterPage = ({
  navigate,
  id,
  certId,
  data,
  isLoading,
  getRegisterData,
  getRegisterEntries,
  createNewRegisterEntity,
  // getRegisterEntryData,
  updateRegisterEntity, // TODO
  deleteRegisterEntity, // TODO
  // getRegisterForm,
  saveRegister,
  backUrl = undefined,
  mode = 'DA',
  isBalanceLoading = false,
  balance,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [newEntityModalOpen, setNewEntityModalOpen] = useState(false);
  // const [registerFormModalOpen, setRegisterFormModalOpen] = useState(false);
  const [updateTable, setUpdateTable] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingModalAction, setLoadingModalAction] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [tableFilter, setTableFilter] = useState('');
  const [loadingSave, setLoadingSave] = useState(true);
  const [updateEntityModalOpen, setUpdateEntityModalOpen] = useState(false);
  const [customerEditData, setCustomerEditData] = useState(undefined);
  const [deleteEntityModalOpen, setDeleteEntityModalOpen] = useState(false);
  const [customerDeleteId, setCustomerDeleteId] = useState(undefined);

  const saveRegisterFormWrap = () => {
    setLoadingSave(true);

    const payload = {
      id: id,
    };

    saveRegister(
      payload,
      (res) => {
        setShowConfirm(false);
        getRegisterData({
          id: id,
        });
        setUpdateTable(true);
        setLoadingSave(false);
      },
      (e) => {
        openNotification({
          type: 'error',
          message: e?.message || 'Something went wrong, please try again',
        });

        setLoadingSave(false);
      }
    );
  };

  const createNewRegisterEntityWrap = (payload) => {
    setLoadingModalAction(true);

    const createData = {
      carbon_registry_id: id,
      customers_id: payload.id,
      name: payload.name,
      email: payload.email,
      wallet_address: payload.wallet,
      amount: parseInt(payload.amount, 10) || undefined,
    };

    createNewRegisterEntity(
      createData,
      (res) => {
        getRegisterData({
          id: id,
        });
        setNewEntityModalOpen(false);
        setUpdateTable(true);

        setLoadingModalAction(false);
      },
      (e) => {
        openNotification({
          type: 'error',
          message: handleErrorMessages(e),
        });

        setLoadingModalAction(false);
      }
    );
  };

  const updateRegisterEntityWrap = (payload) => {
    setLoadingModalAction(true);

    const updateData = {
      carbon_registry_id: id,
      id: payload.entity_id,
      customers_id: payload.id,
      name: payload.name,
      email: payload.email,
      wallet_address: payload.wallet,
      amount: parseInt(payload.amount, 10) || undefined,
    };

    updateRegisterEntity(
      updateData,
      (res) => {
        getRegisterData({
          id: id,
        });
        setUpdateEntityModalOpen(false);
        setUpdateTable(true);

        setLoadingModalAction(false);
        setCustomerEditData(undefined);
      },
      (e) => {
        openNotification({
          type: 'error',
          message: e?.message || 'Something went wrong, please try again',
        });

        setLoadingModalAction(false);
        setCustomerEditData(undefined);
      }
    );
  };

  const deleteRegisterEntityWrap = (entityId) => {
    setLoadingModalAction(true);

    const payloadData = {
      carbon_registry_id: id,
      id: entityId,
    };

    deleteRegisterEntity(
      payloadData,
      (res) => {
        getRegisterData({
          id: id,
        });
        setLoadingModalAction(false);
        setDeleteEntityModalOpen(false);
        setUpdateTable(true);
        setCustomerDeleteId(undefined);
      },
      (e) => {
        openNotification({
          type: 'error',
          message: e?.message || 'Something went wrong, please try again',
        });

        setLoadingModalAction(false);
        setDeleteEntityModalOpen(false);
        setCustomerDeleteId(undefined);
      }
    );
  };

  const openUpdateModal = (editData) => {
    const newEditData = {
      entity_id: editData.id,
      id: editData.customers_id,
      amount: editData.amount,
      name: editData.name,
      email: editData.email,
      wallet: editData.wallet_address,
    };

    setCustomerEditData(newEditData);
    setUpdateEntityModalOpen(true);
  };

  const openDeleteModal = (deleteId) => {
    setCustomerDeleteId(deleteId);
    setDeleteEntityModalOpen(true);
  };

  const tableColumns = [
    {
      key: '',
      dataIndex: '',
      title: ' ',
      align: 'center',
      width: 20,
    },
    {
      key: 'customers_id',
      dataIndex: 'customers_id',
      title: 'ID',
      align: 'left',
      width: 200,
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: 'Amount',
      align: 'left',
      width: 100,
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      align: 'left',
      width: 110,
      render: (value) => value || '-',
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
      align: 'left',
      width: 110,
      render: (value) => value || '-',
    },
    {
      key: 'wallet_address',
      dataIndex: 'wallet_address',
      title: 'Wallet',
      align: 'left',
      width: 100,
      render: (value) =>
        value ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <CopyIcon
              style={{ width: 20, height: 20, cursor: 'pointer' }}
              onClick={async () => {
                if (navigator.clipboard) {
                  copyToClipboard(value);
                }
              }}
            />

            <span>{getterHash(value)}</span>
          </div>
        ) : (
          '-'
        ),
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      title: '',
      width: 80,
      align: 'right',
      render: (_, row) =>
        data?.is_sent ? null : (
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            <Button type="icon">
              <EditIcon
                className="icon-blue-fill"
                style={{ width: 20, height: 20 }}
                onClick={() => openUpdateModal(row)}
              />
            </Button>
            <Button type="icon" onClick={() => openDeleteModal(row?.id)}>
              <LitterBinIcon className="icon-red-fill" style={{ width: 20, height: 20 }} />
            </Button>
          </div>
        ),
    },
  ];

  useEffect(() => {
    if (updateTable) {
      setLoadingTable(true);

      getRegisterEntries(
        { carbon_registry_id: id },
        (response) => {
          setTableData(response.data.getRegistryItemsByRegistryId || []);
          setLoadingTable(false);
        },
        (e) => {
          openNotification({
            type: 'error',
            message: e?.message || 'Something went wrong, please try again',
          });

          setLoadingTable(false);
        }
      );

      setUpdateTable(false);
    }
  }, [updateTable]);

  useEffect(() => {
    if (tableData) {
      setFilteredTableData(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    if (tableFilter) {
      const filteredData = tableData.filter(
        (row) =>
          row?.customers_id?.includes(tableFilter) ||
          row?.name?.includes(tableFilter) ||
          row?.email?.includes(tableFilter) ||
          row?.wallet_address?.includes(tableFilter)
      );

      setFilteredTableData(filteredData);
    } else {
      setFilteredTableData(tableData);
    }
  }, [tableFilter]);

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <Button
          type="icon"
          onClick={() => navigate(backUrl || '/dashboard/decarbonizator')}
          rounded
        >
          <LeftIcon style={{ width: 24, height: 24 }} />
        </Button>
        <h2>Distribution List for Certificate {certId}</h2>
      </div>

      {isLoading ? (
        <div
          className={styles.certLoadWrapper}
          style={{ height: 400, background: '#FFF', borderRadius: 12 }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.block}>
            <div className={styles.blockHeader}>
              <h2>Carbon offset certificate</h2>
              <div>
                <span>
                  {isBalanceLoading ? <Loader /> : <BalanceView balance={balance || '0'} />}
                </span>
                {/* TODO */}
                {/* {mode === 'DA' && (
                  <Button type="default">
                    <MetaMaskTokensIcon style={{ width: 22, height: 22 }} />
                  </Button>
                )} */}
              </div>
            </div>
          </div>

          <div className={styles.block}>
            <div className={styles.mainBlock}>
              <h2>Distribution List</h2>
              <p>
                Add customers and their email addresses to your distribution list manually one by
                one by clicking each time on ”Add Customer” or download our form, fill-in accurately
                and upload by clicking on “Distribution List Form”. Before uploading the file make
                sure that the data and the format of the list fully matches the format below. Then
                click on “Save” button. Once saved your Distribution List will be available in the
                tab “Distribution Lists for Offset” from where it may be edited or deleted.
              </p>
            </div>
          </div>

          <div className={styles.block}>
            <div className={styles.mainBlock}>
              <div className={styles.flexRow} style={{ fontSize: 20, fontWeight: 600 }}>
                <span style={{ wordBreak: 'break-all', width: '100%' }}>
                  {data?.name || 'Distribution'}
                </span>
                <span style={{ wordBreak: 'break-all', minWidth: 140 }}>{data?.date || '-'}</span>
              </div>

              <div style={{ wordBreak: 'break-all', width: '100%' }}>
                {data?.description || '-'}
              </div>

              <div className={styles.tableWrapper}>
                <div className={styles.flexRow}>
                  <Input
                    placeholder="Search"
                    prefix={<SearchIcon style={{ width: 20, height: 20, flexShrink: 0 }} />}
                    style={{ width: '100%' }}
                    value={tableFilter}
                    onChange={(e) => setTableFilter(e.target.value)}
                  />

                  {!data?.is_sent && (
                    <Button
                      icon={
                        <AddIcon className="icon-blue-fill" style={{ width: 22, height: 22 }} />
                      }
                      onClick={() => setNewEntityModalOpen(true)}
                    >
                      Add customer
                    </Button>
                  )}
                </div>

                <Table
                  data={filteredTableData}
                  columns={tableColumns}
                  loading={loadingTable}
                  scroll={{ x: 870 }}
                />

                {/* <div className={styles.tableBottomTooltip}>
                  Distributed amount: {data?.amount || '-'}
                </div> */}
              </div>

              {tableData.length > 0 && !data?.is_sent && (
                <>
                  <div className={styles.saveRow}>
                    <Button
                      onClick={() => {
                        navigate(backUrl);
                      }}
                    >
                      Cancel
                    </Button>

                    <Button type="primary" onClick={() => setShowConfirm(true)}>
                      Distribute certificates
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      <Modal
        title="Do you really want to send certificates?"
        open={showConfirm}
        onClose={() => setShowConfirm(false)}
      >
        <p style={{ marginTop: 10, fontSize: 16, fontWeight: 400 }}>
          Once certificates have been sent, the registry becomes uneditable. To send certificates to
          new users, you must create a new registry.
        </p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 30,
          }}
        >
          <Button onClick={() => setShowConfirm(false)}>Cancel</Button>
          <Button type="primary" onClick={() => saveRegisterFormWrap()} loading={loadingSave}>
            Send Certificates
          </Button>
        </div>
      </Modal>

      <NewEntityModal
        registerName={data?.name || ''}
        open={newEntityModalOpen}
        setOpen={setNewEntityModalOpen}
        onSubmit={createNewRegisterEntityWrap}
        isLoading={loadingModalAction}
      />

      <EditEntityModal
        registerName={data?.name || ''}
        open={updateEntityModalOpen}
        setOpen={setUpdateEntityModalOpen}
        onSubmit={updateRegisterEntityWrap}
        isLoading={loadingModalAction}
        data={customerEditData}
      />

      <Modal
        title="Do you really want to delete customer?"
        open={deleteEntityModalOpen}
        onClose={() => {
          setDeleteEntityModalOpen(false);
          setCustomerDeleteId(undefined);
        }}
      >
        {/* <p style={{ marginTop: 10, fontSize: 16, fontWeight: 400 }}>
          Once certificates have been sent, the registry becomes uneditable. To send certificates to
          new users, you must create a new registry.
        </p> */}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 20,
          }}
        >
          <Button onClick={() => setShowConfirm(false)}>Cancel</Button>
          <Button
            type="primary"
            onClick={() => deleteRegisterEntityWrap(customerDeleteId)}
            loading={loadingModalAction}
          >
            Delete
          </Button>
        </div>
      </Modal>

      {/* <RegisterFormModal
        open={registerFormModalOpen}
        setOpen={setRegisterFormModalOpen}
        getData={getRegisterForm}
        onSubmit={saveRegisterFormWrap}
        isLoading={loadingModalAction}
      /> */}
    </div>
  );
};

export default NewRegisterPage;
