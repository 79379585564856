import React from 'react';
import { Button, Modal } from 'tt-ui-lib/core';

const ConfirmMoveModal = ({
  open,
  setOpen,
  confirmHandler,
  connectWalletHandler,
  mode,
  isMoveLoading,
  walletStatus,
}) => (
  <Modal title="Move to MetaMask wallet" open={open} onClose={() => setOpen(false)}>
    {mode ? (
      <p style={{ marginTop: 10, fontSize: 16, fontWeight: 400 }}>
        You are about to move the carbon credit balance to your MetaMask wallet. By clicking on
        “Move to wallet ” below you will be redirected to MetaMask and following confirmation there,
        the balance of your carbon credit will be removed from “Decarbonizator” and will appear in
        the “Digital Assets” section.
      </p>
    ) : (
      <>
        {walletStatus === 'unavailable' ? (
          <p style={{ marginTop: 10, fontSize: 16, fontWeight: 400 }}>
            To proceed, please install the MetaMask plugin in your browser and connect your wallet.
          </p>
        ) : (
          <p style={{ marginTop: 10, fontSize: 16, fontWeight: 400 }}>
            Please connect your wallet to proceed.
          </p>
        )}
      </>
    )}

    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 30,
      }}
    >
      <Button onClick={() => setOpen(false)}>Cancel</Button>

      {mode ? (
        <Button type="primary" onClick={() => confirmHandler()} loading={isMoveLoading}>
          Move to wallet
        </Button>
      ) : (
        <>
          {walletStatus === 'unavailable' ? (
            <Button
              type="primary"
              onClick={() => window.open('https://metamask.io')}
              loading={isMoveLoading}
            >
              Install MetaMask Wallet
            </Button>
          ) : (
            <Button type="primary" onClick={() => connectWalletHandler()} loading={isMoveLoading}>
              Connect wallet
            </Button>
          )}
        </>
      )}
    </div>
  </Modal>
);

export default ConfirmMoveModal;
