import React, { useEffect, useRef, useState } from 'react';
import { ContactSupportModal } from './components/contactSupportModal/ContactSupportModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styles from './styles.module.scss';
import { SupportChatIcon } from './components/Icons/supportChatIcon';
import { SupportChatLogo } from './components/Icons/supportChatLogo';
import { SupportChatCloseIcon } from './components/Icons/supportChatCloseIcon';
import { MessageLikeIcon } from './components/Icons/messageLikeIcon';
import { MessageDisLikeIcon } from './components/Icons/messageDisLikeIcon';
import { MessagePrintedIcon } from './components/Icons/messagePrintedIcon';
import { SendMessageIcon } from './components/Icons/sendMessageIcon';
import { ReactComponent as FullScreenIcon } from './images/fullScreen.svg';
import { api } from './api';

const messageSchema = yup.object().shape({
  message: yup.string().min(2).max(255).required(),
});
const isUrl = (message) => {
  const regexQuery =
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  return message.match(regexQuery);
};
const getCategories = async (apiSecret, apiUrl) =>
  // eslint-disable-next-line no-return-await
  await api(apiSecret, apiUrl).get('docs/categories/list');
const getFullTime = () => {
  const newDate = new Date();
  return `${newDate.getHours()}:${
    newDate.getMinutes() > 9 ? newDate.getMinutes() : `0${newDate.getMinutes()}`
  }`;
};

// eslint-disable-next-line react/function-component-definition
export default function SupportChat({
  them,
  showSupportChat,
  setShowSupportChat,
  companyName,
  apiUrl,
  apiSecret,
  supportMail,
  buttonStyle,
  user,
  scrollUpMobile,
  scrollUpTablet,
}) {
  const [typed, setTyped] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const chatWindowBody = useRef();
  const [showContactSupportModal, setShowContactSupportModal] = useState(false);
  const [chatMessage, setChatMessage] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(messageSchema),
    defaultValues: {
      message: '',
    },
  });
  const messageStyle = (messageItem, type = '') => {
    switch (messageItem.type) {
      case 'User':
        return {
          backgroundColor: them.messageBackgroundColor,
        };
      case 'Automatic':
        if (messageItem.messageType === 'Standard') {
          if (type === 'button') {
            return {
              color: them.sendButtonColor,
            };
          }
          return {
            border: `1px solid ${them.sendButtonColor}`,
            color: them.sendButtonColor,
          };
        }
        if (messageItem.messageType === 'Press') {
          return {
            border: `1px solid ${them.sendButtonColor}`,
            backgroundColor: them.sendButtonColor,
            color: 'white',
          };
        }
        return {};
      case 'linksMessage':
        if (messageItem.type === 'Support') {
          return {
            flexDirection: 'column',
          };
        }
        return {};
      default:
        return {};
    }
  };

  useEffect(() => {
    setTyped(true);
    new Promise((resolve) => {
      setTimeout(() => {
        setChatMessage((current) => [
          ...current,
          {
            id: 1,
            type: 'Support',
            messageType: 'Standard',
            time: getFullTime(),
            message:
              "Hello, I'm your TT.Concierge. I am here to provide support and insight into the FCE Ecosystem and TransparenTerra. Please note I am not a financial advisor.",
          },
        ]);
      }, 1000);
      setTimeout(() => {
        setChatMessage((current) => [
          ...current,
          {
            id: 2,
            type: 'Support',
            messageType: 'Standard',
            time: getFullTime(),
            message:
              'You can ask in most European languages: English, German, French, Italian, Spanish.',
          },
        ]);
        setTyped(false);
        resolve(true);
      }, 2000);
    }).then((res) => {
      getCategories(apiSecret, apiUrl).catch((err) => {
        console.log('err:::::', err);
      });
    });
  }, []);

  useEffect(() => {
    if (chatWindowBody.current?.scrollHeight) {
      chatWindowBody.current.scrollTop = chatWindowBody.current?.scrollHeight;
    }
    if (chatMessage.length > 3) {
      const understandQuestionAnswer = `I'm sorry, I'm not sure I understand your question. Could you please rephrase it or provide more context so that I can provide a more accurate response?`;
      const lastAnswerMessage = chatMessage[chatMessage.length - 1].message;
      const previousAnswerMessage = chatMessage[chatMessage.length - 3].message;
      if (
        lastAnswerMessage === understandQuestionAnswer &&
        previousAnswerMessage === understandQuestionAnswer
      ) {
        setChatMessage((current) => [
          ...current,
          {
            id: current[current.length - 1].id + 1,
            type: 'Automatic',
            messageType: 'Standard',
            message: 'Contact Support',
          },
        ]);
      }
    }
  }, [chatMessage]);

  // eslint-disable-next-line no-shadow
  const getAnswer = async (apiSecret, apiUrl, formDate) => {
    setTimeout(() => {
      setTyped(true);
    }, 500);
    await api(apiSecret, apiUrl)
      .post('qa/answer', formDate)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.text === 'Contact Support') {
            setShowContactSupportModal(true);
          } else {
            const youtubeRawLinks = res.data.data.linksYoutube;
            const otherRawLinks = res.data.data.linksUrl;
            const linksYt = youtubeRawLinks ? [...youtubeRawLinks] : [];
            const links = otherRawLinks ? [...otherRawLinks] : [];

            setChatMessage((current) => [
              ...current,
              {
                id: current[current.length - 1].id + 1,
                type: 'Support',
                messageType: 'Standard',
                time: getFullTime(),
                message: res.data.data.text,
                extraLinks: [...links, ...linksYt],
              },
            ]);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setTyped(false);
      });
  };

  const submit = async (data) => {
    setChatMessage((current) => [
      ...current,
      {
        id: current[current.length - 1].id + 1,
        type: 'User',
        messageType: 'Standard',
        time: getFullTime(),
        message: data.message,
      },
    ]);
    reset();
    // eslint-disable-next-line no-undef
    const formDate = new FormData();
    formDate.append('q', data.message);
    await getAnswer(apiSecret, apiUrl, formDate);
  };

  const tooltipOnMouseOver = (index) => {
    const child = document.querySelector(`#tooltipBlock_${index}`);
    const tooltip = document.querySelector(`#tooltip_${index}`);
    const cloneTooltip = tooltip.cloneNode(true);
    console.log(tooltip);
    const body = document.querySelector(`body`);
    const x0 = child.getBoundingClientRect().left;
    const y0 = child.getBoundingClientRect().top;
    cloneTooltip.style.transform = `translate(${x0}px, ${y0 - 39}px)`;
    cloneTooltip.style.display = 'block';
    cloneTooltip.id = `tooltip_show_${index}`;
    console.log(x0, y0);
    body.appendChild(cloneTooltip);
  };

  const tooltipOnMouseOut = (index) => {
    const body = document.querySelector(`body`);
    const tooltip = document.querySelector(`#tooltip_show_${index}`);
    if (tooltip) {
      body.removeChild(tooltip);
    }
  };

  const messagePressFunc = async (messageItem) => {
    console.log('Logging message:', messageItem.message);
    if (messageItem.type === 'Automatic' && messageItem.messageType === 'Standard') {
      if (messageItem.message === 'Contact Support') {
        setShowContactSupportModal(true);
      } else {
        setChatMessage([
          ...chatMessage,
          {
            id: chatMessage.length + 1,
            type: 'Automatic',
            messageType: 'Press',
            message: messageItem.message,
          },
        ]);
        // eslint-disable-next-line no-undef
        const formDate = new FormData();
        formDate.append('q', messageItem.message);
        formDate.append('category_id', messageItem.id);
        await getAnswer(apiSecret, apiUrl, formDate);
      }
    }
  };
  return (
    <>
      {!showSupportChat ? (
        <button
          type="button"
          className={styles.supportChatButton}
          style={
            scrollUpMobile
              ? { bottom: 109, right: 4, backgroundColor: them.supportButton, ...buttonStyle }
              : scrollUpTablet
                ? { right: 45, bottom: 83, backgroundColor: them.supportButton, ...buttonStyle }
                : { backgroundColor: them.supportButton, ...buttonStyle }
          }
          onClick={(e) => {
            e.stopPropagation();
            setShowSupportChat(true);
          }}
        >
          <SupportChatIcon />
        </button>
      ) : (
        <div
          className={`${styles.chatWindow} ${fullScreen ? styles.fullScreenWindow : ''}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={styles.chatWindowHeader} style={{ background: them.headerGradient }}>
            <SupportChatLogo />
            <div className={styles.chatWindowHeaderContent}>
              <div className={styles.chatHeaderTitle}>
                <h2>TT Concierge</h2>
                <p>Your Chat GPT empowered assistant</p>
              </div>
              <div className={styles.chatHeaderBtns}>
                <button
                  type="button"
                  className={styles.fullScreenIcon}
                  onClick={() => setFullScreen((prevState) => !prevState)}
                >
                  <FullScreenIcon />
                </button>
                <button
                  type="button"
                  className={styles.closeChatWindow}
                  onClick={() => setShowSupportChat(false)}
                >
                  <SupportChatCloseIcon />
                </button>
              </div>
            </div>
          </div>

          <div className={styles.chatWindowBody}>
            <div className={styles.chatWindowMessage} ref={chatWindowBody}>
              {chatMessage.map((messageItem) => (
                <div className={styles[`message${messageItem.type}`]} key={messageItem.id}>
                  <div
                    onClick={() => messagePressFunc(messageItem)}
                    className={styles.message}
                    style={messageStyle(messageItem)}
                  >
                    <div className={styles.messageWrapper}>
                      <div
                        className={styles.messageContent}
                        style={messageStyle(messageItem, 'button')}
                      >
                        {messageItem.message.split(' ').map((ms, i) =>
                          isUrl(ms) ? (
                            // eslint-disable-next-line react/no-array-index-key
                            <a href={ms} key={`${ms}_${i}`} target="_blank" rel="noreferrer">
                              {`${ms} `}
                            </a>
                          ) : (
                            `${ms} `
                          )
                        )}
                      </div>
                      {messageItem.extraLinks?.length > 0 ? (
                        <div className={styles.extraLinks}>
                          {messageItem.extraLinks.map((link, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={index}>
                              <a
                                href={link.url}
                                target="_blank"
                                rel="noreferrer"
                                id={`tooltipBlock_${index}`}
                                onMouseOver={() => tooltipOnMouseOver(index)}
                                onMouseOut={() => tooltipOnMouseOut(index)}
                                onFocus={() => tooltipOnMouseOver(index)}
                                onBlur={() => tooltipOnMouseOut(index)}
                              >
                                {link.title}
                              </a>
                              <span className={styles.tooltip} id={`tooltip_${index}`}>
                                {link.title}
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                    {messageItem.messageType === 'linksMessage' ? (
                      <div className={styles.linksMessage}>
                        {messageItem.links.map((link) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <a href={link.url} key={link.id}>
                            <span>{link.id}.</span> <span>{link.name}</span>
                          </a>
                        ))}
                      </div>
                    ) : (
                      <div className={styles.messageTime}>
                        <p>{messageItem.time}</p>
                      </div>
                    )}
                  </div>
                  {messageItem.messageType === 'buttonMessage' ? (
                    <button type="button" style={{ color: them.sendButtonColor }}>
                      {messageItem.buttonText}
                    </button>
                  ) : messageItem.messageType === 'rateMessage' ? (
                    <div className={styles.reateMessage}>
                      <p style={{ color: them.sendButtonColor }}>{messageItem.buttonText}</p>

                      <div
                        style={{
                          border: `1px solid ${them.sendButtonColor}`,
                          color: them.sendButtonColor,
                        }}
                      >
                        <button type="button">
                          <MessageLikeIcon color={them.sendButtonColor} />
                        </button>
                        <button type="button">
                          <MessageDisLikeIcon color={them.sendButtonColor} />
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>

            {showContactSupportModal && (
              <ContactSupportModal
                setShowContactSupportModal={setShowContactSupportModal}
                color={them.sendButtonColor}
                supportMail={supportMail}
                user={user}
              />
            )}

            <>
              <div className={styles.messagePrinted}>
                {typed && (
                  <>
                    <MessagePrintedIcon />
                    <p>{companyName} is typing...</p>
                  </>
                )}
              </div>
              <div className={styles.chatWindowFooter}>
                <form onSubmit={handleSubmit(submit)}>
                  <input
                    autoComplete="off"
                    name="message"
                    type="text"
                    placeholder="Enter your message..."
                    {...register('message')}
                  />
                  <button
                    type="submit"
                    className={styles.messageSendBtn}
                    style={{
                      backgroundColor: !isValid ? '#C4C4C4' : them.sendButtonColor,
                      cursor: isValid ? 'pointer' : 'no-drop',
                    }}
                    disabled={!isValid}
                  >
                    <SendMessageIcon />
                  </button>
                </form>
              </div>
            </>
          </div>
        </div>
      )}
    </>
  );
}
