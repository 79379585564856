import React from 'react';
import styles from '../SendCreditsModal.module.scss';
import { Button, Select, Input } from 'tt-ui-lib/core';

const SendStep = ({ formData, setFormData, checkSend, modalClose, credits, balance, loading }) => {
  const currencies = [
    { value: 'g', label: 'grams' },
    { value: 'kg', label: 'kg' },
    { value: 't', label: 'tonnes' },
  ];

  return (
    <div className={styles.body}>
      <div className={styles.modalInfo}>
        <div className={styles.infoItem}>
          <span>Credits:</span> <span>{credits || '-'}</span>
        </div>

        <div className={styles.infoItem}>
          <span>Credit balance:</span> <span>{balance || '-'}</span>
        </div>
      </div>

      <div className={styles.controls}>
        <Input
          label="Recipient wallet"
          value={formData.wallet}
          onChange={(e) =>
            setFormData((prevVal) => ({
              ...prevVal,
              wallet: e.target.value,
            }))
          }
        />

        <Input
          label="Amount to send *"
          value={formData.amount}
          onChange={(e) => {
            const { value } = e.target;

            if (!/^[0-9]*$/.test(value) && value) {
              return;
            }

            setFormData((prevVal) => ({
              ...prevVal,
              amount: value,
            }));
          }}
          suffix={
            <Select
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={styles.nestedSelect}
              options={currencies}
              value={formData.unit}
              onChange={(value) =>
                setFormData((prevVal) => ({
                  ...prevVal,
                  unit: value,
                }))
              }
            />
          }
        />
      </div>

      <div className={styles.bottom}>
        <Button type="default" onClick={() => modalClose()}>
          Cancel
        </Button>

        <Button
          type="primary"
          onClick={() => {
            checkSend();
          }}
          loading={loading}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default SendStep;
