import React, { useState } from 'react';
import { DialogActions, IconButton, Tooltip } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import CallMenu from '../CallMenu';
import AddMembersMenu from '../AddMembersMenu';
import styles from './Footer.module.scss';

const Footer = ({
  deviceExist,
  answerCall,
  rejectCall,
  cancelCall,
  endCall,
  shareScreen,
  muteAudio,
  unmuteAudio,
  offVideo,
  onVideo,
  videoOff,
  status,
  audioMuted,
  timer,
  size,
  changeSize,
}) => {
  const [isAddUserMenuOpen, setIsAddUserMenuOpen] = useState(false);

  const addUser = () => {
    changeSize('xl');
    setIsAddUserMenuOpen(!isAddUserMenuOpen);
  };

  return (
    <DialogActions className={styles.dialogFooter}>
      {size === 'xl' && (
        <div className={styles.dialogLeftActions}>
          {audioMuted ? (
            <Tooltip
              title={deviceExist.includes('audio') && 'Device not found'}
              classes={{ tooltip: styles.tooltip }}
              placement="top"
            >
              <div>
                <IconButton
                  disabled={deviceExist.includes('audio')}
                  onClick={() => unmuteAudio()}
                  className={`${styles.actionButton} ${styles.actionButtonBlue}`}
                >
                  <img
                    src="/images/chat/mic-white-icon.svg"
                    alt=""
                    style={{ width: 13, height: 13 }}
                  />
                  {deviceExist.includes('audio') && (
                    <img
                      src="/images/chat/warning-icon.svg"
                      alt=""
                      style={{ width: 19, height: 19 }}
                      className={styles.warningIcon}
                    />
                  )}
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <IconButton
              disabled={deviceExist.includes('audio')}
              onClick={() => muteAudio()}
              className={`${styles.actionButton} ${styles.actionButtonDisabled}`}
            >
              <img
                src="/images/chat/mic-white-mute-icon.svg"
                alt=""
                style={{ width: 13, height: 13 }}
              />
            </IconButton>
          )}
          {videoOff ? (
            <Tooltip
              title={deviceExist.includes('video') && 'Device not found'}
              classes={{ tooltip: styles.tooltip }}
              placement="top"
            >
              <div>
                <IconButton
                  disabled={deviceExist.includes('video')}
                  onClick={() => onVideo()}
                  className={`${styles.actionButton} ${styles.actionButtonBlue}`}
                >
                  <VideocamIcon sx={{ color: '#ffffff', fontSize: 17 }} />
                  {deviceExist.includes('video') && (
                    <img
                      src="/images/chat/warning-icon.svg"
                      alt=""
                      style={{ width: 19, height: 19 }}
                      className={styles.warningIcon}
                    />
                  )}
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <IconButton
              disabled={deviceExist.includes('video')}
              onClick={() => offVideo()}
              className={`${styles.actionButton} ${styles.actionButtonDisabled}`}
            >
              <img src="/images/chat/video-off-icon.svg" alt="" style={{ width: 13, height: 13 }} />
            </IconButton>
          )}
        </div>
      )}
      <div className={styles.dialogActions}>
        {size === 'xs' &&
          (audioMuted ? (
            <Tooltip
              title={deviceExist.includes('audio') && 'Device not found'}
              classes={{ tooltip: styles.tooltip }}
              placement="top"
            >
              <div>
                <IconButton
                  disabled={deviceExist.includes('audio')}
                  onClick={() => unmuteAudio()}
                  className={`${styles.actionButton} ${styles.actionButtonBlue}`}
                >
                  <img
                    src="/images/chat/mic-white-icon.svg"
                    alt=""
                    style={{ width: 13, height: 13 }}
                  />
                  {deviceExist.includes('audio') && (
                    <img
                      src="/images/chat/warning-icon.svg"
                      alt=""
                      style={{ width: 19, height: 19 }}
                      className={styles.warningIcon}
                    />
                  )}
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <IconButton
              disabled={deviceExist.includes('audio')}
              onClick={() => muteAudio()}
              className={`${styles.actionButton} ${styles.actionButtonDisabled}`}
            >
              <img
                src="/images/chat/mic-white-mute-icon.svg"
                alt=""
                style={{ width: 13, height: 13 }}
              />
            </IconButton>
          ))}
        {size === 'xs' &&
          (videoOff ? (
            <Tooltip
              title={deviceExist.includes('video') && 'Device not found'}
              classes={{ tooltip: styles.tooltip }}
              placement="top"
            >
              <div>
                <IconButton
                  disabled={deviceExist.includes('video')}
                  style={deviceExist.includes('video') ? { pointerEvents: 'none' } : {}}
                  onClick={() => onVideo()}
                  className={`${styles.actionButton} ${styles.actionButtonBlue}`}
                >
                  <VideocamIcon sx={{ color: '#ffffff', fontSize: 17 }} />
                  {deviceExist.includes('video') && (
                    <img
                      src="/images/chat/warning-icon.svg"
                      alt=""
                      style={{ width: 19, height: 19 }}
                      className={styles.warningIcon}
                    />
                  )}
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <IconButton
              disabled={deviceExist.includes('video')}
              onClick={() => offVideo()}
              className={`${styles.actionButton} ${styles.actionButtonDisabled}`}
            >
              <img src="/images/chat/video-off-icon.svg" alt="" style={{ width: 13, height: 13 }} />
            </IconButton>
          ))}
        {status === 'answered' && (
          <IconButton
            onClick={addUser}
            className={`${styles.actionButton} ${styles.actionButtonBlue}`}
          >
            <img src="/images/chat/add-user-icon.svg" alt="" style={{ width: 13, height: 13 }} />
          </IconButton>
        )}
        {status === 'answered' && (
          <IconButton
            onClick={() => shareScreen()}
            className={`${styles.actionButton} ${styles.actionButtonBlue}`}
          >
            <img
              src="/images/chat/share-screen-icon.svg"
              alt=""
              style={{ width: 13, height: 13 }}
            />
          </IconButton>
        )}
        {status === 'answered' && <CallMenu />}
        {status === 'answered' && (
          <IconButton
            className={`${styles.actionButton} ${styles.actionButtonRed}`}
            onClick={() => endCall()}
          >
            <img
              src="/images/chat/call-end-white-icon.svg"
              alt=""
              style={{ width: 13, height: 13 }}
            />
          </IconButton>
        )}
        {status === 'incoming' && (
          <IconButton
            className={`${styles.actionButton} ${styles.actionButtonRed}`}
            onClick={() => rejectCall()}
          >
            <img
              src="/images/chat/call-end-white-icon.svg"
              alt=""
              style={{ width: 13, height: 13 }}
            />
          </IconButton>
        )}
        {status === 'outgoing' && (
          <IconButton
            className={`${styles.actionButton} ${styles.actionButtonRed}`}
            onClick={() => cancelCall()}
          >
            <img
              src="/images/chat/call-end-white-icon.svg"
              alt=""
              style={{ width: 13, height: 13 }}
            />
          </IconButton>
        )}
        {status === 'incoming' && (
          <IconButton
            className={`${styles.actionButton} ${styles.actionButtonGreen}`}
            onClick={() => answerCall()}
          >
            <img src="/images/chat/call-white-icon.svg" alt="" style={{ width: 13, height: 13 }} />
          </IconButton>
        )}
      </div>
      {status === 'answered' && size === 'xl' && (
        <div className={styles.timer}>
          <img src="/images/chat/watch-icon.svg" alt="" style={{ width: 20, height: 20 }} />
          <span>{timer}</span>
        </div>
      )}
      <AddMembersMenu open={isAddUserMenuOpen} onClose={() => setIsAddUserMenuOpen(false)} />
    </DialogActions>
  );
};
export default Footer;
