import React from 'react';
import { IconButton } from '@mui/material';
import styles from './Header.module.scss';

const Header = ({ size, status, changeSize, timer }) => {
  const handleChangeSize = async () => {
    const newSize = size === 'xl' ? 'xs' : 'xl';
    changeSize(newSize);
  };

  return (
    <div className={styles.dialogHeader}>
      {status === 'answered' && size === 'xs' && (
        <div className={styles.timer}>
          <img src="/images/chat/watch-icon.svg" alt="" style={{ width: 20, height: 20 }} />
          <span>{timer}</span>
        </div>
      )}
      <p className={styles.dialogTitle}>Meet “WebOnline”</p>
      <div className={styles.dialogResizer}>
        <IconButton onClick={() => handleChangeSize('xl')}>
          {size === 'xs' ? (
            <img src="/images/chat/resize-full-icon.svg" alt="" style={{ width: 16, height: 16 }} />
          ) : (
            <img
              src="/images/chat/resize-small-icon.svg"
              alt=""
              style={{ width: 16, height: 16 }}
            />
          )}
        </IconButton>
      </div>
    </div>
  );
};

export default Header;
