import React, { useState, useEffect } from 'react';
import SendCreditsModal from './SendCredits/SendCreditsModal';
import { useParams } from 'react-router-dom';

import {
  Button,
  HelpTooltip,
  Loader,
  Tabs,
  Tooltip,
  Collapse,
  openNotification,
} from 'tt-ui-lib/core';
import { DownloadIcon, LeftIcon, MetaMaskTokensIcon, ShareIcon } from 'tt-ui-lib/icons';

import styles from './CertificatePage.module.scss';
import FCEMLogo from './Components/FcemLogo';
import InfoModal from './Components/InfoModal';
import RegisterModal from './Components/RegisterModal';
import GetModal from './Components/GetModal';
import ConfirmMoveModal from './Components/ConfirmMoveModal';
import RegisterTab from './Components/RegisterTab';
import BalanceView, { calculateBalanceWithUnit } from './Components/BalanceView';
import { downloadFileFromURL } from 'modules/tt-tokenized-assets/utils';

const { REACT_APP_SSO_API, REACT_APP_MARKET } = process.env;

const tabs = [
  {
    name: 'CO2 Credit Balance',
    id: 'credit',
  },
  {
    name: 'Offsets',
    id: 'offset',
  },
  {
    name: 'Distribution',
    id: 'distribution',
  },
];

const buildCertOffsetImgLink = (payload) => {
  const parentProjectData = JSON.parse(payload?.parentCertificate?.project || '{}');

  return `${REACT_APP_SSO_API}/tt-result/api/certificates/offset-digitization/jpg?certificate_no=${
    payload?.id
  }&issued_by=${payload?.issued_by}&project=${parentProjectData?.name}&project_link=${
    payload?.parentCertificate?.project_link || '-'
  }&beneficiary=${payload?.beneficiary || '-'}&company=${
    payload?.issued_for || '-'
  }&amount=${`${calculateBalanceWithUnit(payload?.quantity)} (${payload?.quantity} gramms)`}&description=${
    payload?.description
  }&digital=${payload?.parentCertificate?.smart_contract_address}&date=${payload?.date}`;
};

const OffsetImage = ({ item }) => {
  const imgLink = buildCertOffsetImgLink(item);

  const [status, setStatus] = useState('loading');

  const finishLoading = () => {
    setStatus('done');
  };

  return (
    <div>
      <div className={styles.offsetCertBlock}>
        <div>{item?.date}</div>
        <div>
          <Button
            type="icon"
            onClick={() => {
              downloadFileFromURL(imgLink, item?.id);
            }}
            rounded
          >
            <DownloadIcon style={{ width: 22, height: 22 }} />
          </Button>
          <Button
            type="icon"
            onClick={() => {
              navigator.clipboard.writeText(imgLink).then(() => {
                openNotification({
                  type: 'success',
                  message: 'Link copied in clipboard',
                });
              });
            }}
            rounded
          >
            <ShareIcon style={{ width: 22, height: 22 }} />
          </Button>
        </div>
      </div>

      <div>
        <div style={{ display: status === 'loading' ? 'block' : 'none' }}>
          <Loader />
        </div>

        <img
          className={styles.offsetImage}
          src={buildCertOffsetImgLink(item)}
          alt=""
          onLoad={finishLoading}
          onError={finishLoading}
          style={{ display: status === 'loading' ? 'none' : 'block' }}
        />
      </div>
    </div>
  );
};

const CertificatePage = ({
  navigate,
  account,
  data,
  isLoading,
  backUrl,
  certLoading,
  sendCheck = undefined,
  sendConfirm = undefined,
  getCertificate,
  getOffsetDataCertificate,
  createRegister,
  moveToWallet = undefined,
  connectWalletHandler = undefined,
  detachWallet = undefined,
  showCreditInMetamask = undefined,
  getCertificateRegisters,
  offsetData,
  mode = 'DA',
  setUpdateCertImage,
  setUpdateCertData,
  isMoveLoading,
  walletStatus,
}) => {
  const { id } = useParams();

  const [selectedTab, setSelectedTab] = useState(0);

  const [openSendModal, setOpenSendModal] = useState(false);
  const [openConfirmMoveModal, setOpenConfirmMoveModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openGetModal, setOpenGetModal] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);

  const parsedInternalData = JSON.parse(data?.project || '{}') || null;
  const certLogoUrl = parsedInternalData?.type?.logo
    ? `https://dev-market-back.s3.eu-central-1.amazonaws.com/carbon/logo/${parsedInternalData?.type?.logo}` // TODO replace domain with env
    : '';

  const handleChangeTabs = (newValue) => {
    setSelectedTab(newValue);
  };

  const moveToWalletWrap = () => {
    setOpenConfirmMoveModal(true);
  };

  useEffect(() => {
    if (selectedTab === 1) {
      setUpdateCertImage(true);
    }
  }, [selectedTab]);

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <Button
          type="icon"
          onClick={() => navigate(backUrl || '/dashboard/decarbonizator')}
          rounded
        >
          <LeftIcon style={{ width: 24, height: 24 }} />
        </Button>
        <h2>Certificate {id}</h2>
      </div>

      {isLoading ? (
        <div
          className={styles.certLoadWrapper}
          style={{ height: 400, background: '#FFF', borderRadius: 12 }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.mainContainer}>
            <div className={styles.mainContainerHeader}>
              <div>Digitized carbon credit and information about it.</div>
            </div>

            <div className={styles.mainInfoContainer}>
              <div className={styles.certImageBlock} onClick={() => setOpenInfoModal(true)}>
                <img src={certLogoUrl} alt="" />
              </div>

              <div className={styles.certDataBlock}>
                <div className={styles.infoItem}>
                  <div>Vintage</div>
                  <span>
                    {parsedInternalData?.vintage_from || ''}
                    {' - '}
                    {parsedInternalData?.vintage_to || ''}
                  </span>
                </div>
                <div className={styles.infoItem}>
                  <div>Standard</div>
                  <span>{parsedInternalData?.standard?.name || '-'}</span>
                </div>
                <div className={styles.infoItem}>
                  <div>Tokenization date</div>
                  <span>
                    {mode === 'DA' ? `${data?.created_at}`.split('T')[0] : data?.created_at || '-'}
                  </span>
                </div>

                <div className={styles.divider} />

                <div className={styles.infoItem}>
                  <div>Supplier</div>
                  <span>{parsedInternalData?.provider?.name || '-'}</span>
                </div>
                <div className={styles.infoItem}>
                  <div>Project Type</div>
                  <span>{parsedInternalData?.type?.name || '-'}</span>
                </div>
                <div className={styles.infoItem}>
                  <div>Project ID</div>
                  <span>{parsedInternalData?.type?.id || '-'}</span>
                </div>
                <div className={styles.infoItem}>
                  <div>Region</div>
                  <span>{parsedInternalData?.region?.name || '-'}</span>
                </div>
                <div className={styles.infoItem}>
                  <div>Goals</div>
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      columnGap: '10px',
                    }}
                  >
                    {parsedInternalData?.goals?.length > 0
                      ? parsedInternalData?.goals?.map(
                          (item, index) =>
                            index < 4 && (
                              <Tooltip
                                title={item.name.replaceAll('_', ' ').toUpperCase()}
                                placement="top"
                                arrow
                              >
                                <div
                                  style={{ cursor: 'pointer' }}
                                  // onClick={() => scrollToGoalDescription(item.id)}
                                >
                                  <img
                                    alt={item.name}
                                    src={`/images/company/${item.name}.svg`}
                                    style={{ borderRadius: '4px', width: 28, height: 28 }}
                                  />
                                </div>
                              </Tooltip>
                            )
                        )
                      : '-'}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.tabs}>
            <Tabs tabs={tabs} selectedTab={selectedTab} onChangeTab={handleChangeTabs} />
          </div>

          {selectedTab === 0 ? (
            <div className={styles.bottomContent}>
              <div className={styles.block}>
                <div className={styles.blockHeader}>
                  <h2>Carbon Credit balance</h2>
                  <div>
                    <BalanceView balance={data?.carbon_balance || data?.quantity || '-'} />

                    {mode === 'DA' && (
                      <Button
                        type="default"
                        onClick={() => {
                          if (showCreditInMetamask) showCreditInMetamask();
                        }}
                        disabled={!data}
                      >
                        <MetaMaskTokensIcon style={{ width: 22, height: 22 }} />
                      </Button>
                    )}
                  </div>
                </div>

                {data?.deposit && data?.deposit > 0 && (
                  <div className={styles.depositBlock}>
                    <div>
                      <FCEMLogo /> <span>FCEM deposit</span> <HelpTooltip tooltipText="Soon..." />
                    </div>
                    <div>{data?.deposit || '-'} FCEM</div>
                  </div>
                )}

                <div className={styles.blockText}>
                  <p>
                    Here are the details of your сarbon credit and its current balance. From here
                    you can manage your carbon credit. You may transfer its current balance to your
                    MetaMask wallet by clicking on “Move to MetaMask wallet” or offset it in full or
                    in part (the smallest offset fraction is 1 gram) by clicking on “Offset CO2
                    credit”. Want to buy more carbon credits? Visit our{' '}
                    <a
                      href={`${REACT_APP_MARKET}/dashboard/marketplace/list/product?activeTab=carbon`}
                    >
                      Carbon Credit Market
                    </a>
                    .
                  </p>
                </div>
                <div className={styles.blockFooter}>
                  <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                    {mode === 'DA' ? (
                      <>
                        {/* <Button disabled={!data} onClick={() => setOpenSendModal(true)}>
                          Send
                        </Button> */}
                        <Button
                          disabled
                          onClick={() => {
                            if (detachWallet) detachWallet();
                          }}
                        >
                          Detach wallet
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          disabled={!data}
                          onClick={() => {
                            moveToWalletWrap();
                          }}
                        >
                          Move to MetaMask wallet
                        </Button>
                      </>
                    )}
                  </div>
                  <Button
                    disabled={!data}
                    type="primary"
                    onClick={() => {
                      setOpenGetModal(true);
                    }}
                  >
                    Offset the credit
                  </Button>
                </div>
              </div>
            </div>
          ) : selectedTab === 1 ? (
            <div className={styles.bottomContent}>
              <div className={styles.block}>
                <div className={styles.blockHeader}>
                  <h2>Carbon offset certificate</h2>
                  <div>
                    <BalanceView balance={data?.offset_balance || '0'} />

                    {mode === 'DA' && (
                      <Button
                        type="default"
                        onClick={() => {
                          if (showCreditInMetamask) showCreditInMetamask();
                        }}
                        disabled={!data}
                      >
                        <MetaMaskTokensIcon style={{ width: 22, height: 22 }} />
                      </Button>
                    )}
                  </div>
                </div>

                {offsetData?.length > 0 && (
                  <div className={styles.blockText}>
                    This is where you&apos;ll find your redeemed certificates.
                  </div>
                )}

                {certLoading ? (
                  <div>
                    <Loader />
                  </div>
                ) : offsetData?.length > 0 ? (
                  <Collapse
                    appearance="expanded"
                    items={offsetData.map((item, index) => ({
                      key: index,
                      label: `${item?.beneficiary} (${calculateBalanceWithUnit(item?.quantity)})`,
                      children: <OffsetImage item={item} key={`${index + 1}`} />,
                    }))}
                  />
                ) : (
                  <>
                    <p>
                      Here we store all offset certificates issued with respect to the carbon
                      credit. <br /> To offset your carbon footprint by using your carbon credit, go
                      to the “CO2 credit balance” tab and click <br /> on the “Offset CO2 credit”
                      button.
                    </p>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className={styles.bottomContent}>
              <RegisterTab
                id={id}
                data={data}
                setOpenRegisterModal={setOpenRegisterModal}
                getCertificateRegisters={getCertificateRegisters}
              />
            </div>
          )}
        </>
      )}

      <InfoModal
        open={openInfoModal}
        setOpen={setOpenInfoModal}
        id={id}
        image={certLogoUrl}
        balance={calculateBalanceWithUnit(data?.carbon_balance || data?.quantity)}
        offsetted={calculateBalanceWithUnit(data?.offset_balance || '0')}
        transactions={[]} // TODO
      />

      <RegisterModal
        open={openRegisterModal}
        setOpen={setOpenRegisterModal}
        id={id}
        createRegister={createRegister}
      />

      <GetModal
        open={openGetModal}
        setOpen={setOpenGetModal}
        offset={data?.credit_id || '-'}
        balance={calculateBalanceWithUnit(data?.carbon_balance || data?.quantity)}
        id={id}
        getCertificate={getCertificate}
        getOffsetDataCertificate={getOffsetDataCertificate}
        setSelectedTab={setSelectedTab}
        certData={data}
        setUpdateCertData={setUpdateCertData}
      />

      <SendCreditsModal
        open={openSendModal}
        setOpen={setOpenSendModal}
        credits={data?.credit_id || '-'}
        balance={calculateBalanceWithUnit(data?.quantity)}
        id={id}
        sendCheck={sendCheck}
        sendConfirm={sendConfirm}
      />

      <ConfirmMoveModal
        open={openConfirmMoveModal}
        setOpen={setOpenConfirmMoveModal}
        confirmHandler={moveToWallet}
        connectWalletHandler={connectWalletHandler}
        mode={!!account}
        isMoveLoading={isMoveLoading}
        walletStatus={walletStatus}
      />
    </div>
  );
};

export default CertificatePage;
