import React, { useState } from 'react';
import { Modal } from 'tt-ui-lib/core';
import SendStep from './steps/SendStep';
import ConfirmStep from './steps/ConfirmStep';

const SendCreditsModal = ({ open, setOpen, credits, balance, id, sendCheck, sendConfirm }) => {
  const [formValues, setFormValues] = useState({
    wallet: '',
    amount: '',
    unit: 'g',
  });
  const [confirmData, setConfirmData] = useState(null);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [step, setStep] = useState('send');
  const [loading, setLoading] = useState(false);

  const calculateQuantity = (quantity) => {
    const parsedQuantity = parseInt(quantity, 10);

    switch (formValues.unit) {
      case 't':
        return parsedQuantity * 1000000;

      case 'kg':
        return parsedQuantity * 1000;

      case 'g':
        return parsedQuantity;

      default:
        return parsedQuantity;
    }
  };

  const modalClose = () => {
    setOpen(false);
    setTimeout(() => setStep('send'), 500);
  };

  const checkSend = () => {
    setLoading(true);

    sendCheck(
      { id: id, ...formValues, amount: calculateQuantity(formValues?.calculateQuantity) },
      (res) => {
        setConfirmData(res.data);
        setLoading(false);
        setStep('confirm');
      },
      () => {
        setLoading(false);
      }
    );
  };

  const submit = () => {
    setLoading(true);
    sendConfirm(
      confirmData,
      () => {
        setLoading(false);
        modalClose();
      },
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <Modal open={open} onClose={modalClose} title="Send credits">
      {step === 'send' ? (
        <SendStep
          formData={formValues}
          setFormData={setFormValues}
          modalClose={modalClose}
          credits={credits}
          balance={balance}
          loading={loading}
          checkSend={checkSend}
        />
      ) : (
        <ConfirmStep
          confirmData={confirmData}
          setStep={setStep}
          submitDisable={submitDisable}
          loading={loading}
          onSubmit={submit}
        />
      )}
    </Modal>
  );
};

export default SendCreditsModal;
