import React, { useState } from 'react';
import styles from './CartActionMenu.module.scss';
import { Button, ANTD_COMPONENTS } from 'tt-ui-lib/core';
import { MoreVIcon, ShareIcon, LitterBinIcon } from 'tt-ui-lib/icons';

const { Dropdown } = ANTD_COMPONENTS;

const CartActionMenu = ({ clearCart }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleClearCart = () => {
    if (typeof clearCart === 'function') {
      clearCart();
    }
    handleClose();
  };

  return (
    <Dropdown
      open={open}
      onOpenChange={setOpen}
      menu={{
        items: [
          {
            label: (
              <div className={styles.shareBtn}>
                <ShareIcon />
                <p>Share</p>
              </div>
            ),
            key: 'share',
            disabled: true,
          },
          {
            label: (
              <div className={styles.trashBtn} onClick={handleClearCart}>
                <LitterBinIcon />
                <p>Clear cart</p>
              </div>
            ),
            key: 'clear-cart',
          },
        ],
      }}
      trigger="click"
    >
      <Button type="icon" style={{ padding: '0px', width: 'fit-content', height: 'fit-content' }}>
        <MoreVIcon className={styles.dotIcon} />
      </Button>
    </Dropdown>
  );
};

export default CartActionMenu;
