import React from 'react';
import { Cart } from './components/Cart';

export const CartWidget = ({
  cartItems,
  deleteItemRequest,
  fetchCartDataWidget,
  notMarket,
  cartIsLoading,
  setCartDeleteTriggered,
}) => (
  <Cart
    cartIsLoading={cartIsLoading}
    notMarket={notMarket}
    cartItems={cartItems}
    deleteItemRequest={deleteItemRequest}
    fetchCartDataWidget={fetchCartDataWidget}
    setCartDeleteTriggered={setCartDeleteTriggered}
  />
);
