/* eslint-disable react/no-unknown-property */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-debugger */

import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import TransactionDetailModal from '../TransactionDetailModal';
import CarbonTransactionDetailModal from '../../CarbonCertificate/private/TransactionDetailModal';
import {
  useDigitalAssets,
  useDigitalAssetsAPI,
  useDigitalAssetsCaFFee,
  useDigitalAssetsWallet,
} from 'modules/tt-digital-assets-provider';
import userContext from 'context/User/userContext';

import styles from './styles.module.scss';
import { useWindowSize } from '../../../../utils/useWindowSize';
import { Block, CheckBox, Table, DatePicker, Input } from 'tt-ui-lib/core';
import { CloseCrossIcon, CopyIcon, DownloadIcon, ReloadIcon, SearchIcon } from 'tt-ui-lib/icons';

const TransactionHistoryTable = (props) => {
  const { className, style, forceReload, setForceReload } = props;

  const {
    chainSettings,
    account,
    copyToClipboard,
    getterHash,
    getterDate,
    getterValue,
    downloadArrAsCSVFile,
    isNaN,
    fromWei,
    json2obj,
  } = useDigitalAssets();

  const {
    getRegisterWalletState,
    getRegisterWalletStateIcon,
    getRegisterWalletStateText,
    getBalance,
  } = useDigitalAssetsWallet();

  const { getCO2CertInfo } = useDigitalAssetsCaFFee();
  const { getLatestTransaction } = useDigitalAssetsAPI();
  const { width } = useWindowSize();
  const { user } = useContext(userContext);

  const [transactionHistory, setTransactionHistory] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableCurrentPage, setTableCurrentPage] = useState(1);
  const [showTransactionDetail, setShowTransactionDetail] = useState(false);
  const [showCarbonTransactionDetail, setShowCarbonTransactionDetail] = useState(false);

  const [myTransaction, setMyTransaction] = useState(false);
  const [transactionHash, setTransactionHash] = useState('');
  const [search, setSearch] = useState('');
  const [date, setDate] = useState(null);
  const [dateStr, setDateStr] = useState('');

  const [carbonInfo, setCarbonInfo] = useState({
    hash: '',
    hash_short: '',
    datetime: '',
    caffee: '',
    token: '',
    symbol: '',
    offsetted_transaction: '',
    cert: {},
  });

  const [filteredWallet, setFilteredWallet] = useState('');
  const [filteredWalletState, setFilteredWalletState] = useState(6);
  const [filteredWalletStateIcon, setFilteredWalletStateIcon] = useState(null);
  const [filteredWalletBalance, setFilteredWalletBalance] = useState(null);

  /**
   * @param {Object} bFilter
   * @return {Promise<[{hash:String, ts:String, from:String, to:String, method:String, value:Number|BigInt, value_all:Number|BigInt, blocknumber:Number|BigInt, co2tokens:Number, co2address:String, co2cost:Number|BigInt}]>}
   * @private
   */
  const _getTransactionHistory = async (bFilter, trDate, limit) => {
    try {
      const arr = [];

      let wallet;

      if (typeof bFilter === 'string') {
        wallet = bFilter;
        setFilteredWallet(wallet);
        arr.push(getBalance(wallet));
        arr.push(getRegisterWalletState(wallet));
      } else {
        wallet = user?.id
          ? bFilter === undefined
            ? myTransaction
              ? account
              : ''
            : bFilter
              ? account
              : ''
          : '';
      }

      arr.push(getLatestTransaction(wallet, trDate, limit));

      setTableLoading(true);
      const res = await Promise.all(arr);

      /** @type {[{hash:String, ts:String, from:String, to:String, method:String, value:Number|BigInt, value_all:Number|BigInt, blocknumber:Number|BigInt, co2tokens:Number, co2address:String, co2cost:Number|BigInt}]} */
      let data;
      if (res.length === 3) {
        setFilteredWalletBalance(res[0] || 0);
        setFilteredWalletState(res[1]);
        setFilteredWalletStateIcon(getRegisterWalletStateIcon(res[1]));

        data = (res && res[2]) || [];
      } else {
        data = (res && res[0]) || [];
      }

      data = data.map((el) => {
        if (el) {
          let amnt = el.value !== null ? fromWei(el.value || 0) : '-';
          // eslint-disable-next-line no-param-reassign
          el.value = isNaN(amnt) ? '-' : String(amnt);

          amnt = el?.value_all !== null ? fromWei(el?.value_all || 0) : '-';
          // eslint-disable-next-line no-param-reassign
          el.value_all = isNaN(amnt) ? '-' : String(amnt);
        }
        return el;
      });

      setTableLoading(false);

      return data;
    } catch {
      setTableLoading(false);
    }
  };

  const getTransactionHistory = async (bFilter, trDate = null) => {
    setTransactionHistory(await _getTransactionHistory(bFilter, trDate, 1000));

    setTableCurrentPage(1);
  };

  const getCSVTransactionHistory = async (bFilter) =>
    downloadArrAsCSVFile(
      await _getTransactionHistory(bFilter, undefined, 'all'),
      'transactionHistory.csv'
    );

  // eslint-disable-next-line no-underscore-dangle
  const _findTransactionByHash = async (evnt) => {
    let hash = '';
    // eslint-disable-next-line no-restricted-globals
    if (evnt?.target?.tagName === 'IMG' || evnt?.target?.tagName === 'SPAN') {
      const field = evnt?.target?.parentElement?.getAttribute('field');
      if (field) {
        /** @type {[{hash:String, ts:String, from:String, to:String, method:String, value:Number|BigInt, value_all:Number|BigInt, blocknumber:Number|BigInt, co2tokens:Number, co2address:String, co2cost:Number|BigInt}]} */
        const data = json2obj(evnt?.target?.parentElement?.getAttribute('data')) || {
          hash: null,
          ts: null,
          from: null,
          to: null,
          method: null,
          value: null,
          value_all: null,
          blocknumber: null,
          co2tokens: null,
          co2address: null,
          co2cost: null,
        };
        hash = data[field];
      } else {
        hash = evnt?.target?.parentElement?.querySelector('input')?.value || '';
      }
    } else if (evnt.key === 'Enter') {
      hash = evnt.target.value;
    }

    if (hash) {
      if (/^0x[a-fA-F0-9]{40}$/.test(hash)) {
        // Фильтрация по конкретному кошельку
        await getTransactionHistory(hash);
      } else {
        setTransactionHash(hash);
        setShowTransactionDetail(true);
      }
    }
  };

  const _findTransaction = (hash) => {
    setTransactionHash(hash);
    setShowTransactionDetail(true);
  };

  const _findWallet = (wallet) => {
    setTableCurrentPage(1);

    setFilteredWallet(wallet);
    getTransactionHistory(wallet).then();
  };

  const clearTransactionFindField = async () => {
    setTableCurrentPage(1);

    setFilteredWallet('');
    setSearch('');
    setDate(null);
    setDateStr('');
    document.getElementsByName('inputTransactionByHash')?.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.value = '';
    });
    return getTransactionHistory();
  };

  useEffect(() => {
    getTransactionHistory(filteredWallet || undefined, dateStr).then();
  }, [dateStr]);

  useEffect(() => {
    getTransactionHistory().then();
  }, []);

  const showTransactionCarbonCertificate = async (row) => {
    if (!row || !row.co2address) return;

    const res = (await getCO2CertInfo(row.co2address)) || {};
    res.to = 'TransparenTerra';
    res.address = row.co2address;

    const carbonFullInfo = {
      hash: row.hash,
      hash_short: getterHash(row.hash),
      datetime: (row.ts || '').replace('T', ' / ').split('.')[0] || '',
      caffee: fromWei(row.co2cost) || '',
      token: row.co2tokens,
      symbol: chainSettings.nativeCurrency.symbol,
      offsetted_transaction: '0.841775642',
      cert: res,
    };

    setCarbonInfo(carbonFullInfo);

    setShowCarbonTransactionDetail(true);
  };

  const getterCarbonValue = (val, row) =>
    val ? (
      <span
        className={clsx(styles.link, 'link')}
        onClick={() => showTransactionCarbonCertificate(row)}
      >
        {val ? `${val} CaFFee` : ''}
      </span>
    ) : (
      '-'
    );

  const columns = [
    {
      key: 'hash',
      dataIndex: 'hash',
      title: 'Txn Hash',
      align: 'left',
      width: 110,
      render: (value) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <CopyIcon
            style={{ width: 20, height: 20, cursor: 'pointer' }}
            onClick={async () => {
              if (navigator.clipboard) {
                copyToClipboard(value);
              }
            }}
          />

          <span className={clsx(styles.link, 'link')} onClick={() => _findTransaction(value)}>
            {getterHash(value)}
          </span>
        </div>
      ),
    },
    {
      key: 'method',
      dataIndex: 'method',
      title: 'Method',
      align: 'left',
      width: 140,
    },
    {
      key: 'ts',
      dataIndex: 'ts',
      title: 'Date',
      align: 'left',
      width: 120,
      render: (value) => <span>{getterDate(value)}</span>,
    },
    {
      key: 'from',
      dataIndex: 'from',
      title: 'From',
      align: 'left',
      width: 120,
      render: (value) => (
        <span className={clsx(styles.link, 'link')} onClick={() => _findWallet(value)}>
          {getterHash(value)}
        </span>
      ),
    },
    {
      key: 'to',
      dataIndex: 'to',
      title: 'To',
      align: 'left',
      width: 120,
      render: (value) => (
        <span className={clsx(styles.link, 'link')} onClick={() => _findWallet(value)}>
          {getterHash(value)}
        </span>
      ),
    },
    {
      key: 'value',
      dataIndex: 'value',
      title: `Value ${chainSettings.nativeCurrency.symbol}`,
      align: 'left',
      width: 140,
      render: (value) => <span>{getterValue(value)}</span>,
    },
    {
      key: 'co2tokens',
      dataIndex: 'co2tokens',
      title: 'Txn Fee',
      align: 'left',
      width: 140,
      render: (value, row) => <span>{getterCarbonValue(value, row)}</span>,
    },
  ];

  useEffect(() => {
    console.log(`TransactionHistoryTable: forceReload: ${forceReload}`);

    // eslint-disable-next-line no-debugger
    // debugger;
    if (forceReload) {
      // eslint-disable-next-line no-debugger
      // debugger;
      getTransactionHistory().then();
      // eslint-disable-next-line no-unused-expressions
      setForceReload && setForceReload(false);
    }
  }, [forceReload]);

  return (
    <div
      className={`${styles.TransactionHistoryTable} ${className} TransactionHistoryTable`}
      style={{ ...style }}
    >
      <div className={clsx(styles.row1, 'row1')}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span className={clsx(styles.h2, 'h2', styles.header)} style={{ marginBottom: 0 }}>
            Latest Transactions
          </span>

          <ReloadIcon
            style={{
              width: 20,
              height: 20,
              marginLeft: '5px',
              display: filteredWallet ? 'none' : 'inherit',
              cursor: 'pointer',
            }}
            onClick={() => getTransactionHistory()}
          />

          <DownloadIcon
            style={{
              width: 20,
              height: 20,
              marginLeft: '5px',
              display: filteredWallet ? 'none' : 'inherit',
              cursor: 'pointer',
            }}
            onClick={() => getCSVTransactionHistory()}
          />

          <span style={{ display: filteredWallet && window.innerWidth > 375 ? 'inherit' : 'none' }}>
            : Search results
          </span>
        </div>

        {filteredWallet ? (
          <div>
            <Input
              placeholder="Search..."
              value={filteredWallet}
              onKeyUp={_findTransactionByHash}
              prefix={<SearchIcon style={{ width: 22, height: 22 }} />}
              suffix={
                <div onClick={clearTransactionFindField}>
                  <CloseCrossIcon style={{ width: 20, height: 20, cursor: 'pointer' }} />
                </div>
              }
              style={{ width: 250 }}
            />
          </div>
        ) : (
          <div className={styles.searchRow}>
            {user.id && account && (
              <CheckBox
                label="My transactions"
                style={{ display: user?.id ? '' : 'none' }}
                value={myTransaction}
                onChange={async (evnt) => {
                  setMyTransaction(!!evnt.target.checked);
                  return getTransactionHistory(!!evnt.target.checked);
                }}
              />
            )}

            <DatePicker
              label="Date"
              value={date}
              onChange={(val, valStr) => {
                setDate(val);
                setDateStr(valStr);
              }}
            />

            <Input
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyUp={_findTransactionByHash}
              prefix={<SearchIcon style={{ width: 22, height: 22 }} />}
              suffix={
                <div onClick={clearTransactionFindField}>
                  <CloseCrossIcon style={{ width: 20, height: 20, cursor: 'pointer' }} />
                </div>
              }
              style={{ width: 250 }}
            />
          </div>
        )}
      </div>

      <div
        style={{
          display: filteredWallet && window.innerWidth <= 875 ? '' : 'none',
          fontWeight: 'bold',
        }}
      >
        Search result
      </div>

      <div style={{ display: filteredWallet ? '' : 'none' }}>
        {width < 876 ? (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', height: '40px' }}>
              Wallet: {`${filteredWallet.substring(0, 12)}...${filteredWallet.substring(30)}`}
              <span style={{ marginLeft: '5px', width: 18, height: 18 }}>
                {filteredWalletStateIcon}
              </span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', height: '40px' }}>
              Balance:{' '}
              <span title={filteredWalletBalance?.toString() || '0'}>
                {(filteredWalletBalance || 0).toFixed(5)}&nbsp;
                {chainSettings.nativeCurrency.symbol}
              </span>
            </div>
          </div>
        ) : (
          <>
            <div>
              <span className={clsx(styles.bold, 'bold')}>Wallet:</span> {filteredWallet}
              <span style={{ marginLeft: '5px', width: 18, height: 18 }}>
                {filteredWalletStateIcon}
              </span>
            </div>
            <div style={{ marginTop: '10px' }}>
              <span className={clsx(styles.bold, 'bold')}>Balance:</span>
              &nbsp;
              {filteredWalletBalance || '-'}
              &nbsp;
              {chainSettings.nativeCurrency.symbol}
            </div>
          </>
        )}
      </div>

      <div style={{ width: '100%', overflowX: 'auto' }}>
        <Table
          loading={tableLoading}
          columns={columns}
          data={transactionHistory || []}
          scroll={{ x: 870, y: '40vh' }}
          pagination={{
            position: ['bottomCenter'],
            current: tableCurrentPage,
            pageSize: 10,
            total: transactionHistory?.length,
            responsive: true,
            showSizeChanger: false,
            hideOnSinglePage: true,
            onChange: (page) => setTableCurrentPage(parseInt(page, 10)),
          }}
        />
      </div>

      <TransactionDetailModal
        showTransactionDetail={showTransactionDetail}
        setShowTransactionDetail={setShowTransactionDetail}
        transactionHash={transactionHash}
      />

      <CarbonTransactionDetailModal
        showCarbonTransactionDetail={showCarbonTransactionDetail}
        setCarbonShowTransactionDetail={setShowCarbonTransactionDetail}
        certificateInfo={carbonInfo}
      />
    </div>
  );
};

export default TransactionHistoryTable;
