export const handleErrorMessages = (error) => {
  if (error?.message) {
    if (error.message.includes('input.amount')) {
      return 'Amount must be a number less then 1 000 000 000 and more then 0';
    }

    return error.message;
  }

  return 'Something went wrong, please try again';
};
