import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'tt-ui-lib/core';
import { roles } from '../../../constants/roles';
import { useWindowSize } from '../../../utils/useWindowSize';

import styles from '../SignInModal.module.scss';

export const SignUpModal = ({ setMode }) => {
  const { width } = useWindowSize();

  const [selectedRole, setSelectedRole] = useState('');
  const [submitDisable, setSubmitDisable] = useState(true);
  // TODO: uncomment
  // useEffect(() => {
  //   if (selectedRole) {
  //     setSubmitDisable(false);
  //   } else {
  //     setSubmitDisable(true);
  //   }
  // }, [selectedRole]);

  const submitStep = () => {
    setMode(`signUp_${selectedRole}`);
  };

  return (
    <div className={styles.formWrapper}>
      <div className={styles.formDescription}>
        <span>
          Book an initial interview and share more information that will help prepare for our
          meeting: your role, experience, expectations, and needs.
        </span>
      </div>
      <form name="role" className={styles.form}>
        <Select
          className={styles.roleSelect}
          label="Role"
          onChange={(value) => setSelectedRole(value)}
          value={selectedRole}
          options={roles?.map((item) => ({ label: item.name, value: item.value })) || []}
        />
      </form>
      <div className={styles.formBottom} style={{ marginTop: 20 }}>
        <div />
        <Button
          className={styles.formSubmitButton}
          type="primary"
          disabled={submitDisable}
          onClick={() => submitStep()}
        >
          {/* {width > 768 ? 'Send a request' : 'Send'} */}
          Book an interview
        </Button>
      </div>
    </div>
  );
};
