import React from 'react';

export const calculateBalanceWithUnit = (balance) => {
  const parsedBalance = parseInt(balance, 10);

  if (typeof parsedBalance !== 'number') {
    return `${parsedBalance || '-'}`;
  }

  if (parsedBalance > 1000000) {
    return `${Math.round(parsedBalance / 1000000)} tonnes`;
  }

  if (parsedBalance > 1000) {
    return `${Math.round(parsedBalance / 1000)} kg`;
  }

  return `${parsedBalance} gramms`;
};

const BalanceView = ({ balance }) => <span>{calculateBalanceWithUnit(balance)}</span>;

export default BalanceView;
