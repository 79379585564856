/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-prototype-builtins */

import React, { useEffect } from 'react';
import { useMetaMask } from 'metamask-react';
import WalletOperationsContext from './WalletOperationsContext';
import { isAddress, isBN, toBN } from '../../utils/web3_helper';
import { chainSettings, showError } from '../../utils/common';
import { sendContractMethod } from '../../utils/request';

import { useWallet } from '../Wallet/WalletProvider';
import Web3 from 'web3';

const WalletOperationsProvider = (props) => {
  const { children, web3, setWeb3 } = props;

  const { chainId, ethereum, account, status } = useMetaMask();

  const { claimAmount } = useWallet();

  /**
   * Восстановление баланса кошелька
   * @return {Promise<*>}
   */
  const doClaimBalance = async () => {
    if (chainSettings?.chainId === chainId) {
      if (claimAmount) {
        return sendContractMethod(
          web3,
          status,
          account,
          'Claim',
          'restoreBalance',
          null,
          false
        ).catch((err) => showError(err));
      }
    }
  };

  /**
   * Простой перевод (старый контракт)
   * @param {String|Number|BigInt} amount      - Сумма перевода
   * @param {String|Number|BigInt} fullAmount  - Сумма перевода, полная
   * @param {String}               recipient   - Получатель перевода
   * @param {Number}              [type]       - Тип перевода
   * @param {String}               description - Описание перевода
   * @return {Promise<*>}
   */
  const simplePaymentOld = async (amount, fullAmount, recipient, type, description) => {
    if (chainSettings?.chainId === chainId) {
      const amnt = toBN(amount);
      const amntFl = toBN(fullAmount);
      // eslint-disable-next-line no-param-reassign
      description = description || '-';

      if (isAddress(recipient) && isBN(amnt) && isBN(amntFl)) {
        console.log(`send simplePayment`);
        console.dir({
          amount: amount,
          fullAmount: fullAmount,
          recipient: recipient,
          type: type,
          description: description,
        });

        return sendContractMethod(
          web3,
          status,
          account,
          'SimplePaymentFactory',
          'makePayment',
          [type || 0, recipient, true, description],
          false,
          amntFl
        ).catch((err) => showError(err));
      }
      showError(`Wrong parameters`);
    }
  };

  /**
   * Простой перевод
   * @param {String|Number|BigInt} amount      - Сумма перевода
   * @param {String|Number|BigInt} fullAmount  - Сумма перевода, полная
   * @param {String}               recipient   - Пoлучатель перевода
   * @param {Number}              [type]       - Тип перевода
   * @param {String}               description - Описание перевода
   * @return {Promise<*>}
   */
  const simplePayment = async (
    amount,
    fullAmount,
    recipient,
    type,
    description,
    version = null
  ) => {
    if (chainSettings?.chainId === chainId) {
      const amnt = toBN(amount);
      const amntFl = toBN(fullAmount);
      // eslint-disable-next-line no-param-reassign
      description = description || '-';

      if (isAddress(recipient) && isBN(amnt) && isBN(amntFl)) {
        console.log(`send simplePayment`);
        console.dir({
          amount: amount,
          fullAmount: fullAmount,
          recipient: recipient,
          type: type,
          description: description,
        });

        return sendContractMethod(
          web3,
          status,
          account,
          'SimplePaymentFactory',
          'makePayment',
          [type || 0, recipient, amnt, description],
          false,
          amntFl,
          null,
          null,
          version
        ).catch((err) => showError(err));
      }
      showError(`Wrong parameters`);
    }
  };

  //--------------------------

  useEffect(() => {
    console.info(`WalletOperationProvider: changed WEB3: ${!!web3}`);
    if (!web3) {
      setWeb3(new Web3(ethereum || window.ethereum));
    }
  }, [web3]);

  //--------------------------

  const defaultValue = {
    doClaimBalance,
    simplePayment,
    simplePaymentOld,
  };

  return (
    <WalletOperationsContext.Provider value={defaultValue}>
      {children}
    </WalletOperationsContext.Provider>
  );
};

export function useWalletOperation() {
  const context = React.useContext(WalletOperationsContext);

  if (!context) {
    throw new Error('`useWalletOperations` should be used within a `WalletOperationsProvider`');
  }

  return context;
}

export default WalletOperationsProvider;
