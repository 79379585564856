import 'core-js/modules/es.array.includes';
import 'core-js/modules/es.array.iterator';
import 'core-js/modules/es.object.to-string';
import 'core-js/modules/es.set';
import 'core-js/modules/es.array.unscopables.flat';
import 'core-js/modules/es.array.unscopables.flat-map';
import 'core-js/modules/es.object.from-entries';
import 'core-js/modules/web.immediate';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppState } from 'context';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from 'utils/api-client';
import { HelmetProvider } from 'react-helmet-async';

import UserState from 'context/User/UserState';
import MessengerState from 'context/Messenger/MessengerState';

import { createTheme, ThemeProvider } from '@mui/material'; // TODO: remove
import ScrollToTop from 'hoc/ScrollToTop';
import { DashboardRoutes } from './views/DashboardRoutes';
import AdminRoutes from 'views/Admin';
import Certificate from './views/DigitalAssets/CarbonCertificate/public/Certificate';
import GoogleMetrics from './utils/googleMetrics';

import DisconnectHandlerWrapper from './utils/disconnectHandler';
import { MetaMaskProvider } from 'metamask-react';

import './index.scss';
import AuthWrapper from './components/AuthWarpper';
import { ConfigProvider } from 'tt-ui-lib/core';
import 'tt-ui-lib/core/style.css';
import NotificationState from './context/Notification/NotificationState';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const ROUTES_INFO = [
  {
    path: '/',
    element: <Navigate to="/dashboard/digital_assets" />,
  },
  {
    path: '/dashboard/*',
    element: <DashboardRoutes />,
  },
  {
    path: '/certificate/:uid',
    element: <Certificate />,
  },
  {
    path: '/admin/*',
    element: (
      <AuthWrapper reloadAfterLogin>
        <AdminRoutes />
      </AuthWrapper>
    ),
  },
];
const helmetContext = {};

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#18397A',
      darker: '#18397A',
    },
    important: {
      main: '#d95e27',
      darker: '#bb5021',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    notSelected: {
      main: '#212121',
      contrastText: '#151515',
    },
    white: {
      main: '#ffffff',
      contrastText: '#6a6c60',
    },
  },
  box: {
    margin: '10px',
    padding: '60px 20px',
    borderRadius: '10px',
    backgroundColor: 'white',
  },
});

const root = createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <HelmetProvider context={helmetContext}>
      <ApolloProvider client={client}>
        <AppState>
          <UserState>
            <NotificationState>
              <MessengerState>
                <ThemeProvider theme={theme}>
                  <ConfigProvider>
                    <DisconnectHandlerWrapper />
                    <GoogleMetrics />
                    <ScrollToTop />
                    <MetaMaskProvider>
                      <Routes>
                        {ROUTES_INFO.map((routeInfo) => (
                          <Route
                            path={routeInfo.path}
                            element={routeInfo.element}
                            key={routeInfo.path}
                          />
                        ))}
                      </Routes>
                    </MetaMaskProvider>
                  </ConfigProvider>
                </ThemeProvider>
              </MessengerState>
            </NotificationState>
          </UserState>
        </AppState>
      </ApolloProvider>
    </HelmetProvider>
  </BrowserRouter>
  // </React.StrictMode>,
);
