import React, { useEffect, useState } from 'react';
import { CartWidget } from '../../modules/tt-module-cart';

import { SHOW_USER_AVAILABLE_CARTS, REMOVE_ITEM_FROM_CART } from '../../api';

import { useLazyQuery, useMutation } from '@apollo/client';

export const CartWrapper = ({ notMarket }) => {
  const [deleteItemRequest] = useMutation(REMOVE_ITEM_FROM_CART);
  const [cartItems, setCartItems] = useState([]);
  const [cartIsLoading, setCartIsLoading] = useState(true);
  const [getAvailableCarts] = useLazyQuery(SHOW_USER_AVAILABLE_CARTS, {
    fetchPolicy: 'network-only',
  });

  const fetchCartDataWidget = async () => {
    const availableRes = await getAvailableCarts({ variables: { is_checkout: false } });
    if (availableRes?.data?.showAvailableUserCarts.carts) {
      setCartItems(availableRes.data.showAvailableUserCarts.carts);
      setCartIsLoading(false);
    } else {
      setCartItems([]);
      setCartIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCartDataWidget();
  }, []);

  return (
    <CartWidget
      notMarket={notMarket}
      cartItems={cartItems}
      deleteItemRequest={deleteItemRequest}
      fetchCartDataWidget={fetchCartDataWidget}
      cartIsLoading={cartIsLoading}
    />
  );
};
