import React, { useEffect, useState } from 'react';
import {
  Button,
  DatePicker,
  Select,
  Modal,
  Input,
  InputTextArea,
  HelpTooltip,
  openNotification,
} from 'tt-ui-lib/core';
import styles from '../CertificatePage.module.scss';
import { useSelectedContext } from 'context/contextSelector';
import dayjs from 'dayjs';

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

const currencies = [
  { value: 'g', label: 'grams' },
  { value: 'kg', label: 'kg' },
  { value: 't', label: 'tonnes' },
];

const GetModal = ({
  open,
  setOpen,
  id,
  setSelectedTab,
  certData,
  offset,
  balance,
  getCertificate,
  setUpdateCertData,
}) => {
  const { user } = useSelectedContext((context) => context.user);

  const currentDate = new Date();
  const formattedDate = formatDate(currentDate);

  const [formData, setFormData] = useState({
    quantity: '',
    date: dayjs(),
    dateStr: formattedDate,
    unit: 'g',
    description: '',
    issued_for: '',
    beneficiary: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user?.first_name) {
      setFormData((prevData) => ({
        ...prevData,
        issued_for: `${user?.first_name} ${user?.last_name}`,
      }));
    }
  }, [user]);

  const calculateQuantity = (quantity) => {
    const parsedQuantity = parseInt(quantity, 10);

    switch (formData.unit) {
      case 't':
        return parsedQuantity * 1000000;

      case 'kg':
        return parsedQuantity * 1000;

      case 'g':
        return parsedQuantity;

      default:
        return parsedQuantity;
    }
  };

  const onSubmit = () => {
    setLoading(true);

    const reqPayload = {
      carbon_ucr_credit_id: id,
      quantity: calculateQuantity(formData.quantity),
      date: formData?.dateStr,
      description: formData?.description,
      issued_for: formData?.issued_for,
      beneficiary: formData?.beneficiary,
      user_id: user?.id,
      email: user?.email,
      wallet: certData?.wallet,
      carbon_address: certData?.carbon_address,
      // company_id: payload?.company_id,
      // register_id: payload?.register_id,
      // issued_by: payload?.issued_by,
      // calc_result_id: payload?.calc_result_id,
    };

    getCertificate(
      { variables: { input: reqPayload } },
      () => {
        setUpdateCertData(true);
        setSelectedTab(1);
        setLoading(false);
        setOpen(false);
        setFormData({
          quantity: '',
          date: dayjs,
          dateStr: formattedDate,
          unit: 'g',
          description: '',
          issued_for: '',
          beneficiary: '',
        });
      },
      (err) => {
        openNotification({
          type: 'error',
          message: err
            ? `${err}`.replace(/^\w/, (c) => c.toUpperCase())
            : 'Something went wrong, please try again',
        });
        setLoading(false);
      }
    );
  };

  const modalClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   if (open) {
  //     setLoading(true);

  //     getOffsetDataCertificate(
  //       { variables: { input: { carbon_ucr_credit_id: id } } },
  //       (response) => {
  //         if (response?.data?.getOffsetData) {
  //           setPayload(response?.data?.getOffsetData);
  //         }
  //         setLoading(false);
  //       },
  //       (err) => {
  //         openNotification({
  //           type: 'error',
  //           message: err?.message || 'Something went wrong, please try again',
  //         });
  //         setLoading(false);
  //       }
  //     );
  //   }
  // }, [open]);

  return (
    <Modal
      open={open}
      onClose={modalClose}
      title={
        <div className={styles.title}>
          <span>Offset the credit</span>
          <HelpTooltip tooltipText="To retire the carbon credit, specify the desired number in the 'Amount' field. Pay attention to the units on the right. Then press the 'Retire' button and confirm the transaction in MetaMask." />
        </div>
      }
      closeOnlyByControls
    >
      <div className={styles.infoModal}>
        <div className={styles.modalInfo}>
          You are about to offset this carbon credit. The minimum offset fraction is 1 gram. When
          entering information in the form below make sure you select the proper metrics (grams, kg,
          tons). You may also choose to offset the full outstanding balance of the carbon credit.
        </div>
        <div className={styles.modalInfo}>
          <div className={styles.infoItem}>
            <span>Offset with credits:</span> <span>{offset || '-'}</span>
          </div>

          <div className={styles.infoItem}>
            <span>Credit balance:</span> <span>{balance || '-'}</span>
          </div>
        </div>

        <div className={styles.controls}>
          <div className={styles.controlsRow}>
            <Input
              label="Amount to send *"
              value={formData.quantity}
              onChange={(e) => {
                const { value } = e.target;

                if (!/^[0-9]*$/.test(value) && value) {
                  return;
                }

                setFormData((prevVal) => ({
                  ...prevVal,
                  quantity: value,
                }));
              }}
              suffix={
                <Select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={styles.nestedSelect}
                  options={currencies}
                  value={formData.unit}
                  onChange={(value) =>
                    setFormData((prevVal) => ({
                      ...prevVal,
                      unit: value,
                    }))
                  }
                />
              }
            />

            <DatePicker
              label="Date *"
              value={formData.date}
              format="DD-MM-YYYY"
              onChange={(value, valueStr) => {
                setFormData((prevVal) => ({
                  ...prevVal,
                  date: value,
                  dateStr: valueStr,
                }));
              }}
            />
          </div>

          <div className={styles.controlsRow}>
            <Input
              label="Issued for *"
              value={formData.issued_for}
              onChange={(e) =>
                setFormData((prevVal) => ({
                  ...prevVal,
                  issued_for: e.target.value,
                }))
              }
            />

            <Input
              label="Beneficiary *"
              value={formData.beneficiary}
              onChange={(e) =>
                setFormData((prevVal) => ({
                  ...prevVal,
                  beneficiary: e.target.value,
                }))
              }
            />
          </div>

          <InputTextArea
            label="Description *"
            value={formData.description}
            onChange={(e) =>
              setFormData((prevVal) => ({
                ...prevVal,
                description: e.target.value,
              }))
            }
          />
        </div>

        <div className={styles.bottom}>
          <Button type="default" onClick={() => modalClose()}>
            Cancel
          </Button>

          <Button
            loading={loading}
            type="primary"
            disabled={
              !id ||
              !user ||
              !formData?.quantity ||
              !formData?.unit ||
              !formData?.date ||
              !formData?.description ||
              !formData?.beneficiary ||
              !formData?.issued_for ||
              parseInt(balance || '0', 10) === 0 ||
              parseInt(balance || '0', 10) < parseInt(formData?.quantity || '0', 10)
            }
            onClick={() => onSubmit()}
          >
            Offset the credit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default GetModal;
