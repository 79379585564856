import React, { useEffect, useState } from 'react';
import { GoogleIcon } from 'tt-ui-lib/icons';
import {
  Button,
  LoaderOn,
  Input,
  InputPassword,
  CheckBox,
  Form,
  openNotification,
} from 'tt-ui-lib/core';
import AvatarUploader from '../../AvatarUploader';
import ReactPhoneInput from 'react-phone-input-2';
import clsx from 'clsx';
import { PolicyModalWrapper } from '../../../PolicyModal';
import { useLazyQuery } from '@apollo/client';
import { deleteNestedKeys } from '../../../../utils/validation';
import { useWindowSize } from '../../../../utils/useWindowSize';

import styles from '../../SignInModal.module.scss';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const whitespacesReg = /^\s*\S.*$/;
const specialCharReg = /^[^<>'"/;`%]*$/;

const IndividualForm = ({ setMode, agreementSchema, handler, closeModal, signUpWithGoogle }) => {
  const { width } = useWindowSize();
  const [form] = Form.useForm();

  const [submitDisable, setSubmitDisable] = useState(true);
  const [avatar, setAvatar] = useState('');
  const [avatarError, setAvatarError] = useState(false);
  const [isPolicyAgree, setIsPolicyAgree] = useState(false);
  const [isPolicyLoading, setIsPolicyLoading] = useState(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);
  const [isHonorAgree, setIsHonorAgree] = useState(false);
  const [isHonorModalOpen, setIsHonorModalOpen] = useState(false);
  const [policyText, setPolicyText] = useState(null);
  const [honorText, setHonorText] = useState(null);
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [getAgreement] = useLazyQuery(agreementSchema, {});

  const setValue = (name, value) => {
    form.setFieldValue(name, value);
  };

  const onChangeAvatar = async (value) => {
    form.setFields([
      {
        name: 'avatar',
        errors: [],
      },
    ]);
    setAvatar(value);
    setValue('avatar', value);
  };

  const handleModalClose = (setIsOpen) => setIsOpen(false);

  const onSubmit = (data) => {
    setIsSignUpLoading(true);

    handler(
      deleteNestedKeys(data, ['province', 'phoneCode']),
      () => {
        setIsSignUpLoading(false);
        closeModal();
      },
      (e) => {
        setIsSignUpLoading(false);

        if (e) {
          if (e.message) {
            openNotification({
              message: e.message,
              type: 'error',
            });
          } else {
            openNotification({
              message: 'Error while sign-up. Try again later.',
              type: 'error',
            });
          }
        }
      }
    );
  };

  const validateBeforeSubmit = async (data) => {
    setFormSubmitted(true);
    const isStepValid = await form.validateFields();
    console.log(isStepValid, data);

    if (!data.avatar || data.avatar === '') {
      form.setFields([
        {
          name: 'avatar',
          errors: ['Please upload your avatar.'],
        },
      ]);
      setAvatarError('Please upload your avatar.');
      return;
    }

    if (!isPolicyAgree || !isHonorAgree) {
      openNotification({
        message: 'Please, accept Privacy Policy and Honor Code.',
        type: 'error',
      });
      return;
    }

    if (!isStepValid) {
      openNotification({
        message: 'Please, fill out the rest fields.',
        type: 'error',
      });
      return;
    }

    await onSubmit(data);
  };

  const getAgreementData = async () => {
    setIsPolicyLoading(true);

    const res = await getAgreement();

    if (res?.data?.agreementPrivacyAndHonor?.length > 0) {
      const policy = res.data.agreementPrivacyAndHonor.find(
        (agreement) => agreement.field === 'Privacy Policy'
      );

      setPolicyText(policy.body);

      const honor = res.data.agreementPrivacyAndHonor.find(
        (agreement) => agreement.field === 'Honor Code'
      );

      setHonorText(honor.body);
    }

    setIsPolicyLoading(false);
  };

  useEffect(() => {
    if (isPolicyAgree && isHonorAgree) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [isPolicyAgree, isHonorAgree]);

  return (
    <div className={styles.body}>
      <div className={styles.roleTextBlock}>
        <div className={styles.roleDescription}>
          Individuals on TransparenTerra are conscious consumers who understand that every action
          they take has consequences for their health and the planet.
        </div>
      </div>

      <Form style={{ marginTop: 10 }} form={form} onFinish={validateBeforeSubmit}>
        <div className={styles.form}>
          <div className={`${styles.row} ${styles.verticalCenter}`}>
            <div>
              <AvatarUploader
                avatarSrc={avatar}
                changeAvatar={(value) => onChangeAvatar(value)}
                error={formSubmitted && !!avatarError}
                helperText={formSubmitted && avatarError}
              />
            </div>

            <div className={styles.col}>
              <div className={styles.row}>
                <div className={styles.inputSignupWrapper}>
                  <Form.Item
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please fill out the rest fields. This is mandatory.',
                      },
                      {
                        max: 35,
                        message: 'Too many characters in first name.',
                      },
                      {
                        pattern: whitespacesReg,
                        message: 'The field cannot contain only spaces.',
                      },
                      {
                        pattern: specialCharReg,
                        message: 'Special characters are prohibited.',
                      },
                    ]}
                  >
                    <Input label="First Name" />
                  </Form.Item>
                </div>

                <div className={styles.inputSignupWrapper}>
                  <Form.Item
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please fill out the rest fields. This is mandatory.',
                      },
                      {
                        max: 35,
                        message: 'Too many characters in last name.',
                      },
                      {
                        pattern: whitespacesReg,
                        message: 'The field cannot contain only spaces.',
                      },
                      {
                        pattern: specialCharReg,
                        message: 'Special characters are prohibited.',
                      },
                    ]}
                  >
                    <Input label="Last Name" />
                  </Form.Item>
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.inputSignupWrapper}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please fill out the rest fields. This is mandatory.',
                      },
                      {
                        type: 'email',
                        message: 'Email is invalid',
                      },
                    ]}
                  >
                    <Input label="Email" />
                  </Form.Item>
                </div>

                <div className={styles.inputSignupWrapper}>
                  <Form.Item
                    name="tel"
                    rules={[
                      {
                        required: true,
                        message: 'Please fill out the rest fields. This is mandatory.',
                      },
                      {
                        pattern: phoneRegExp,
                        message: 'Phone number is not valid',
                      },
                    ]}
                  >
                    <ReactPhoneInput
                      country="us"
                      id="phone-input"
                      specialLabel=""
                      placeholder="Phone number"
                      buttonClass={styles.phone}
                      inputClass={clsx(styles.phoneInput)}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          {/* <div className={`${styles.row} ${styles.pdTopRow}`}> */}
          {/*  <TextInput */}
          {/*    style={{ width: '100%' }} */}
          {/*    label="Locations within this area, country/region, postal code" */}
          {/*    value={address} */}
          {/*    onClick={() => setIsLocationModal(true)} */}
          {/*    data-type="location" */}
          {/*    error={!!errors.location} */}
          {/*    helperText={errors.location?.message} */}
          {/*    InputProps={{ */}
          {/*      startAdornment: ( */}
          {/*        <InputAdornment position="start"> */}
          {/*          <Image src={MapIconSVG} width={26} height={22} /> */}
          {/*        </InputAdornment> */}
          {/*      ), */}
          {/*    }} */}
          {/*  /> */}
          {/* </div> */}

          <div className={`${styles.row} ${styles.pdTopRow}`}>
            <div className={styles.inputSignupWrapper}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please fill out the rest fields. This is mandatory.',
                  },
                  {
                    min: 8,
                    message: 'Password must be at least 8 characters',
                  },
                  {
                    pattern: /(?=.*\d)/,
                    message: 'Include at least one number',
                  },
                  {
                    pattern: /(?=.*[!@#$%^&*])/,
                    message: 'Include at least 1 special character (!@#$%^&*)',
                  },
                  {
                    pattern: /(?=.*[A-Z])/,
                    message: 'Include at least 1 uppercase letter',
                  },
                  {
                    pattern: /(?=.*[a-z])/,
                    message: 'Include at least 1 lowercase letter',
                  },
                ]}
              >
                <InputPassword label="Password" />
              </Form.Item>
            </div>
            <div className={styles.inputSignupWrapper}>
              <Form.Item
                name="password_confirmation"
                rules={[
                  {
                    required: true,
                    message: 'Please fill out the rest fields. This is mandatory.',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('The new password that you entered do not match!')
                      );
                    },
                  }),
                ]}
              >
                <InputPassword label="Password confirmation" />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className={styles.policyWrapper}>
          <CheckBox
            label="Privacy Policy"
            value={isPolicyAgree}
            onChange={async () => {
              if (isPolicyAgree) {
                setIsPolicyAgree(false);
              } else {
                getAgreementData().then();
                setIsPolicyModalOpen(true);
              }
            }}
          />

          <CheckBox
            label="Honor Code"
            value={isHonorAgree}
            onChange={async () => {
              if (isHonorAgree) {
                setIsHonorAgree(false);
              } else {
                getAgreementData().then();
                setIsHonorModalOpen(true);
              }
            }}
          />
        </div>

        <div className={styles.formBottom}>
          <Button className={styles.formSubmitButton} type="default" onClick={() => setMode('in')}>
            Cancel
          </Button>
          <div className={styles.rightButtons}>
            <button type="button" className={styles.googleBtn} onClick={signUpWithGoogle}>
              <GoogleIcon />
            </button>
            <Button
              className={styles.formSubmitButton}
              type="primary"
              loading={isSignUpLoading}
              disabled={submitDisable}
              onClick={() => form.submit()}
            >
              {width > 768 ? 'Create account' : 'Create'}
            </Button>
          </div>
        </div>
      </Form>

      {/* <LocationModal */}
      {/*  title="Map" */}
      {/*  isModelOpen={isLocationModal} */}
      {/*  handleClose={onClose} */}
      {/*  setIsOpen={setIsLocationModal} */}
      {/*  address={address} */}
      {/*  setAddress={setAddress} */}
      {/*  setLocationData={setLocationData} */}
      {/*  coordinates={coordinates} */}
      {/*  setCoordinates={setCoordinates} */}
      {/*  background="#FFFFFF" */}
      {/*  isProfileEditing */}
      {/* /> */}

      <PolicyModalWrapper
        isModelOpen={isPolicyModalOpen}
        setIsOpen={setIsPolicyModalOpen}
        handleClose={handleModalClose}
        setIsAgree={setIsPolicyAgree}
        isAgree={isPolicyAgree}
        text={policyText}
        checkboxText="I agree to the Privacy Policy"
        loading={isPolicyLoading}
      />

      <PolicyModalWrapper
        isModelOpen={isHonorModalOpen}
        setIsOpen={setIsHonorModalOpen}
        handleClose={handleModalClose}
        setIsAgree={setIsHonorAgree}
        isAgree={isHonorAgree}
        text={honorText}
        checkboxText="I accept the Honor Code of TransparenTerra members"
        loading={isPolicyLoading}
      />

      {isSignUpLoading && <LoaderOn />}
    </div>
  );
};

export default IndividualForm;
