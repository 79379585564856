/* eslint-disable react/no-unknown-property */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { CheckBox, DateRangePicker, Table } from 'tt-ui-lib/core';
import { ReloadIcon, DownloadIcon, VisibleIcon } from 'tt-ui-lib/icons';
import OperationDetailModal from '../OperationDetailModal';
import CarbonTransactionDetailModal from '../../CarbonCertificate/private/TransactionDetailModal';
import {
  useDigitalAssets,
  useDigitalAssetsBridge,
  useDigitalAssetsCaFFee,
} from 'modules/tt-digital-assets-provider';
import styles from './styles.module.scss';

const BridgeHistoryTable = (props) => {
  const { className, style, forceUpdate, receiveOperationHandle } = props;

  const {
    chainSettings,
    getterHash,
    getterValue,
    downloadArrAsCSVFile,
    fromWei,
    json2obj,
    obj2json,
    isNaN,
  } = useDigitalAssets();
  const { getBridgeOperations } = useDigitalAssetsBridge();
  const { getCO2CertInfo } = useDigitalAssetsCaFFee();

  const [bridgeHistory, setBridgeHistory] = useState(null);
  const [tableCurrentPage, setTableCurrentPage] = useState(1);
  const [showOperationDetail, setShowOperationDetail] = useState(false);
  const [showCarbonTransactionDetail, setShowCarbonTransactionDetail] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const [pending, setPending] = useState(true);
  const [operationDateStart, setOperationDateStart] = useState(null);
  const [operationDateEnd, setOperationDateEnd] = useState(null);

  const [carbonInfo, setCarbonInfo] = useState({
    hash: '',
    hash_short: '',
    datetime: '',
    CaFFee: '',
    token: '',
    symbol: '',
    offsetted_transaction: '',
    cert: {},
  });

  const [bridgeOperationID, setBridgeOperationID] = useState({});

  const _toBalance = (val) => (val !== null ? (!isNaN(val) ? fromWei(val) : '-') : '-');

  const _dateTransform = (d) => {
    if (d) {
      const dd = new Date(d);
      if (!Number.isNaN(dd?.getTime())) {
        return `${dd.getDate().toString().padStart(2, '0')}.${(dd.getMonth() + 1)
          .toString()
          .padStart(2, '0')}.${dd.getFullYear()} ${dd.getHours().toString().padStart(2, '0')}:${dd
          .getMinutes()
          .toString()
          .padStart(2, '0')}:${dd.getSeconds().toString().padStart(2, '0')}`;
      }
      return '';
    }
    return '';
  };

  const _getBridgeHistory = async (filter) => {
    setTableLoading(true);

    let data =
      (await getBridgeOperations({
        onlyPending: filter?.onlyPending !== undefined ? filter?.onlyPending : pending,
        operationDateStart:
          filter?.operationDateStart !== undefined
            ? filter?.operationDateStart
            : operationDateStart,
        operationDateEnd:
          filter?.operationDateEnd !== undefined ? filter?.operationDateEnd : operationDateEnd,
      })) || [];

    data = data.map((el) => {
      if (el) {
        el.amount_from = _toBalance(el.amount_from);
        el.amount_from_full = _toBalance(el.amount_from_full);
        el.fee_from = _toBalance(el.fee_from);
        el.amount_to = _toBalance(el.amount_to);
        el.amount_to_full = _toBalance(el.amount_to_full);
        el.fee_to = _toBalance(el.fee_to);
        el.date_from = _dateTransform(el.date_from);
        el.date_to = _dateTransform(el.date_to);
      }
      return el;
    });

    setTableLoading(false);
    return data;
  };

  const getBridgeHistory = async (bFilter) => setBridgeHistory(await _getBridgeHistory(bFilter));

  const getCSVTransactionHistory = async () =>
    downloadArrAsCSVFile(await _getBridgeHistory(), 'bridgeHistory.csv');

  const showTransactionCarbonCertificate = async (row) => {
    if (!row || !row.co2address) return;

    const res = (await getCO2CertInfo(row.co2address)) || {};
    res.to = 'TransparenTerra';

    const carbonHash = row?.hash_from?.startsWith('0x') ? row?.hash_from : `0x${row?.hash_from}`;
    const carbonFullInfo = {
      hash: carbonHash,
      hash_short: getterHash(carbonHash),
      datetime: (row?.date_from || '').replace('T', ' '),
      caffee: fromWei(row?.co2cost) || '',
      token: row?.co2tokens,
      symbol: chainSettings.nativeCurrency.symbol,
      offsetted_transaction: '0.841775642',
      cert: res,
    };
    console.log('carbonFullInfo', carbonFullInfo, row);
    setCarbonInfo(carbonFullInfo);

    setShowCarbonTransactionDetail(true);
  };

  const getterCarbonValue = (val, row) =>
    val ? (
      <span
        className={clsx(styles.link, 'link')}
        onClick={() => showTransactionCarbonCertificate(row)}
      >
        {val} CaFFee
      </span>
    ) : (
      '-'
    );

  const getterStatus = (val, data) =>
    data?.status_to === 3 ? (
      <span style={{ color: 'green' }}>Complete</span>
    ) : data?.status_from === 1 ? (
      'Pending...'
    ) : data?.status_from === 2 ? (
      <span
        className={clsx(styles.link, 'link')}
        onClick={(evnt) => {
          receiveOperationHandle(json2obj(evnt?.target?.getAttribute('data')));
        }}
        data={obj2json(data)}
      >
        Receive
      </span>
    ) : (
      '-'
    );

  const getterInfo = (_, data) => (
    <VisibleIcon
      style={{ width: 20, height: 20, cursor: 'pointer' }}
      onClick={() => {
        setBridgeOperationID({
          netFrom: data?.net_from,
          netTo: data?.net_to,
          addrFrom: data?.addr_from,
          addrTo: data?.addr_to,
          nonce: data?.nonce,
        });
        setShowOperationDetail(true);
      }}
    />
  );

  const columns = [
    {
      key: 'hash',
      dataIndex: 'hash',
      title: '',
      align: 'left',
      width: 40,
      render: (value, row) => <>{getterInfo(value, row)}</>,
    },
    {
      key: 'date_from',
      dataIndex: 'date_from',
      title: `Date`,
      align: 'left',
      width: 150,
      // render: (value) => <>{getterDate(value)}</>,
    },
    {
      key: 'net_from_name',
      dataIndex: 'net_from_name',
      title: `From`,
      align: 'left',
      width: 100,
    },
    {
      key: 'net_to_name',
      dataIndex: 'net_to_name',
      title: `To`,
      align: 'left',
      width: 100,
    },
    {
      key: 'amount_from',
      dataIndex: 'amount_from',
      title: `Value ${chainSettings.nativeCurrency.symbol}`,
      align: 'left',
      width: 150,
      render: (value) => <>{getterValue(value)}</>,
    },
    {
      key: 'co2tokens',
      dataIndex: 'co2tokens',
      title: `Tnx Fee`,
      align: 'left',
      width: 120,
      render: (value, row) => <>{getterCarbonValue(value, row)}</>,
    },
    {
      key: 'status_from',
      dataIndex: 'status_from',
      title: `Status`,
      align: 'left',
      width: 100,
      render: (value, row) => <>{getterStatus(value, row)}</>,
    },
  ];

  useEffect(() => {
    getBridgeHistory().then();
  }, [forceUpdate]);

  return (
    <div
      className={`${styles.BridgeHistoryTable} ${className} BridgeHistoryTable`}
      style={{ ...style }}
    >
      <div className={clsx(styles.row1, 'row1')}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span
            className={clsx(styles.h2, 'h2', styles.header)}
            style={{ marginBottom: 0, width: '150px' }}
          >
            Your latest bridge transactions
          </span>

          <ReloadIcon
            style={{
              width: 20,
              height: 20,
              marginLeft: '5px',
              cursor: 'pointer',
            }}
            onClick={() => getBridgeHistory()}
          />

          <DownloadIcon
            style={{
              width: 20,
              height: 20,
              marginLeft: '5px',
              cursor: 'pointer',
            }}
            onClick={() => getCSVTransactionHistory()}
          />
        </div>

        <div className={styles.filter}>
          <CheckBox
            label="Pending Transactions"
            value={pending}
            onChange={async (evnt) => {
              setPending(!!evnt.target.checked);
              return getBridgeHistory({
                onlyPending: !!evnt.target.checked,
              });
            }}
          />

          <DateRangePicker
            label="Date"
            value={[operationDateStart, operationDateEnd]}
            onChange={(vals) => {
              if (!vals) {
                setOperationDateStart(null);
                setOperationDateEnd(null);

                return getBridgeHistory({
                  operationDateStart: undefined,
                  operationDateEnd: undefined,
                });
              }

              setOperationDateStart(vals[0] || null);
              setOperationDateEnd(vals[1] || null);

              return getBridgeHistory({
                operationDateStart: vals[0] || undefined,
                operationDateEnd: vals[1] || undefined,
              });
            }}
          />
        </div>
      </div>

      <div style={{ width: '100%', overflowX: 'auto' }}>
        <Table
          loading={tableLoading}
          columns={columns}
          data={bridgeHistory || []}
          scroll={{ x: 870, y: '40vh' }}
          pagination={{
            position: ['bottomCenter'],
            current: tableCurrentPage,
            pageSize: 10,
            // total: bridgeHistory?.length,
            responsive: true,
            showSizeChanger: false,
            hideOnSinglePage: true,
            onChange: (page) => setTableCurrentPage(parseInt(page, 10)),
          }}
        />
      </div>

      <OperationDetailModal
        showOperationDetail={showOperationDetail}
        setShowOperationDetail={setShowOperationDetail}
        bridgeOperationID={bridgeOperationID}
      />

      <CarbonTransactionDetailModal
        showCarbonTransactionDetail={showCarbonTransactionDetail}
        setCarbonShowTransactionDetail={setShowCarbonTransactionDetail}
        certificateInfo={carbonInfo}
      />
    </div>
  );
};

export default BridgeHistoryTable;
