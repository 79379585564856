import React, { useState } from 'react';
import { CheckBox } from 'tt-ui-lib/core';

import styles from './CheckboxWithButton.module.scss';

const CheckboxWithButton = ({
  setIsAgree,
  text,
  handleClose,
  setIsOpen,
  isShouldScroll,
  isScrolled,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className={styles.wrapper}>
      <CheckBox
        label={text}
        value={isChecked}
        onChange={() => setIsChecked(!isChecked)}
        disabled={isShouldScroll && !isScrolled}
      />

      <button
        type="button"
        className={styles.btn}
        disabled={!isChecked}
        onClick={() => {
          setIsAgree(true);
          handleClose(setIsOpen);
        }}
      >
        Proceed
      </button>
    </div>
  );
};

export default CheckboxWithButton;
