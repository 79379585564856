import React from 'react';
import AdminNavbar from './AdminNavbar';

const AdminNavbarWrapper = ({
  showNavbar,
  // theme,
  baseUrl = null,
  baseFceUrl = null,
  baseApiUrl = null,
  mode,
  setHeaderHeight,
}) => (
  // <ThemeProvider theme={theme}>
  <AdminNavbar
    showNavbar={showNavbar}
    baseUrl={baseUrl}
    baseFceUrl={baseFceUrl}
    baseApiUrl={baseApiUrl}
    mode={mode}
    setHeaderHeight={setHeaderHeight}
  />
  // </ThemeProvider>
);

export default AdminNavbarWrapper;
