import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import AppAvatar from '../../AppAvatar';
import { Loader, Dropdown } from 'tt-ui-lib/core';
import { RightIcon, DotIcon } from 'tt-ui-lib/icons';
import { Link } from 'react-router-dom';
import styles from './NavBarNotificationButton.module.scss';

const NavBarNotificationButton = ({
  Icon,
  text,
  user,
  notifications,
  getUserNotifications,
  handleMarkAsReadNotifications,
  onClick,
  setShowNeedKYCModal,
  setIsAgreementModalOpen,
  logOut,
  redirectDomain,
  loading,
  countOfUnread,
}) => {
  const [open, setOpen] = useState(false);

  const extractFirstLink = (htmlString) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    const anchor = div.querySelector('a');
    return anchor ? anchor.href : null;
  };

  useEffect(() => {
    if (open) {
      getUserNotifications();
    }
  }, [open]);

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      {countOfUnread ? <span className={styles.countOfUnread}>{countOfUnread}</span> : null}
      <Dropdown
        placement="bottom"
        trigger="click"
        open={open}
        onOpenChange={(value) => setOpen(value)}
        // eslint-disable-next-line react/no-unstable-nested-components
        dropdownRender={() => (
          <div className={styles.dropdown}>
            {notifications.length > 0 && !loading ? (
              notifications.map((item) => {
                const url = item?.content?.body ? extractFirstLink(item.content.body) : null;
                return (
                  <div
                    key={item.id}
                    className={`${styles.notification} ${!item.read_at ? styles.notificationUnread : ''}`}
                    onClick={() => {
                      if (url) {
                        window.open(url, '_blank');
                      }
                    }}
                    style={{ cursor: url ? 'pointer' : 'default' }}
                  >
                    <div>
                      {item.user ? (
                        <AppAvatar
                          user={user}
                          nav={0}
                          userName={`${item.user.first_name} ${item.user.last_name}`}
                          src={item.user.avatar}
                          size={48}
                          setShowNeedKYCModal={setShowNeedKYCModal}
                          setIsAgreementModalOpen={setIsAgreementModalOpen}
                          logOut={logOut}
                        />
                      ) : null}
                    </div>
                    <div className={styles.notificationInfo}>
                      <div className={styles.notificationTitle}>{item.message}</div>
                      <div className={styles.notificationTitle}>{item.title}</div>
                      <div
                        className={styles.notificationDescription}
                        dangerouslySetInnerHTML={{ __html: `${item?.content?.body}` }}
                      />
                    </div>
                    {!item.read_at && (
                      <DotIcon className={styles.unreadIcon} style={{ width: 30, height: 30 }} />
                    )}
                  </div>
                );
              })
            ) : !loading && !notifications.length ? (
              <div className={styles.notification} disabled>
                You have not notifications
              </div>
            ) : (
              <div className={styles.loader}>
                <Loader />
              </div>
            )}
            {!loading && notifications.length > 0 && (
              <div className={styles.viewAllLinkBlock}>
                <Link
                  to={
                    redirectDomain
                      ? `${redirectDomain}/dashboard/notification`
                      : '/dashboard/notification'
                  }
                  onClick={() => setOpen(false)}
                  underline="none"
                  className={styles.viewAllLink}
                >
                  <span className={styles.viewAllLinkText}>View all</span>
                  <RightIcon style={{ width: 20, height: 20 }} />
                </Link>
              </div>
            )}
          </div>
        )}
      >
        <button
          // onClick={onClick || setOpen}
          type="button"
          className={open ? styles.navbarLinkActive : styles.navbarLink}
        >
          <Icon className={clsx(styles.linkIcon, open ? styles.linkIconActive : '')} />
          <span className={styles.navbarLinktext}>{text}</span>
        </button>
      </Dropdown>
    </div>
  );
};

export default NavBarNotificationButton;
