import React from 'react';
import { Modal } from 'tt-ui-lib/core';
import ModalContainer from '../../components/ModalContainer';
import styles from './KycModal.module.scss';

const YotiModal = ({ isModelOpen, setIsOpen, handleClose, yotiUrl }) => (
  <Modal
    onClose={() => handleClose(setIsOpen)}
    open={isModelOpen}
    title="Pass KYC"
    maskClosable={false}
  >
    <ModalContainer
      background="rgba(255, 255, 255, 0.4)"
      handleClose={() => handleClose(setIsOpen)}
      isFullHeight
    >
      <div className={styles.iframeWrapper}>
        {yotiUrl && (
          <iframe
            className={styles.iframe}
            src={yotiUrl}
            allow="camera"
            title="KYC"
            width="400px"
            height="600px"
          />
        )}
      </div>
    </ModalContainer>
  </Modal>
);

export default YotiModal;
