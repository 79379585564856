import React, { useEffect, useState } from 'react';
import { PartialLoader } from 'tt-ui-lib/core';
import clsx from 'clsx';
import ConsentModal from '../../components/ConsentModal';

import styles from './PolicyModal.module.scss';

export const PolicyModalWrapper = ({
  isModelOpen,
  setIsOpen,
  handleClose,
  setIsAgree,
  isAgree,
  checkboxText,
  text,
  loading,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isFullScreenActive, setIsFullScreenActive] = useState(false);

  const handleScroll = (e) => {
    const { target } = e;
    console.log(target.scrollHeight, Math.ceil(target.scrollTop) + target.offsetHeight);
    if (target.scrollHeight <= Math.ceil(target.scrollTop) + target.offsetHeight) {
      setIsScrolled(true);
    }
  };

  useEffect(() => {
    if (!isModelOpen) {
      setIsScrolled(false);
    }
  }, [isModelOpen]);

  return (
    <ConsentModal
      isModelOpen={isModelOpen}
      setIsOpen={setIsOpen}
      handleClose={handleClose}
      setIsAgree={setIsAgree}
      isAgree={isAgree}
      checkboxText={checkboxText}
      isShouldScroll
      isScrolled={isScrolled}
      isFullScreenActive={isFullScreenActive}
      setIsFullScreenActive={setIsFullScreenActive}
      isFullScreen
    >
      <div
        className={clsx(
          styles.contentFirstWrapper,
          isFullScreenActive ? styles.fullScreenContentWrapper : ''
        )}
      >
        <div className={styles.contentSecondWrapper}>
          <PartialLoader loading={loading} />
          <div onScroll={handleScroll}>
            <pre className={styles.text}>{text}</pre>
          </div>
        </div>
      </div>
    </ConsentModal>
  );
};
