import React, { useRef, useState, useEffect } from 'react';
import styles from './CartItem.module.scss';
import { getAvatarPathBySize } from '../../utils/common';
import { Tooltip } from 'tt-ui-lib/core';

const CartItem = ({ item }) => {
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const checkIfTruncated = (element) => {
    if (element) {
      return element.scrollWidth > element.clientWidth;
    }
    return false;
  };

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(checkIfTruncated(textRef.current));
    }
  }, []);

  return (
    <div className={styles.cartItem}>
      <div className={styles.cartItemInfo}>
        <img
          src={
            getAvatarPathBySize(item.carts[0].shopProduct?.logos, 'mediumPath') ||
            '/images/market-place/no_image.png'
          }
          alt=""
        />
        {isTruncated ? (
          <Tooltip title={item.name} arrow placement="topRight">
            <p className={styles.truncatedText} ref={textRef}>
              {item.carts[0].quantity} x {item.name}
            </p>
          </Tooltip>
        ) : (
          <p className={styles.truncatedText} ref={textRef}>
            {item.carts[0].quantity} x {item.name}
          </p>
        )}
      </div>
      <p>
        {item.carts[0]?.shopProductPrice?.productDiscountPrice
          ? (+item.carts[0].shopProductPrice.productDiscountPrice * item.carts[0].quantity).toFixed(
              2
            )
          : (+item.carts[0].shopProductPrice.productPrice * item.carts[0].quantity).toFixed(2)}
      </p>
    </div>
  );
};

export default CartItem;
