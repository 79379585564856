import React, { useEffect, useState } from 'react';
import { NAVBAR, FCE_GROUP_NAVBAR, API_NAVBAR } from './data';
import { useLocation } from 'react-router-dom';
import { Button, Drawer } from 'tt-ui-lib/core';
import { MenuIcon, CloseCrossIcon, UpIcon, DownIcon } from 'tt-ui-lib/icons';
import styles from './AdminNavbar.module.scss';
import NavbarLink from './AdminNavbarLink';

const AdminNavbar = ({
  showNavbar,
  baseUrl,
  baseFceUrl,
  baseApiUrl,
  mode = 'tt',
  setHeaderHeight,
}) => {
  const [section, setSection] = useState(
    mode === 'tt' ? 'transparenterra' : mode === 'api' ? 'api' : 'fce-group'
  );
  const [openAdminHeader, setOpenAdminHeader] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const toggleSlider = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (location.pathname.split('/')[2] === 'messaging') {
      setHeaderHeight();
    }
  }, [showNavbar, openAdminHeader, location]);

  useEffect(() => {
    const isFceGroup = FCE_GROUP_NAVBAR.find((item) => item.url === location.pathname);
    const isApi = API_NAVBAR.find((item) => item.url === location.pathname);

    if (isFceGroup) {
      setSection('fce-group');
    } else if (isApi) {
      setSection('api');
    } else {
      setSection('transparenterra');
    }

    if (location.pathname.split('/')[1] === 'admin') {
      setOpenAdminHeader(true);
    }
  }, [location]);

  return (
    <>
      {showNavbar ? (
        <header className={styles.navbar}>
          <div className={styles.navbarContent}>
            <div className={styles.desktopLinks}>
              <div
                className={styles.headerMenu}
                style={!openAdminHeader ? { paddingBottom: 16 } : {}}
              >
                {location.pathname.includes('admin') && (
                  <div
                    className={styles.headerMenuItem}
                    onClick={() => {
                      window.location.href = `${baseUrl}/dashboard/feed`;
                    }}
                  >
                    Go to home screen
                  </div>
                )}
                <div
                  style={section === 'transparenterra' ? { color: '#143471' } : {}}
                  className={styles.headerMenuItem}
                  onClick={() => setSection('transparenterra')}
                >
                  Transparenterra
                </div>
                <div
                  style={section === 'fce-group' ? { color: '#143471' } : {}}
                  className={styles.headerMenuDarkItem}
                  onClick={() => {
                    if (mode === 'tt' || mode === 'api') {
                      window.location.href = `${baseFceUrl}/admin/`;
                    } else {
                      setSection('fce-group');
                      setOpenAdminHeader(true);
                    }
                  }}
                >
                  FCE Group
                </div>
                <div
                  style={section === 'api' ? { color: '#143471' } : {}}
                  className={styles.headerMenuDarkItem}
                  onClick={() => {
                    if (mode === 'tt' || mode === 'fce-group') {
                      window.location.href = `${baseApiUrl}/admin/`;
                    } else {
                      setSection('api');
                      setOpenAdminHeader(true);
                    }
                  }}
                >
                  API
                </div>
                <Button type="icon" onClick={() => setOpenAdminHeader(!openAdminHeader)}>
                  {openAdminHeader ? (
                    <UpIcon style={{ color: '#212121', width: 18, height: 18 }} />
                  ) : (
                    <DownIcon style={{ color: '#212121', width: 18, height: 18 }} />
                  )}
                </Button>
                {openAdminHeader ? (
                  <UpIcon sx={{ color: '#212121' }} />
                ) : (
                  <DownIcon sx={{ color: '#212121' }} />
                )}
              </div>
              {openAdminHeader && (
                <nav className={styles.navbarLinks}>
                  {section === 'transparenterra'
                    ? NAVBAR.map((item, index) => (
                        <NavbarLink
                          key={`${index + 1}`}
                          href={baseUrl ? `${baseUrl}${item.url || '/'}` : item.url || '/'}
                          text={item.label}
                          className={styles.navBarLink}
                        />
                      ))
                    : section === 'api'
                      ? API_NAVBAR.map((item, index) => (
                          <NavbarLink
                            key={`${index + 1}`}
                            href={baseApiUrl ? `${baseApiUrl}${item.url || '/'}` : item.url || '/'}
                            text={item.label}
                            className={styles.navBarLink}
                          />
                        ))
                      : FCE_GROUP_NAVBAR.map((item, index) => (
                          <NavbarLink
                            key={`${index + 1}`}
                            href={baseFceUrl ? `${baseFceUrl}${item.url || '/'}` : item.url || '/'}
                            text={item.label}
                            className={styles.navBarLink}
                          />
                        ))}
                </nav>
              )}
            </div>

            <div className={styles.mobileLinks}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: !open ? 'space-between' : 'flex-end',
                  alignItems: 'center',
                  paddingTop: '22px !important',
                  paddingBottom: '20px !important',
                }}
              >
                {!open ? <div className={styles.menuTitle}>Admin Menu</div> : null}
                <Button type="icon" onClick={toggleSlider}>
                  <MenuIcon style={{ width: 24, height: 24, flexShrink: 0 }} />
                </Button>
              </div>

              <Drawer open={open} placement="right" onClose={toggleSlider} width={300}>
                <div className={styles.menuHeader}>
                  <div className={styles.menuTitle}>Admin Menu</div>
                  <Button type="icon" onClick={toggleSlider}>
                    <CloseCrossIcon style={{ width: 24, height: 24, flexShrink: 0 }} />
                  </Button>
                </div>

                <div
                  className={styles.headerMenu}
                  style={!openAdminHeader ? { paddingBottom: 16 } : {}}
                >
                  {location.pathname.includes('admin') && (
                    <div
                      className={styles.headerMenuItem}
                      onClick={() => {
                        window.location.href = `${baseUrl}/dashboard/feed`;
                      }}
                    >
                      Go to home screen
                    </div>
                  )}

                  <div
                    style={section === 'transparenterra' ? { color: '#143471' } : {}}
                    className={styles.headerMenuItem}
                    onClick={() => setSection('transparenterra')}
                  >
                    Transparenterra
                  </div>

                  <div
                    style={section === 'fce-group' ? { color: '#143471' } : {}}
                    className={styles.headerMenuDarkItem}
                    onClick={() => {
                      if (mode === 'tt' || mode === 'api') {
                        window.location.href = `${baseFceUrl}/admin/`;
                      } else {
                        setSection('fce-group');
                        setOpenAdminHeader(true);
                      }
                    }}
                  >
                    FCE Group
                  </div>

                  <div
                    style={section === 'api' ? { color: '#143471' } : {}}
                    className={styles.headerMenuDarkItem}
                    onClick={() => {
                      if (mode === 'tt' || mode === 'fce-group') {
                        window.location.href = `${baseApiUrl}/admin/`;
                      } else {
                        setSection('api');
                        setOpenAdminHeader(true);
                      }
                    }}
                  >
                    API
                  </div>
                </div>
                {section === 'transparenterra'
                  ? NAVBAR.map((item, index) => (
                      <NavbarLink
                        key={`${index + 1}`}
                        href={baseUrl ? `${baseUrl}${item.url || '/'}` : item.url || '/'}
                        text={item.label}
                        className={styles.navbarLink}
                      />
                    ))
                  : section === 'api'
                    ? API_NAVBAR.map((item, index) => (
                        <NavbarLink
                          key={`${index + 1}`}
                          href={baseApiUrl ? `${baseApiUrl}${item.url || '/'}` : item.url || '/'}
                          text={item.label}
                          className={styles.navBarLink}
                        />
                      ))
                    : FCE_GROUP_NAVBAR.map((item, index) => (
                        <NavbarLink
                          key={`${index + 1}`}
                          href={baseFceUrl ? `${baseFceUrl}${item.url || '/'}` : item.url || '/'}
                          text={item.label}
                          className={styles.navbarLink}
                        />
                      ))}
              </Drawer>
            </div>
          </div>
        </header>
      ) : null}
    </>
  );
};

export default AdminNavbar;
