import React, { useEffect, useState } from 'react';
import { InviteWrapper } from 'components/Invite';
import styles from './DashboardWidgets.module.scss';
import { useWindowSize } from 'utils/useWindowSize';
import { CartWrapper } from 'components/CartWrapper';

import { ReactComponent as OpenIcon } from './Icons/ArrowOpen.svg';
import { ReactComponent as CloseIcon } from './Icons/ArrowClose.svg';

import { ReactComponent as IviteIcon } from './Icons/InviteIcon.svg';
import { Button } from 'tt-ui-lib/core';
import { ShopCartIcon } from 'tt-ui-lib/icons';

import { SHOW_USER_AVAILABLE_CARTS, REMOVE_ITEM_FROM_CART } from '../../api';
import { useLazyQuery } from '@apollo/client';

const DashboardWidgets = ({ user, kyc, showNavbar }) => {
  const { width } = useWindowSize();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [cartItems, setCartItems] = useState();
  const [cartItemCounter, setCartItemCounter] = useState(0);
  const [getAvailableCarts] = useLazyQuery(SHOW_USER_AVAILABLE_CARTS, {
    fetchPolicy: 'network-only',
  });

  const fetchCartDataWidget = async () => {
    const availableRes = await getAvailableCarts({ variables: { is_checkout: false } });
    if (availableRes?.data?.showAvailableUserCarts.carts) {
      setCartItems(availableRes.data.showAvailableUserCarts.carts);
    } else {
      setCartItems([]);
    }
  };

  useEffect(() => {
    if (user.id) {
      fetchCartDataWidget();
      if (cartItems?.length > 0) {
        const allProducts = cartItems.flatMap((cart) => cart.products);
        const numbOfProducts = allProducts.length;
        setCartItemCounter(numbOfProducts);
      } else {
        setCartItemCounter(0);
      }
    }
  }, [cartItems]);

  const renderWidgets = () => (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: open ? 'space-between' : 'center',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 15,
          padding: '0 15px',
        }}
      >
        {open ? <div style={{ marginLeft: 15, fontWeight: 600 }}>Widgets</div> : null}
        <Button type="icon" onClick={open ? handleDrawerClose : handleDrawerOpen}>
          {open ? <CloseIcon /> : <OpenIcon />}
        </Button>
      </div>

      <hr
        style={{
          marginTop: 10,
          flexShrink: 0,
          borderWidth: '0px 0px thin',
          borderStyle: 'solid',
          borderColor: 'rgba(0, 0, 0, 0.12)',
        }}
      />

      <div
        className={styles.drawerList}
        style={{ maxHeight: showNavbar ? 'calc(90vh - 200px)' : 'calc(90vh - 130px)' }}
      >
        {user.id && (
          <div style={{ display: 'block' }}>
            <div className={styles.drawerItem}>
              {open ? (
                <CartWrapper notMarket />
              ) : (
                <div className={styles.cartIcon}>
                  {cartItemCounter > 0 ? (
                    <div className={styles.cartItemCounter}>
                      <p>{cartItemCounter < 100 ? cartItemCounter : '99+'}</p>
                    </div>
                  ) : null}

                  <ShopCartIcon style={{ width: 25, height: 25 }} />
                </div>
              )}
            </div>
          </div>
        )}
        {kyc === 'success' ||
        (user?.roles_list && JSON.parse(user.roles_list).includes('transparenterra')) ? (
          <div style={{ display: 'block' }}>
            <div className={styles.drawerItem}>
              {open ? <InviteWrapper /> : <IviteIcon style={{ width: 25, height: 25 }} />}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );

  return width > 1580 || width < 1025 ? (
    <div
      className={styles.rightColumn}
      style={{
        top: showNavbar ? 195 : 125,
      }}
    >
      {user.id && (
        <div className={styles.widgetItem}>
          <CartWrapper notMarket />
        </div>
      )}
      {kyc === 'success' ||
      (user?.roles_list && JSON.parse(user.roles_list).includes('transparenterra')) ? (
        <div className={styles.widgetItem}>
          <InviteWrapper />
        </div>
      ) : null}
    </div>
  ) : (
    <div
      className={`${styles.drawerWrapper}`}
      style={{
        top: showNavbar ? 195 : 125,
        visibility: !user.id ? 'hidden' : 'inherit',
      }}
    >
      <div className={open ? styles.drawerOpen : ''}>{renderWidgets()}</div>
    </div>
  );
};

export default DashboardWidgets;
