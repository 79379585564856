/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, HelpTooltip, Input } from 'tt-ui-lib/core';
import {
  useDigitalAssets,
  useDigitalAssetsWallet,
  useDigitalAssetsWalletOperation,
  useDigitalAssetsCaFFee,
} from 'modules/tt-digital-assets-provider';

import WarningIconSVG from 'assets/images/warning-orange-triangle.svg';

import styles from './styles.module.scss';
import { CopyIcon } from 'tt-ui-lib/icons';

const SimpleSend = (props) => {
  const { className, style, setShowSimplePaymentDialog, setForceReload } = props;

  const { chainSettings, account, copyToClipboard, toBN, isBN, isAddress, toWei, fromWei } =
    useDigitalAssets();
  const { getRegisterWalletState, getRegisterWalletStateIcon, getRegisterWalletStateText } =
    useDigitalAssetsWallet();
  const { getCO2TokenCost, co2TokenCost } = useDigitalAssetsCaFFee();
  // eslint-disable-next-line camelcase
  const { simplePayment } = useDigitalAssetsWalletOperation();

  const [sendRecipientWallet, setSendRecipientWallet] = useState('');
  const [sendRecipientWalletError, setSendRecipientWalletError] = useState('');
  const [sendAmount, setSendAmount] = useState('');
  const [sendAmountError, setSendAmountError] = useState('');
  const [sendTotalAmount, setSendTotalAmount] = useState(toBN(0));
  const [sendTotalAmountStr, setSendTotalAmountStr] = useState('-');
  const [sendDescription, setSendDescription] = useState('');

  const [sentBntActive, setSentBntActive] = useState(false);

  const [showConfirm, setShowConfirm] = useState(false);
  const [recipientWalletState, setRecipientWalletState] = useState(-1);
  const [recipientWalletStateIcon, setRecipientWalletStateIcon] = useState(null);
  const [recipientWalletStateText, setRecipientWalletStateText] = useState('');
  const [recipientWalletWarningText, setRecipientWalletWarningText] = useState('');

  const getTotalAmount = async (amount) => {
    let am = amount;
    if (!isBN(am)) {
      am = toBN(0);
    }
    const CaFFee = await getCO2TokenCost();
    const tax = toBN(CaFFee || 0);
    return am + tax;
  };

  const checkRecipientAddress = (address) => {
    if (!address) {
      setSendRecipientWalletError('required field');
      return false;
    }
    if (!isAddress(address)) {
      setSendRecipientWalletError('is not valid address');
      return false;
    }
    if (address === account || `0x${address}` === account) {
      setSendRecipientWalletError("can't send to active wallet");
      return false;
    }
    setSendRecipientWalletError('');
    return true;
  };

  const checkAmount = (amount) => /^\d+(?:\.\d+)?$/gim.test(amount);

  const changeSendRecipientWallet = (evnt) => {
    const val = evnt.target.value || '';
    setSendRecipientWallet(val);
    setSentBntActive(checkRecipientAddress(val) && checkAmount(sendAmount));
  };

  const changeSentAmount = async (evnt) => {
    let val = String(evnt.target.value);

    if (parseFloat(val) > 5300000000) return;

    setSendAmount(val);

    if (/^(0\.\d{1,18}|[1-9]\d{0,9}(?:\.\d{1,18})?)$/gim.test(val)) {
      val = toWei(val || 0);
      if (!isBN(val)) {
        setSendAmountError('is not valid amount');
        setSendTotalAmount('');
        setSendTotalAmountStr('-');
        setSentBntActive(false);
      } else {
        setSendAmountError('');

        const total = await getTotalAmount(val);
        setSendTotalAmount(total);
        setSendTotalAmountStr(fromWei(total || 0));
        setSentBntActive(checkRecipientAddress(sendRecipientWallet));
      }
    } else {
      setSendAmountError('is not valid amount');
      setSendTotalAmount('');
      setSendTotalAmountStr('-');
      setSentBntActive(false);
    }
  };

  const showConfirmHandler = async () => {
    const state = await getRegisterWalletState(sendRecipientWallet);
    setRecipientWalletState(state); // blocked && delete state
    switch (state) {
      case 2:
        setRecipientWalletStateIcon(getRegisterWalletStateIcon(state));
        setRecipientWalletStateText(getRegisterWalletStateText(state));
        setRecipientWalletWarningText('');
        break;
      case 3:
      case 4:
        setRecipientWalletStateIcon(getRegisterWalletStateIcon());
        setRecipientWalletStateText(`This wallet is blocked in the TransparenTerra`);
        setRecipientWalletWarningText('This wallet is blocked in the TransparenTerra');
        break;
      case 5:
        setRecipientWalletStateIcon(getRegisterWalletStateIcon());
        setRecipientWalletStateText(`The user deleted his wallet from TransparenTerra`);
        setRecipientWalletWarningText('The user deleted his wallet from TransparenTerra');
        break;
      default:
        setRecipientWalletStateIcon(getRegisterWalletStateIcon());
        setRecipientWalletStateText(`This wallet is not registered in the TransparenTerra`);
        setRecipientWalletWarningText('This wallet is not registered in the TransparenTerra');
        break;
    }

    setShowConfirm(true);
  };

  const cancelConfirm = () => {
    setShowConfirm(false);
  };

  const getHash = (val) =>
    `${(val || '').substring(0, 6)}...${(val || '').substring(val.length - 6)}`;

  const _simplePayment = () => {
    const amnt = toBN(toWei(sendAmount));
    const amntFull = toBN(sendTotalAmount);

    setShowSimplePaymentDialog(false);

    simplePayment(amnt, amntFull, sendRecipientWallet, 0, sendDescription).then(() => {
      if (setForceReload) setForceReload(true);
    });
  };

  useEffect(() => {
    getCO2TokenCost().then();
  }, []);

  return (
    <div className={className} style={{ ...style, padding: 0 }}>
      {!showConfirm ? (
        <div className={clsx(styles.config, 'config')}>
          <div className={clsx(styles.simplePaymentRow1, 'simplePaymentRow1')}>
            <Input
              placeholder="Recipient's wallet address"
              error={!!sendRecipientWalletError}
              fullWidth
              onChange={changeSendRecipientWallet}
              onBlur={() =>
                sendRecipientWallet &&
                sendRecipientWallet.substring(0, 2) !== '0x' &&
                setSendRecipientWallet(`0x${sendRecipientWallet}`)
              }
              value={sendRecipientWallet}
              className={styles.fullWidthInput}
            />
          </div>
          <div className={clsx(styles.simplePaymentRow1, 'simplePaymentRow1')}>
            <Input
              placeholder="Amount"
              error={!!sendAmountError}
              onChange={changeSentAmount}
              value={sendAmount}
              suffix={chainSettings?.nativeCurrency?.symbol}
              className={styles.fullWidthInput}
            />
          </div>
          {/*
          <div
            className={clsx(styles.simplePaymentRow1, 'simplePaymentRow1')}
            style={{ justifyContent: 'flex-start' }}
          >
            <TextField
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={4}
              fullWidth
              defaultValue={sendDescription}
              onChange={(evnt) => setSendDescription(evnt.target.value)}
              inputProps={{ maxLength: 500 }}
            />
          </div>
          */}
          <div
            className={clsx(styles.simplePaymentRow1, 'simplePaymentRow1')}
            style={{ justifyContent: 'flex-start', alignItems: 'center' }}
          >
            <span>Commission CaFFee:</span>
            <HelpTooltip
              tooltipText="Users automatically redeem the carbon footprint of each transaction in FCE Blockchain with
                CAFFEE. The price of the CAFFEE is dynamic and calculated by FCEM. After payment, the user
                receives an sbd token, which represents a share of ownership in an offset certificate.
                With each transaction on the FCE Blockchain, sbd tokens accumulate in users wallets,
                confirming the carbon neutrality of each user and the entire Since FCE Blockchain does not
                charge transaction fees, the CAFFEE for offsetting the carbon footprint of the transaction
                is the only fee in the network."
              placement="right"
              overlayInnerStyle={{ maxWidth: 290 }}
              arrow={false}
            />
            <span>
              {fromWei(co2TokenCost || 0)}&nbsp;
              {chainSettings?.nativeCurrency?.symbol}
            </span>
          </div>
          <div
            className={clsx(styles.simplePaymentRow1, 'simplePaymentRow1')}
            style={{ justifyContent: 'flex-start', fontWeight: 600, gap: 20 }}
          >
            <span style={{ fontWeight: 600 }}>Total:</span>
            <span>
              {sendTotalAmountStr}&nbsp;{chainSettings?.nativeCurrency?.symbol}
            </span>
          </div>
          <div className={clsx(styles.simplePaymentRow2, 'simplePaymentRow2')}>
            <Button
              type="default"
              onClick={() => {
                setShowSimplePaymentDialog(false);
              }}
              size="large"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={showConfirmHandler}
              size="large"
              disabled={!sentBntActive}
            >
              Send
            </Button>
          </div>
        </div>
      ) : (
        <div className={clsx(styles.confirm, 'confirm')}>
          <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
            <div className={clsx(styles.confirmDetailRow, 'confirmDetailRow')}>
              <div>Your wallet address:</div>
              <div>
                {getHash(account)}&nbsp;
                <CopyIcon
                  style={{
                    width: 20,
                    height: 20,
                    marginLeft: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={async () => copyToClipboard(account)}
                />
              </div>
            </div>
            <div className={clsx(styles.confirmDetailRow, 'confirmDetailRow')}>
              <div>Recipient&apos;s wallet address:</div>
              <div
                className={clsx(styles.recipientAddress, 'recipientAddress')}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {sendRecipientWallet}

                <span style={{ marginLeft: '5px', width: 18, height: 18 }}>
                  {recipientWalletStateIcon}
                </span>

                <CopyIcon
                  style={{
                    width: 20,
                    height: 20,
                    marginLeft: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={async () => copyToClipboard(sendRecipientWallet)}
                />
              </div>
            </div>
            <div
              className={clsx(styles.confirmDetailRow, 'confirmDetailRow')}
              style={{ display: recipientWalletWarningText ? '' : 'none' }}
            >
              <div>Attention:</div>
              <div style={{ borderBottom: '1px solid #F9CB00' }}>
                <img
                  src={WarningIconSVG}
                  alt=""
                  style={{ marginLeft: '5px', width: 18, height: 18 }}
                  title={recipientWalletStateText}
                />
                {recipientWalletWarningText}
              </div>
            </div>
            <div className={clsx(styles.confirmDetailRow, 'confirmDetailRow')}>
              <div>Amount:</div>
              <div>
                {sendAmount}&nbsp;{chainSettings?.nativeCurrency?.symbol}
              </div>
            </div>
            {/*
            <div className={clsx(styles.confirmDetailRow, 'confirmDetailRow')}>
              <div>Comment:</div>
              <div>{sendDescription}</div>
            </div>
            */}
            <div className={clsx(styles.confirmDetailRow, 'confirmDetailRow')}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                Commission CaFFee:
                <HelpTooltip
                  tooltipText="Users automatically redeem the carbon footprint of each transaction in FCE Blockchain with
                    CAFFEE. The price of the CAFFEE is dynamic and calculated by FCEM. After payment, the user
                    receives an sbd token, which represents a share of ownership in an offset certificate.
                    With each transaction on the FCE Blockchain, sbd tokens accumulate in users wallets,
                    confirming the carbon neutrality of each user and the entire Since FCE Blockchain does not
                    charge transaction fees, the CAFFEE for offsetting the carbon footprint of the transaction
                    is the only fee in the network."
                  placement="right"
                  overlayInnerStyle={{ maxWidth: 290 }}
                  arrow={false}
                />
              </div>
              <div>
                {fromWei(co2TokenCost || 0)}&nbsp;
                {chainSettings?.nativeCurrency?.symbol}
              </div>
            </div>
            <div className={`${styles.confirmDetailRow} ${styles.bold}`}>
              <div>Total:</div>
              <div>
                {sendTotalAmountStr}&nbsp;{chainSettings?.nativeCurrency?.symbol}
              </div>
            </div>
          </div>
          <div className={clsx(styles.simplePaymentRow2, 'simplePaymentRow2')}>
            <Button type="default" onClick={cancelConfirm} size="large">
              Back
            </Button>
            <Button
              type="primary"
              onClick={_simplePayment}
              size="large"
              disabled={!sentBntActive || [1, 3, 4, 5, 6].includes(recipientWalletState)}
            >
              Confirm
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SimpleSend;
