import React, { useState } from 'react';
import { Button, DatePicker, Modal, Select, Input, CheckBox, Form } from 'tt-ui-lib/core';
import styles from '../CertificatePage.module.scss';

const RegisterModal = ({ open, setOpen, id, createRegister }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [disclamerAccepted, setDisclamerAccepted] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const onSubmit = (formData) => {
    setLoading(true);

    createRegister(
      { id: id, ...formData },
      () => {
        setLoading(false);
        setCreateModalOpen(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const submitForm = (data) => {
    const newData = {
      ...data,
      company: '',
    };

    onSubmit(newData);
  };

  const modalClose = () => {
    setCreateModalOpen(false);
  };

  const accepDisclamer = () => {
    setCreateModalOpen(true);
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={createModalOpen}
        onClose={modalClose}
        title="Create a new distribution list"
        closeOnlyByControls
      >
        <div className={styles.infoModal}>
          <div className={styles.registerModalText}>
            Complete the form below. It is designed to assist you in organizing your data
            effectively.
            <br />
            <br />
            You can link your distribution list to specific product(s) or service(s) for which you
            aim to counterbalance the environmental impact. To do so, tick the box labeled
            &apos;Select a project&apos; and you will be able to select from a list of projects that
            you have previously evaluated using the <a href="">Carbon Footprint Calculator</a>.
          </div>

          <Form className={styles.controls} form={form} onFinish={submitForm} unstyled>
            <Form.Item name="name" rules={[{ required: true, message: 'Name is required' }]}>
              <Input label="Distribution List Name" />
            </Form.Item>

            <div className={styles.controlsRow}>
              <Form.Item name="date" rules={[{ required: true, message: 'Date is required' }]}>
                <DatePicker label="Date" format="DD-MM-YYYY" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="unit" rules={[{ required: true, message: 'Unit is required' }]}>
                <Select
                  label="Unit"
                  options={[
                    { value: 'g', label: 'g' },
                    { value: 't', label: 't' },
                  ]}
                />
              </Form.Item>
            </div>

            <Form.Item
              name="description"
              rules={[{ required: true, message: 'Description is required' }]}
            >
              <Input
                label="Description"
                required={false}
                multiline
                maxRows={4}
                className={styles.textArea}
              />
            </Form.Item>
          </Form>

          <div className={styles.bottom}>
            <Button type="default" onClick={() => modalClose()}>
              Cancel
            </Button>

            <Button type="primary" loading={loading} onClick={() => form.submit()}>
              Create
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="Legal Disclaimer"
        closeOnlyByControls
      >
        <div className={styles.infoModal}>
          <div
            className={styles.registerModalText}
            style={{ height: '50vh', overflow: 'auto', marginTop: 10 }}
          >
            You are about to enter third party personal information. Please read and agree before
            proceeding.
            <br />
            <br />
            By submitting the names and email addresses of third parties (&apos;Third-Party Personal
            Information&apos;) on <a href="https://transparenterra.com/">
              www.Transparenterra.com
            </a>{' '}
            (&apos;TransparenTerra&apos; or &apos;TransparenTerra platform&apos; or
            &apos;web-site&apos;), you acknowledge and agree to the following terms:
            <br />
            <br />
            1. <b>Authority to Share Information:</b> You represent and warrant that you have the
            full legal right and authority to disclose the Third-Party Personal Information to
            TransparenTerra. You further affirm that you have obtained all necessary consents and
            permissions from the individuals whose information you are sharing, in compliance with
            all applicable laws, including but not limited to data protection and privacy laws, and
            the relevant legal contracts between you and the data subjects. You further represent
            and warrant that you have notified those individuals of the disclosure of Third-Party
            Personal Information you are making on TransparenTerra.
            <br />
            <br />
            2. <b>Purpose of Collection:</b> The Third-Party Personal Information collected will be
            used solely for the purpose of sending the offset carbon certificates in connection with
            their purchase of goods and services in accordance with the relevant legal contract
            between a data subject and the seller and will not be used for any other purpose without
            obtaining additional consent from the individuals concerned.
            <br />
            <br />
            3. <b>Data Protection:</b> FCE Group AG is committed to protecting the privacy and
            security of all personal information collected. We will implement appropriate technical
            and organizational measures to ensure the confidentiality, integrity, and availability
            of the Third-Party Personal Information in accordance with our Privacy Policy which is
            an integral part hereof.
            <br />
            <br />
            4. <b>Removal and Rectification</b> of Third-Party Personal Information: You hereby
            covenant and agree to expeditiously remove or cause to be removed Third-Party Personal
            Information from TransparenTerra at the data subject request or upon revocation of their
            consent for the processing or transfer their personal data. You shall be solely
            responsible for timely and accurate corrections to personal data as may be requested by
            the data subject in accordance with applicable data protection laws and regulations.
            <br />
            <br />
            5. <b>Compliance with Laws:</b> You agree to comply with all relevant laws and
            regulations applicable to the collection and sharing of Third-Party Personal
            Information, including but not limited to the GDPR, or any other data protection
            legislation.
            <br />
            <br />
            6. <b>Indemnification:</b> You agree to indemnify, defend, and hold harmless FCE Group
            AG, its officers, directors, employees, agents, licensors, suppliers, and any
            third-party information providers from and against all claims, losses, expenses,
            damages, and costs, including reasonable attorneys&apos; fees, resulting from any
            violation of this disclaimer by you or any wrongful disclosure of Third-Party Personal
            Information.
            <br />
            <br />
            7. <b>Changes to Disclaimer:</b> FCE Group AG reserves the right to modify this
            disclaimer at any time. Your continued provision of Third-Party Personal Information
            after any such changes constitutes your acceptance of the new terms.
          </div>

          <CheckBox
            label="By clicking you confirm that you have read, understood, and agree to be bound by this disclaimer."
            value={disclamerAccepted}
            onChange={(e) => setDisclamerAccepted(e.target.checked)}
          />

          <div className={styles.bottom}>
            <Button type="default" onClick={() => setOpen(false)}>
              Cancel
            </Button>

            <Button type="primary" disabled={!disclamerAccepted} onClick={() => accepDisclamer()}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RegisterModal;
