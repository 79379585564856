import Cookies from 'js-cookie';
import { openNotification } from 'tt-ui-lib/core';

export const openAppLoader = (opacity) => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = opacity ?? '1';
    loader.style.visibility = 'visible';
  }
};

export const closeAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = '0';
    loader.style.visibility = 'hidden';
  }
};

export const logoutUserHandler = (logoutApiMethod, disconnectSocketMethod, withRedirect = true) => {
  openAppLoader();

  logoutApiMethod().finally(() => {
    closeAppLoader();
    const domainValue = process.env.REACT_APP_DOMAIN;
    Cookies.remove('access_token', { domain: domainValue });
    Cookies.remove('refresh_token', { domain: domainValue });
    Cookies.remove('userId', { domain: domainValue });
    Cookies.remove('companyId', { domain: domainValue });
    Cookies.remove('calculatorId', { domain: domainValue });
    Cookies.remove('last_active', {
      domain: domainValue,
    });
    localStorage.removeItem('currentUserData');
    localStorage.removeItem('currentCompanyData');

    if (disconnectSocketMethod) {
      disconnectSocketMethod();
    }

    if (withRedirect) {
      window.location.href = '/dashboard/digital_assets';
    } else {
      window.location.reload();
    }
  });
};

const isJWTTokenValid = (token) => {
  const tokenParts = token.split('.');

  if (tokenParts.length === 3) {
    try {
      const payload = JSON.parse(atob(tokenParts[1]));
      const expTime = payload.exp * 1000 - 60000;

      if (expTime && Date.now() <= expTime) {
        return true;
      }
    } catch (err) {
      console.error('Error decoding JWT token:', err);
      return false;
    }
  }

  return false;
};

export const refreshAuthToken = (refreshHandler) => {
  const domainValue = process.env.REACT_APP_DOMAIN;
  const accessToken = Cookies.get('access_token', { domain: domainValue });
  const refreshToken = Cookies.get('refresh_token', { domain: domainValue });

  const logout = () => {
    Cookies.remove('access_token', { domain: domainValue });
    Cookies.remove('refresh_token', { domain: domainValue });
    Cookies.remove('userId', { domain: domainValue });
    Cookies.remove('marketId', { domain: domainValue });
    Cookies.remove('companyId', { domain: domainValue });
    Cookies.remove('calculatorId', { domain: domainValue });
    Cookies.remove('last_active', {
      domain: domainValue,
    });

    localStorage.removeItem('currentUserData');
    localStorage.removeItem('currentCompanyData');
    window.location.href = '/dashboard/feed';
  };

  if (!accessToken || !refreshToken) {
    logout();
    return;
  }

  const isAccessTokenValid = isJWTTokenValid(accessToken);

  if (isAccessTokenValid) {
    return;
  }

  refreshHandler({
    variables: {
      input: {
        refresh_token: refreshToken,
      },
    },
  })
    .then((res) => {
      const newAccessToken = res?.data?.refreshToken?.access_token;
      const newRefreshToken = res?.data?.refreshToken?.refresh_token;

      if (newAccessToken && newRefreshToken) {
        Cookies.set('access_token', newAccessToken, { domain: domainValue });
        Cookies.set('refresh_token', newRefreshToken, { domain: domainValue });
        return;
      }

      logout();
    })
    .catch((err) => {
      logout();
    });
};

export const showError = (msg) =>
  openNotification({
    message: msg?.message || msg,
    type: 'error',
  });
