import React, { useEffect, useState } from 'react';
import { CertificatePage } from '../../../modules/tt-tokenized-assets/index';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
  getAuthorizedImageByLink,
  sendBlockchainApiRequest,
} from '../../../api/blockchain/endpoints/blockcahin-da-api';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CARBON_GET_REGISTERS,
  CARBON_CREATE_REGISTER,
  CARBON_GET_OFFSET_DATA,
  CARBON_OFFSET,
} from '../../../api';
import { openNotification, Modal, Button } from 'tt-ui-lib/core';
import { useSelectedContext } from 'context/contextSelector';
import {
  useDigitalAssets,
  useDigitalAssetsCaFFee,
  useDigitalAssetsCertificate,
} from 'modules/tt-digital-assets-provider';
import moment from 'moment';

const CarbonCertificatePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { user, selectedCompany } = useSelectedContext((context) => context.user);
  const { account } = useDigitalAssets();
  const { certificateDetachWallet, certificateOffset } = useDigitalAssetsCertificate();
  const { addCO2TokenToMetaMask } = useDigitalAssetsCaFFee();

  const [getCertificateRequest] = useMutation(CARBON_OFFSET);
  const [getCarbonOffsetDataRequest] = useMutation(CARBON_GET_OFFSET_DATA);
  const [createRegisterRequest] = useMutation(CARBON_CREATE_REGISTER);
  const [getCarbonRegistersRequest] = useLazyQuery(CARBON_GET_REGISTERS, {
    fetchPolicy: 'network-only',
  });

  const selectedCompanyId = selectedCompany?.isCompanySelected
    ? selectedCompany?.selectedCompany?.id || undefined
    : undefined;

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [offsetData, setOffsetData] = useState([]);
  const [certLoading, setCertLoading] = useState(false);
  const [updateCertImage, setUpdateCertImage] = useState(true);
  const [updateCertData, setUpdateCertData] = useState(true);
  const [openDetachModal, setOpenDetachModal] = useState(false);

  const getCertificateData = () => {
    setIsLoading(true);

    const payload = {
      id: 'getTokenizationAssetsData',
      method: 'getCarbonContract',
      cert_id: id,
    };

    sendBlockchainApiRequest(payload)
      .then((response) => {
        if (!response?.data?.result) {
          openNotification({
            type: 'error',
            message: 'Something went wrong, please try again',
          });
          return;
        }

        setData(response?.data?.result || null);
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: 'Something went wrong, please try again',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendCheck = (payload, onSuccess, onError) => {
    // TODO
  };

  const sendConfirm = (payload, onSuccess, onError) => {
    // TODO
  };

  const getCertificate = (payload, onSuccess, onError) => {
    const bcPayload = {
      contractAddress: data?.carbon_address,
      account: account,
      amount: payload?.variables?.input?.quantity,
    };

    certificateOffset(bcPayload)
      .then(() => {
        getCertificateRequest(payload)
          .then((response) => {
            if (onSuccess) onSuccess(response);
          })
          .catch(() => {
            if (onError) onError();
          });
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };

  const showCreditInMetamask = (onSuccess, onError) => {
    addCO2TokenToMetaMask()
      .then(() => {
        setUpdateCertData(true);
        if (onSuccess) onSuccess();
      })
      .catch(() => {
        if (onError) onError();
      });
  };

  const getOffsetDataCertificate = (payload, onSuccess, onError, onFinally) => {
    getCarbonOffsetDataRequest(payload)
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onError) {
          onError(err);
        }
      })
      .finally(() => {
        if (onFinally) {
          onFinally();
        }
      });
  };

  const createRegister = (payload, onSuccess, onError) => {
    const parsedProjectData = JSON.parse(data?.project || '{}') || null;
    const input = {
      user_id: user.id,
      name: payload.name,
      description: payload.description,
      unit: payload.unit,
      carbon_credit_id: data?.id,
      carbon_credit_type: parsedProjectData?.type?.name,
      date: moment(payload.date).format('YYYY-MM-DD'),
      company_id: selectedCompanyId,
    };

    createRegisterRequest({ variables: { input } })
      .then((response) => {
        navigate(`/dashboard/certificate/${id}/register/${response.data.createRegistry.id}`);

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((err) => {
        openNotification({
          type: 'error',
          message: 'Something went wrong, please try again',
        });

        if (onError) {
          onError(err);
        }
      });
  };

  const getCertificateRegisters = (payload, onSuccess, onError) => {
    getCarbonRegistersRequest({ variables: payload })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onError) {
          onError(err);
        }
      });
  };

  const detachWallet = () => {
    setOpenDetachModal(true);
  };

  const detachConfirm = () => {
    const payload = {
      account: account,
      contractAddress: data?.carbon_address,
    };

    certificateDetachWallet(payload)
      .then(() => {
        navigate('/dashboard/digital_assets?activeTab=tokens');
      })
      .catch((err) => {
        openNotification({
          type: 'error',
          message: err
            ? `${err}`.replace(/^\w/, (c) => c.toUpperCase())
            : 'Something went wrong, please try again',
        });
      });
  };

  useEffect(() => {
    if (id && updateCertData) {
      getCertificateData();
      setUpdateCertData(false);
    }
  }, [updateCertData]);

  useEffect(() => {
    if (updateCertImage) {
      setCertLoading(true);
      getOffsetDataCertificate(
        { variables: { input: { carbon_ucr_credit_id: id } } },
        (response) => {
          if (response && response?.data?.getAllOffsetData[0]) {
            setOffsetData(response.data.getAllOffsetData);
            setCertLoading(false);
          }
        },
        () => {
          setCertLoading(false);
        },
        () => {
          setCertLoading(false);
        }
      );
      setUpdateCertImage(false);
    }
  }, [updateCertImage]);

  return (
    <>
      <CertificatePage
        data={data}
        isLoading={isLoading}
        navigate={navigate}
        getAuthorizedImageByLink={getAuthorizedImageByLink}
        sendCheck={sendCheck}
        sendConfirm={sendConfirm}
        getCertificate={getCertificate}
        createRegister={createRegister}
        detachWallet={detachWallet}
        getCertificateRegisters={getCertificateRegisters}
        offsetData={offsetData}
        setUpdateCertImage={setUpdateCertImage}
        setUpdateCertData={setUpdateCertData}
        certLoading={certLoading}
        showCreditInMetamask={showCreditInMetamask}
        backUrl="/dashboard/digital_assets?activeTab=tokens"
      />

      <Modal
        title="Detach wallet"
        open={openDetachModal}
        onClose={() => {
          setOpenDetachModal(false);
        }}
      >
        <p>
          To transfer the certificate balance from your wallet, you must confirm the action in
          Metamask. Once confirmed, the balance will be moved to the Financials section and will
          disappear from Digital Assets.
        </p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 30,
          }}
        >
          <Button onClick={() => setOpenDetachModal(false)}>Cancel</Button>

          <Button type="primary" onClick={() => detachConfirm()}>
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CarbonCertificatePage;
