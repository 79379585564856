import React from 'react';
import styles from '../SendCreditsModal.module.scss';
import { Button, HelpTooltip } from 'tt-ui-lib/core';

const ConfirmStep = ({ confirmData, setStep, submitDisable, onSubmit, loading }) => {
  const submit = () => {
    onSubmit();
  };

  return (
    <div className={styles.body}>
      <div className={styles.modalInfo}>
        <div className={styles.infoItem}>
          <span>Credits:</span> <span>{confirmData?.credits || '-'}</span>
        </div>

        <div className={styles.infoItem}>
          <span>Credit balance:</span> <span>{confirmData?.balance || '-'}</span>
        </div>

        <div className={styles.infoItem}>
          <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
            Commission CAFFEE: <HelpTooltip tooltipText="To be soon..." />
          </span>
          <span>-</span>
        </div>

        <div className={styles.infoItem}>
          <span>Attention:</span> <span>-</span>
        </div>

        <div className={styles.infoItem}>
          <span>Recipient wallet:</span> <span>-</span>
        </div>

        <div className={styles.infoItem}>
          <span>Amount to send:</span> <span>-</span>
        </div>
      </div>

      <div className={styles.bottom}>
        <Button type="default" onClick={() => setStep('send')}>
          Back
        </Button>

        <Button loading={loading} type="primary" disabled={submitDisable} onClick={() => submit()}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ConfirmStep;
