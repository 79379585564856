import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { Button, Input, Form, openNotification } from 'tt-ui-lib/core';
import { useMutation } from '@apollo/client';
import { FORGOT_USER_PASSWORD } from '../../../constants/api';
import styles from '../SignInModal.module.scss';

export const ResetModal = ({ setMode }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const [resetPassword] = useMutation(FORGOT_USER_PASSWORD);

  const onSubmit = async (data) => {
    setLoading(true);

    resetPassword({ variables: { input: data } })
      .then(() => {
        openNotification({
          message: "We've sent you an email with a link to reset your password.",
          type: 'success',
        });
        setMode('in');
        setErrorEmail(false);
      })
      .catch(() => {
        form.setFields([
          {
            name: 'email',
            errors: ['There is no user with this e-mail in our community'],
          },
        ]);
        setErrorEmail(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.formWrapper}>
      <Form className={styles.form} form={form} onFinish={onSubmit}>
        <Form.Item
          name="email"
          className={styles.inputWrapper}
          rules={[{ required: true, message: 'Please enter your email address' }]}
        >
          <Input label="Email *" />
        </Form.Item>

        <div className={styles.formBottom}>
          <div className={styles.formLink} onClick={() => setMode('in')}>
            Remember password? Sign in
          </div>
          <Button
            className={styles.formSubmitButton}
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Reset
          </Button>
        </div>
      </Form>
    </div>
  );
};
