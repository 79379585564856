import React from 'react';
import { Modal, CodeView } from 'tt-ui-lib/core';

const ContractCompileResultModal = ({
  showCompileResultDialog,
  setShowCompileResultDialog,
  compileResult,
}) => (
  <Modal
    open={showCompileResultDialog}
    onClose={() => setShowCompileResultDialog(false)}
    title="Compile result"
    destroyOnClose
  >
    <div>
      <b>SOLC version:</b> {compileResult?.solc}
    </div>
    <div>Messages</div>
    <div>
      {compileResult?.errors?.map((error) => (
        <div>
          <div style={{ width: '100%', maxWidth: '100%', breakWord: 'break-all' }}>
            <b>{error?.type}</b>: {error?.file}
          </div>
          <div>
            <CodeView
              code={error?.message || '-'}
              style={{ width: '100%', maxWidth: '100%', breakWord: 'break-all' }}
            />
          </div>
        </div>
      ))}
    </div>
    <div>
      <b>Update contracts:</b>
    </div>
    <div>
      {compileResult?.contracts?.map((contract) => (
        <div style={{ width: '100%', maxWidth: '100%', breakWord: 'break-all' }}>{contract}</div>
      ))}
    </div>
  </Modal>
);

export default ContractCompileResultModal;
