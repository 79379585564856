import React, { useEffect, useRef, useState } from 'react';
import styles from './AvatarCropModal.module.scss';
import AvatarEditor from 'react-avatar-editor';
import { Button, Modal, ANTD_COMPONENTS } from 'tt-ui-lib/core';
import { RotateLeftIcon, RotateRightIcon } from 'tt-ui-lib/icons';

const { Slider } = ANTD_COMPONENTS;

const AvatarCropModal = ({
  open,
  height = false,
  width = false,
  borderRadius = false,
  setOpen,
  avatarFile,
  setAvatar,
  type = 'dashboard',
}) => {
  const editor = useRef(null);

  const [fileName, setFileName] = useState('');
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1);
  const [submitDisable, setSubmitDisable] = useState(false);

  const onClose = () => {
    setOpen(false);
    setRotate(0);
    setScale(1);
  };

  useEffect(() => setSubmitDisable(false), []);

  const saveImage = () => {
    setSubmitDisable(true);
    editor.current.getImage()?.toBlob((blob) => {
      if (blob) {
        const newEditedFile = new File([blob], fileName, { type: blob.type });
        setAvatar(newEditedFile);
        onClose();
      }
    });
  };

  const rotateImage = (direction) => {
    if (direction === 'right') {
      setRotate((prevState) => (prevState === 360 ? 90 : prevState + 90));
    } else {
      setRotate((prevState) => (prevState === 0 ? 270 : prevState - 90));
    }
  };

  useEffect(() => {
    if (avatarFile) {
      setFileName(avatarFile.name);
    }
  }, [avatarFile]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Zoom and crop"
      type={type}
      className={styles.modalContainer}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={styles.body}>
        <AvatarEditor
          ref={editor}
          image={avatarFile || ''}
          width={width || 220}
          height={height || 220}
          border={40}
          borderRadius={borderRadius || 110}
          scale={scale}
          rotate={rotate}
        />

        <div className={styles.sliderContainer}>
          <Slider
            value={scale}
            onChange={(e) => setScale(e)}
            defaultValue={1}
            step={0.1}
            max={5}
            min={1}
          />
        </div>

        <div className={styles.rotateContainer}>
          <Button type="icon" onClick={() => rotateImage('left')}>
            <RotateLeftIcon style={{ width: 24, height: 24 }} />
          </Button>
          <span>Rotate</span>
          <Button type="icon" onClick={() => rotateImage('right')}>
            <RotateRightIcon style={{ width: 24, height: 24 }} />
          </Button>
        </div>

        <div className={styles.modalButtons}>
          <Button
            type="default"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={submitDisable}
            type="primary"
            onClick={() => {
              saveImage();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AvatarCropModal;
