import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Loader, HelpTooltip, Modal } from 'tt-ui-lib/core';
import { useDigitalAssets, useDigitalAssetsCaFFee } from 'modules/tt-digital-assets-provider';
import CarbonMainCertificateDetailModal from '../MainCertificate';

import MainCertificateIMG from '../../assets/co2_certificate_16714506896614.png';
import DownloadWhiteIconSVG from 'assets/images/download_white.svg';
import ZoomPlusIconSVG from 'assets/images/zoom-plus.svg';

import styles from './styles.module.scss';
import { getAuthorizedImageByLink } from '../../../../../api/blockchain/endpoints/blockcahin-da-api';
import { CopyIcon } from 'tt-ui-lib/icons';

const { REACT_APP_SSO_API } = process.env;

const CarbonTransactionDetailModal = (props) => {
  const {
    className,
    style,
    showCarbonTransactionDetail,
    setCarbonShowTransactionDetail,
    certificateInfo,
  } = props;
  const { copyToClipboard, downloadPDFFile } = useDigitalAssets();
  const { getCO2CertInfo } = useDigitalAssetsCaFFee();

  const [showDownloadCert, setShowDownloadCert] = useState('none');
  const [showDownloadMainCert, setShowDownloadMainCert] = useState('none');
  const [referCertificateInfo, setReferCertificateInfo] = useState({});
  const [referCertificateLoading, setReferCertificateLoading] = useState(false);

  const [showCarbonMainCertificateDetail, setShowCarbonMainCertificateDetail] = useState(false);
  const [certJPGURL, setCertJPGURL] = useState('');
  const [certLoading, setCertLoading] = useState(false);
  const [certDownloadLoading, setCertDownloadLoading] = useState(false);

  const divDownloadCert = useRef();

  const downloadCertHandle = () => {
    setCertDownloadLoading(true);

    downloadPDFFile(
      `${REACT_APP_SSO_API}/wallet/current_certificate/pdf?transaction=${certificateInfo?.hash}`,
      'transactionCertificate.pdf'
    ).finally(() => {
      setCertDownloadLoading(false);
    });
  };

  const certMouseOverHandler = () => {
    setShowDownloadCert('flex');
  };
  const certMouseOutHandler = () => {
    setShowDownloadCert('none');
  };

  const mainCertMouseOverHandler = () => {
    setShowDownloadMainCert('flex');
  };
  const mainCertMouseOutHandler = () => {
    setShowDownloadMainCert('none');
  };

  const getRelatedCertInfo = async (id, certData) => {
    setReferCertificateLoading(true);

    if (id) {
      const res = (await getCO2CertInfo(id)) || {};
      res.to = 'TransparenTerra';
      res.address = id;
      setReferCertificateInfo(res);
    } else {
      setReferCertificateInfo(certData);
    }

    setCertLoading(true);

    getAuthorizedImageByLink(
      `${REACT_APP_SSO_API}/wallet/current_certificate/jpg?transaction=${
        `${certificateInfo?.hash}`.slice(2) || ''
      }`
    )
      .then((imageData) => {
        if (imageData) {
          setCertJPGURL(imageData);
        }
      })
      .catch(() => {
        setCertJPGURL('');
      })
      .finally(() => {
        setCertLoading(false);
      });

    setReferCertificateLoading(false);
  };

  useEffect(() => {
    if (showCarbonTransactionDetail) {
      // if (!certificateInfo?.cert?.address && !certificateInfo?.cert?.number) {
      getRelatedCertInfo(certificateInfo?.cert?.address, certificateInfo?.cert).then();
      // } else {
      //   setReferCertificateInfo(certificateInfo?.cert);
      // }
    }
  }, [showCarbonTransactionDetail]);

  return (
    <div>
      <Modal // Transaction offset certificate
        className={clsx(styles.carbonTransactionDetailModal, className)}
        style={{ ...style, overflowX: 'hidden' }}
        open={showCarbonTransactionDetail}
        onClose={() => setCarbonShowTransactionDetail(false)}
        fullScreenOnResponse
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Transaction offset certificate&nbsp;
            <HelpTooltip
              iconStyle={{ display: 'flex' }}
              tooltipText="A user offset certificate redeems the carbon footprint of a user transaction in FCE
              blockchain. It is the tokenized part of offset certificate that is automatically
              calculated and assigned to the initiator of the transaction. The carbon footprint of
              transactions is calculated using the average value."
            />
          </div>
        }
      >
        {referCertificateLoading ? (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        ) : (
          <>
            <div className={clsx(styles.row1, 'row1')}>
              <div className={clsx(styles.row1_1, 'row1_1')}>
                <div className={clsx(styles.col1_1, 'col1_1')}>
                  <span style={{ width: '150px', fontWeight: 'bold' }}>Hash:</span>
                  <span>{certificateInfo?.hash_short}</span>&nbsp;
                  <CopyIcon
                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                    onClick={async () => copyToClipboard(certificateInfo?.hash)}
                  />
                </div>
                <div className={clsx(styles.col1_1, 'col1_1')}>
                  <span style={{ width: '150px', fontWeight: 'bold' }}>Date/Time:</span>
                  <span>{certificateInfo?.datetime?.toString() || '-'}</span>
                </div>
                <div className={clsx(styles.col1_1, 'col1_1')}>
                  <span style={{ width: '150px', fontWeight: 'bold' }}>
                    {certificateInfo?.symbol}:
                  </span>
                  <span>{certificateInfo?.caffee || '-'}</span>
                </div>
                <div className={clsx(styles.col1_1, 'col1_1')}>
                  <span style={{ width: '150px', fontWeight: 'bold' }}>Offsetted:</span>
                  <span>{certificateInfo?.offsetted_transaction || '- '}g CO2</span>
                </div>
              </div>
              <div className={styles.certBlock}>
                {certLoading ? (
                  <div className={styles.certLoadWrapper}>
                    <Loader style={{ marginLeft: 10 }} />
                  </div>
                ) : certJPGURL ? (
                  <img
                    src={certJPGURL}
                    alt=""
                    style={{ width: 300, height: 200 }}
                    onMouseOver={certMouseOverHandler}
                    onFocus={certMouseOverHandler}
                  />
                ) : (
                  <div className={styles.notFound}>No certificate found...</div>
                )}
                <div
                  ref={divDownloadCert}
                  className={clsx(styles.certDownload, 'certDownload')}
                  style={{
                    display: showDownloadCert,
                    width: 300,
                    height: 200,
                  }}
                  onMouseOut={certMouseOutHandler}
                  onBlur={certMouseOutHandler}
                >
                  {certDownloadLoading ? (
                    <Loader color="#ffffff" />
                  ) : (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <img
                      src={DownloadWhiteIconSVG}
                      alt=""
                      style={{ width: 24, height: 24 }}
                      onClick={downloadCertHandle}
                    />
                  )}
                </div>
              </div>
            </div>

            <h2
              className={clsx(styles.h2, 'h2')}
              style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}
            >
              Related to
            </h2>
            <div className={clsx(styles.row1, 'row1')}>
              <div className={clsx(styles.row1_1, 'row1_1')}>
                <div className={clsx(styles.col1_1, 'col1_1')}>
                  <span style={{ width: '150px', fontWeight: 'bold' }}>Number:</span>
                  {referCertificateInfo?.number ? (
                    <>
                      <span>{referCertificateInfo?.number}</span>&nbsp;
                      <CopyIcon
                        style={{ width: 20, height: 20, cursor: 'pointer' }}
                        onClick={async () => copyToClipboard(certificateInfo?.cert?.number)}
                      />
                    </>
                  ) : (
                    '-'
                  )}
                </div>
                <div className={clsx(styles.col1_1, 'col1_1')}>
                  <span style={{ width: '150px', fontWeight: 'bold' }}>From:</span>
                  <span>{referCertificateInfo?.brand || '-'}</span>
                </div>
                <div className={clsx(styles.col1_1, 'col1_1')}>
                  <span style={{ width: '150px', fontWeight: 'bold' }}>To:</span>
                  <span>{referCertificateInfo?.to || '-'}</span>
                </div>
                <div className={clsx(styles.col1_1, 'col1_1')}>
                  <span style={{ width: '150px', fontWeight: 'bold' }}>Date:</span>
                  <span>{(referCertificateInfo?.date?.toString() || '-').substring(0, 10)}</span>
                </div>
                <div className={clsx(styles.col1_1, 'col1_1')}>
                  <span style={{ width: '150px', fontWeight: 'bold' }}>Offsetted:</span>
                  <span>
                    {parseFloat(referCertificateInfo?.value || 0).toFixed(2) || '-'} tonne(s)
                  </span>
                </div>
              </div>
              <div className={styles.certBlock}>
                <img
                  src={MainCertificateIMG}
                  alt=""
                  style={{ width: 300, height: 200 }}
                  onMouseOver={mainCertMouseOverHandler}
                  onFocus={mainCertMouseOverHandler}
                />
                <div
                  ref={divDownloadCert}
                  className={clsx(styles.certDownload, 'certDownload')}
                  style={{
                    display: showDownloadMainCert,
                    width: 300,
                    height: 200,
                  }}
                  onMouseOut={mainCertMouseOutHandler}
                  onBlur={mainCertMouseOutHandler}
                >
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <img
                    src={ZoomPlusIconSVG}
                    alt=""
                    style={{ width: 24, height: 24 }}
                    onClick={() => setShowCarbonMainCertificateDetail(true)}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>

      <CarbonMainCertificateDetailModal
        showCarbonMainCertificateDetail={showCarbonMainCertificateDetail}
        setShowCarbonMainCertificateDetail={setShowCarbonMainCertificateDetail}
        certificate={{ ...referCertificateInfo, addr: certificateInfo?.cert?.address }}
      />
    </div>
  );
};

export default CarbonTransactionDetailModal;
