import React, { useEffect, useState } from 'react';
import { useDigitalAssets, useDigitalAssetsBridge } from 'modules/tt-digital-assets-provider';

import styles from './styles.module.scss';
import { Tabs } from 'tt-ui-lib/core';
import { useSearchParams } from 'react-router-dom';

const MainMenu = (props) => {
  const { showedTab, setShowedTab, setShowSwitchDialog, setToNet, updateMenu, setUpdateMenu } =
    props;
  const [searchParams, setSearchParams] = useSearchParams();

  const { chainId } = useDigitalAssets();
  const { bridges, bridge } = useDigitalAssetsBridge();

  const [menu, setMenu] = useState([
    {
      id: `explorer`,
      name: 'FCE.Explorer',
      child: [
        { id: 'transfer', name: 'Transfer' },
        { id: 'holders', name: 'Holders' },
      ],
    },
    { id: 'tokens', name: 'Tokenization Assets', disabled: false },
    { id: 'bridge', name: 'Bridge', child: null, disabled: !bridge },
    { id: 'contracts', name: 'Smart Contracts', disabled: true },
  ]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedChildTab, setSelectedChildTab] = useState(0);
  const [selectedChildTabId, setSelectedChildTabId] = useState(null);
  const [currentSubmenu, setCurrentSubmenu] = useState([]);

  const changeChain = (id) => {
    if (parseInt(id, 10) !== bridge?.id) {
      // Запрос на переключение если сети не совпадают
      setToNet(parseInt(id, 10));
      setShowSwitchDialog(true);
    }
  };

  const onChangeTab = (value) => {
    if (!value && value !== 0) return;

    setSelectedTab(value);

    const menuItem = menu[value];

    if (!menuItem?.id?.includes('explorer')) {
      setSearchParams({ activeTab: menuItem?.id });
    } else {
      setSearchParams({});
    }

    if (menuItem?.child) {
      setCurrentSubmenu(menuItem.child);
      setSelectedChildTab(0);
      setSelectedChildTabId(menuItem.child[0].id || '');
      setShowedTab(`${menuItem.id || ''}.${menuItem.child[0].id || ''}`);
    } else {
      setCurrentSubmenu([]);
      setSelectedChildTab(null);
      setSelectedChildTabId(null);
      setShowedTab(`${menuItem.id || ''}`);
    }
  };

  const onChangeChildTab = (value) => {
    if (!value && value !== 0) return;

    setSelectedChildTab(value);

    const menuItem = menu[selectedTab];
    const childItem = menuItem?.child[value] || undefined;

    if (menuItem && childItem) {
      if (menuItem.id?.includes('bridge')) {
        const newChainId = childItem.id;

        if (newChainId) {
          changeChain(newChainId);
        }
      }

      setSelectedChildTabId(childItem.id || null);
      setShowedTab(`${menuItem.id || ''}.${childItem.id || ''}`);
    }
  };

  useEffect(() => {
    if (searchParams.has('activeTab')) {
      const activeTabFromUrl = searchParams.get('activeTab');
      const activeTabIndex = menu.findIndex((item) => item.id === activeTabFromUrl);
      if (activeTabIndex !== -1) {
        onChangeTab(activeTabIndex);
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    if (menu) {
      const b = menu.find((el) => el?.id === 'bridge');

      if (b) {
        const sm = b.child || [];
        (bridges || []).forEach((brdg) => {
          if (!sm.find((el) => el?.id === brdg.id)) {
            sm.push({ id: brdg?.id, name: brdg?.name });
          }
        });
        b.child = sm;
        b.disabled = !bridge;

        const newMenu = [...menu];
        newMenu[2] = b;
        setMenu(newMenu);
      }
    }
  }, [bridges, bridge]);

  useEffect(() => {
    // Отметка выбрано через MetaMask сети
    if (showedTab.includes('bridge')) {
      const chId = parseInt(chainId, 16);
      const brdg = (bridges || []).find((el) => el?.chain_id === chId);

      setShowedTab(`bridge.${brdg?.id}`);
    }
  }, [chainId, bridges]);

  useEffect(() => {
    if (updateMenu) {
      if (showedTab.includes('bridge')) {
        const tabId = parseInt((showedTab || '').split('.')[1] || '', 10);

        if ((tabId || tabId === 0) && selectedChildTabId !== tabId) {
          let neededIndex = null;
          currentSubmenu.forEach((el, index) => {
            if (el.id === tabId) {
              neededIndex = index;
            }
          });

          if (neededIndex !== null) {
            setSelectedChildTab(neededIndex);
            setSelectedChildTabId(tabId);
          }
        }
      }
    }
    setUpdateMenu(false);
  }, [updateMenu]);

  useEffect(() => {
    if (showedTab.includes('bridge')) {
      setTimeout(() => {
        setShowedTab(`bridge.${bridge.id}`);
        setUpdateMenu(true);
      }, 100);
    }
  }, [bridge, showedTab]);

  useEffect(() => {
    console.log(`MainMenu. Change current bridge`, bridge);
  }, [bridge]);

  return (
    <Tabs
      containerClassName={`${styles.MainMenu}`}
      tabs={menu}
      selectedTab={selectedTab}
      selectedChildTab={selectedChildTab}
      onChangeTab={onChangeTab}
      onChangeChildTab={onChangeChildTab}
    />
  );
};

export default MainMenu;
