import React, { useEffect, useState } from 'react';
import { Button, HelpTooltip } from 'tt-ui-lib/core';
import { roles } from '../../../../constants/roles';
import { Controller, useForm } from 'react-hook-form';
import AvatarUploader from '../../AvatarUploader';
import ReactPhoneInput from 'react-phone-input-2';
import clsx from 'clsx';
import { PolicyModalWrapper } from '../../../PolicyModal';
import { useLazyQuery } from '@apollo/client';

import PlusBlueSVG from './assets/plusBlue.svg';
import MapIconSVG from './assets/map-icon.svg';

import styles from '../../SignInModal.module.scss';

const ExpertForm = ({ setMode, agreementSchema, closeModal }) => {
  const [submitDisable, setSubmitDisable] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [phoneInputLabel, setPhoneInputLabel] = useState('');
  const [address, setAddress] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [country, setCountry] = useState('');
  const [location, setLocation] = useState('');
  const [postal, setPostal] = useState('');
  const [addressError, setAddressError] = useState(null);
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [isPolicyAgree, setIsPolicyAgree] = useState(false);
  const [isPolicyLoading, setIsPolicyLoading] = useState(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);
  const [isHonorAgree, setIsHonorAgree] = useState(false);
  const [isHonorModalOpen, setIsHonorModalOpen] = useState(false);
  const [policyText, setPolicyText] = useState(null);
  const [honorText, setHonorText] = useState(null);

  const [getAgreement, { agreementPrivacyAndHonor }] = useLazyQuery(agreementSchema, {});

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      address: '',
      avatar: '',
      location: '',
      password: '',
      role: 'expert',
    },
  });

  const renderSelectValue = (val) => {
    const roleItem = roles.find((item) => item.value === val);
    return roleItem?.name || null;
  };

  const onClose = (setModal, loading) => {
    if (!loading) {
      setModal(false);
    }
  };

  const onTelChange = async (value) => {
    setValue('phone', value);
  };

  const onChangeAvatar = async (value) => {
    setAvatar(value);
    setValue('avatar', value);
  };

  const getLocation = (fullAddress) =>
    fullAddress
      .split(',')
      .reduce((res, item) => `${res && res.length ? `${res},` : res} ${item}`, '');

  const handleModalClose = (setIsOpen) => setIsOpen(false);

  const onSubmit = (values) => {};

  const getAgreementData = async () => {
    setIsPolicyLoading(true);

    const res = await getAgreement();

    if (res?.data?.agreementPrivacyAndHonor?.length > 0) {
      const policy = res.data.agreementPrivacyAndHonor.find(
        (agreement) => agreement.field === 'Privacy Policy'
      );

      setPolicyText(policy.body);

      const honor = res.data.agreementPrivacyAndHonor.find(
        (agreement) => agreement.field === 'Honor Code'
      );

      setHonorText(honor.body);
    }

    setIsPolicyLoading(false);
  };

  useEffect(() => {
    if (address && locationData) {
      setLocation(getLocation(address));
      setCountry(locationData.country || '');
      setPostal(locationData.postcode || '');
      setValue('location', {
        address: address,
        data: {
          latLng: {
            lat: coordinates?.lat.toString(),
            lng: coordinates?.lng.toString(),
          },
          country: locationData.country || '',
          postcode: locationData.postcode || '',
          locality: locationData.locality || '',
        },
      });

      setValue('address', address);
    }
  }, [address, locationData]);

  return (
    <div>
      <div className={styles.roleTextBlock}>
        <div>
          You are about to apply for sign up on the TransparenTerra platform. To ensure a smooth
          processing of your application, please complete all the required fields accurately. This
          will allow us to verify your details and proceed with approving your application.
        </div>
        <div className={styles.form}>
          {/* <Select
            className={styles.roleSelect}
            label="Role"
            type="default"
            onChange={(e) => setMode(`signUp_${e.target.value}`)}
            value="expert"
            renderValue={renderSelectValue}
            items={roles}
          /> */}
        </div>
        <div className={styles.roleDescription}>
          Experts are independent members of our community who provide advice, assessment, and
          consulting to other community members.
        </div>
      </div>

      <form name="login" method="POST" onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.form}>
          <div className={`${styles.row} ${styles.verticalCenter}`}>
            <div>
              <AvatarUploader avatarSrc={avatar} changeAvatar={(value) => onChangeAvatar(value)} />
            </div>

            <div className={styles.col}>
              <div className={styles.inputSignupWrapper}>
                <Controller
                  name="firstName"
                  control={control}
                  // render={({ field: { value, onChange, ...field } }) => (
                  //   <TextInput
                  //     {...field}
                  //     label="First Name"
                  //     disableUnderline
                  //     value={value}
                  //     onChange={onChange}
                  //     error={!!errors.firstName}
                  //     helperText={errors.firstName?.message}
                  //   />
                  // )}
                />
              </div>
              <div className={styles.inputSignupWrapper}>
                <Controller
                  name="email"
                  control={control}
                  // render={({ field: { value, onChange, ...field } }) => (
                  //   <TextInput
                  //     {...field}
                  //     label="Email"
                  //     value={value}
                  //     onChange={onChange}
                  //     error={!!errors.email}
                  //     helperText={errors.lastName?.email}
                  //   />
                  // )}
                />
              </div>
            </div>

            <div className={styles.col}>
              <div className={styles.inputSignupWrapper}>
                <Controller
                  name="lastName"
                  control={control}
                  // render={({ field: { value, onChange, ...field } }) => (
                  //   <TextInput
                  //     {...field}
                  //     label="Last Name"
                  //     disableUnderline
                  //     onChange={onChange}
                  //     value={value}
                  //     error={!!errors.lastName}
                  //     helperText={errors.lastName?.message}
                  //   />
                  // )}
                />
              </div>
              <div className={styles.inputSignupWrapper}>
                <Controller
                  name="phone"
                  control={control}
                  // render={({ field: { value, onChange, ...field } }) => (
                  //   <Box sx={{ width: '100%' }}>
                  //     <ReactPhoneInput
                  //       onChange={onTelChange}
                  //       onFocus={() => setPhoneInputLabel('Phone number')}
                  //       onBlur={() => setPhoneInputLabel('')}
                  //       value={value}
                  //       id="phone-input"
                  //       specialLabel={value ? 'Phone number' : phoneInputLabel}
                  //       placeholder="Phone number"
                  //       buttonClass={styles.phone}
                  //       inputClass={clsx(
                  //         styles.phoneInput,
                  //         errors.tel?.message ? styles.errorInput : ''
                  //       )}
                  //     />
                  //     {errors.tel?.message && (
                  //       <div className={styles.errorMessage}>{errors.tel?.message}</div>
                  //     )}
                  //   </Box>
                  // )}
                />
              </div>
            </div>
          </div>

          <div className={`${styles.row} ${styles.pdTopRow_L}`}>
            {/* <TextInput
              style={{ width: '100%' }}
              label="Locations within this area, country/region, postal code"
              value={address}
              onClick={() => setIsLocationModal(true)}
              data-type="location"
              // error={!postal}
              helperText={addressError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Image src={MapIconSVG} width={26} height={22} />
                  </InputAdornment>
                ),
              }}
            /> */}
          </div>

          <div className={`${styles.row} ${styles.pdTopRow}`}>
            <div className={styles.inputSignupWrapper}>
              <Controller
                name="password"
                control={control}
                // render={({ field: { value, onChange, ...field } }) => (
                //   <TextInput
                //     {...field}
                //     label="Password"
                //     autoComplete="new-password"
                //     onChange={onChange}
                //     type={showPassword ? 'text' : 'password'}
                //     InputProps={{
                //       endAdornment: (
                //         <InputAdornment position="end">
                //           <Button
                //             type="icon"
                //             aria-label="toggle password visibility"
                //             onClick={() => {
                //               setShowPassword((show) => !show);
                //             }}
                //             onMouseDown={(e) => {
                //               e.preventDefault();
                //             }}
                //             edge="end"
                //           >
                //             {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                //           </Button>
                //         </InputAdornment>
                //       ),
                //     }}
                //   />
                // )}
              />
            </div>
            <div className={styles.inputSignupWrapper}>
              <Controller
                name="password_confirmation"
                control={control}
                // render={({ field: { value, onChange, ...field } }) => (
                //   <TextInput
                //     {...field}
                //     label="Password confirmation"
                //     autoComplete="new-password"
                //     onChange={onChange}
                //     type={showPassword ? 'text' : 'password'}
                //     InputProps={{
                //       endAdornment: (
                //         <InputAdornment position="end">
                //           <Button
                //             type="icon"
                //             aria-label="toggle password visibility"
                //             onClick={() => {
                //               setShowPassword((show) => !show);
                //             }}
                //             onMouseDown={(e) => {
                //               e.preventDefault();
                //             }}
                //             edge="end"
                //           >
                //             {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                //           </Button>
                //         </InputAdornment>
                //       ),
                //     }}
                //   />
                // )}
              />
            </div>
          </div>
        </div>

        <div className={styles.section}>
          <p className={styles.subTitleCategory}>Experience*</p>
          <div className={styles.row}>
            <div className={styles.inputSignupWrapper}>
              <Controller
                name="title"
                control={control}
                // render={({ field: { value, onChange, ...field } }) => (
                //   <TextInput
                //     {...field}
                //     label="Title"
                //     disableUnderline
                //     onChange={onChange}
                //     value={value}
                //     error={!!errors.lastName}
                //     helperText={errors.lastName?.message}
                //   />
                // )}
              />
            </div>
            <div className={styles.inputSignupWrapper}>
              <Controller
                name="company"
                control={control}
                // render={({ field: { value, onChange, ...field } }) => (
                //   <TextInput
                //     {...field}
                //     label="Company"
                //     disableUnderline
                //     onChange={onChange}
                //     value={value}
                //     error={!!errors.lastName}
                //     helperText={errors.lastName?.message}
                //   />
                // )}
              />
            </div>
            <div className={styles.inputSignupWrapper}>
              <Controller
                name="peroid"
                control={control}
                // render={({ field: { value, onChange, ...field } }) => (
                //   <TextInput
                //     {...field}
                //     label="Period"
                //     disableUnderline
                //     onChange={onChange}
                //     value={value}
                //     error={!!errors.lastName}
                //     helperText={errors.lastName?.message}
                //   />
                // )}
              />
            </div>
          </div>

          <div className={`${styles.row} ${styles.addFileSection}`}>
            <Button type="default">
              <div className={styles.buttonWithImage}>
                <img src={PlusBlueSVG} alt="" />
                <span>Add diplomas / certificates / documents</span>
              </div>
            </Button>
            <HelpTooltip tooltipText="Please add your academic degrees, professional licenses and certificates proving your education and qualifications. This is necessary to become part of TransparenTerra's professional community." />
          </div>
        </div>

        <div className={styles.policyWrapper}>
          {/* <FormControlLabel
            control={
              <CheckBox
                checked={isPolicyAgree}
                onChange={async () => {
                  if (isPolicyAgree) {
                    setIsPolicyAgree(false);
                  } else {
                    getAgreementData().then();
                    setIsPolicyModalOpen(true);
                  }
                }}
              />
            }
            label="Privacy Policy"
          />
          <FormControlLabel
            control={
              <CheckBox
                checked={isHonorAgree}
                onChange={async () => {
                  if (isHonorAgree) {
                    setIsHonorAgree(false);
                  } else {
                    getAgreementData().then();
                    setIsHonorModalOpen(true);
                  }
                }}
              />
            }
            label="Honor Code"
          /> */}
        </div>

        <div className={styles.formBottom}>
          <div />
          <Button
            className={styles.formSubmitButton}
            type="primary"
            disabled={submitDisable}
            isSubmit
          >
            Book an interview
          </Button>
        </div>
      </form>

      {/* <LocationModal */}
      {/*  title="Map" */}
      {/*  isModelOpen={isLocationModal} */}
      {/*  handleClose={onClose} */}
      {/*  setIsOpen={setIsLocationModal} */}
      {/*  address={address} */}
      {/*  setAddress={setAddress} */}
      {/*  setLocationData={setLocationData} */}
      {/*  coordinates={coordinates} */}
      {/*  setCoordinates={setCoordinates} */}
      {/*  background="#FFFFFF" */}
      {/*  isProfileEditing */}
      {/* /> */}
      <PolicyModalWrapper
        isModelOpen={isPolicyModalOpen}
        setIsOpen={setIsPolicyModalOpen}
        handleClose={handleModalClose}
        setIsAgree={setIsPolicyAgree}
        isAgree={isPolicyAgree}
        text={policyText}
        checkboxText="I agree to the Privacy Policy"
        loading={isPolicyLoading}
      />
      <PolicyModalWrapper
        isModelOpen={isHonorModalOpen}
        setIsOpen={setIsHonorModalOpen}
        handleClose={handleModalClose}
        setIsAgree={setIsHonorAgree}
        isAgree={isHonorAgree}
        text={honorText}
        checkboxText="I accept the Honor Code of TransparenTerra members"
        loading={isPolicyLoading}
      />
    </div>
  );
};

export default ExpertForm;
