import React, { useState } from 'react';
import { Button, Input, InputPassword, Form } from 'tt-ui-lib/core';
import { GoogleIcon } from 'tt-ui-lib/icons';

import styles from '../SignInModal.module.scss';

export const SignInModal = ({ setMode, handler, signInWithGoogle }) => {
  const [form] = Form.useForm();

  const [error, setError] = useState('');
  const [submitDisable, setSubmitDisable] = useState(false);

  const submit = async (data) => {
    setError('');
    setSubmitDisable(true);

    await handler(data, setError, () => {
      setSubmitDisable(false);
    });
  };

  return (
    <div className={styles.formWrapper}>
      <Form className={styles.form} form={form} onFinish={submit}>
        <Form.Item
          name="username"
          className={styles.inputWrapper}
          rules={[{ required: true, message: 'Please enter your email address' }]}
        >
          <Input label="Email *" />
        </Form.Item>

        <Form.Item
          name="password"
          className={styles.inputWrapper}
          rules={[{ required: true, message: 'Please enter your password' }]}
        >
          <InputPassword label="Password *" />
        </Form.Item>

        {error && (
          <div className={styles.formError}>
            {error.trim() === 'Authentication exception' ? (
              <p>• Username or password incorrect</p>
            ) : (
              <p>• Server Error</p>
            )}
          </div>
        )}

        <div>
          <div className={styles.formLink} onClick={() => setMode('reset')}>
            Reset password
          </div>
        </div>

        <div className={styles.formBottom}>
          <div className={styles.formLink} onClick={() => setMode('up-ref')}>
            Don`t have an account yet? Sign up
          </div>

          <div className={styles.rightButtons}>
            <button
              type="button"
              className={styles.googleBtn}
              disabled={submitDisable}
              onClick={signInWithGoogle}
            >
              <GoogleIcon />
            </button>

            <Button
              className={styles.formSubmitButton}
              type="primary"
              loading={submitDisable}
              onClick={() => form.submit()}
            >
              Sign in
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
