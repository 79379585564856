import React, { useEffect, useState } from 'react';
import styles from '../CertificatePage.module.scss';
import { Button, Input, Loader } from 'tt-ui-lib/core';
import { RightIcon, SearchIcon } from 'tt-ui-lib/icons';
import { useNavigate } from 'react-router';

const RegisterTab = ({ id, data, setOpenRegisterModal, getCertificateRegisters }) => {
  const navigate = useNavigate();

  const [registersLoading, setRegistersLoading] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [registers, setRegisters] = useState([]);
  const [filteredRegisters, setFilteredRegisters] = useState([]);

  const getData = () => {
    setRegistersLoading(true);

    getCertificateRegisters(
      { carbon_ucr_credit_id: id },
      (response) => {
        setRegisters(response?.data?.getRegistries || []);
        setRegistersLoading(false);
      },
      () => {
        setRegistersLoading(false);
      }
    );
  };

  useEffect(() => {
    setFilteredRegisters(registers);
  }, [registers]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.block}>
      <div className={styles.blockHeader}>
        <h2>{registers.length > 0 ? 'Distribution Lists' : 'Distribution'}</h2>
      </div>
      <div className={styles.blockText}>
        If you, like us, want more people to make offsetting carbon footprint part of their daily
        routine, distribute your carbon credit to your customers. Not only will this show a
        wonderful example of caring for nature, but will definitely give you a marketing advantage
        over your competitors. You can do so by clicking on the “Distribute” button, indicating the
        fraction of the carbon credit to offset your customer’s carbon footprint and the email of
        your customer - the recipient of the offset certificate.
      </div>
      <div className={styles.blockFooter}>
        {registersLoading ? (
          <Loader />
        ) : registers.length > 0 ? (
          <Input
            placeholder="Search..."
            value={searchString}
            onChange={(e) => {
              const search = e.target.value;

              setSearchString(search);
              if (search) {
                const filteredData = registers.filter(
                  (item) =>
                    `${item.name}`.toLowerCase().includes(`${search}`.toLowerCase()) ||
                    `${item.date}`.toLowerCase().includes(`${search}`.toLowerCase())
                );
                setFilteredRegisters(filteredData);
              } else {
                setFilteredRegisters(registers);
              }
            }}
            className={styles.registerSearchInput}
            prefix={<SearchIcon style={{ width: 20, height: 20, flexShrink: 0 }} />}
          />
        ) : (
          <div />
        )}

        <Button
          type="primary"
          disabled={!data}
          onClick={() => setOpenRegisterModal(true)}
          style={{ maxWidth: 315, width: registers.length > 0 ? '100%' : 'unset', flexShrink: 0 }}
        >
          {registers.length > 0 ? 'Create new distribution list' : 'Distribute'}
        </Button>
      </div>

      {filteredRegisters.length > 0
        ? filteredRegisters.map((register) => (
            <div className={styles.registerList}>
              <div
                onClick={() => {
                  navigate(`/dashboard/decarbonizator/certificate/${id}/register/${register.id}`);
                }}
              >
                <div>
                  <p>{register.name || '-'}</p>
                  <p>
                    Edited: <span>{register.date || '-'}</span>
                  </p>
                </div>
                <div>
                  <RightIcon style={{ width: 18, height: 18 }} />
                </div>
              </div>
            </div>
          ))
        : searchString && <div className={styles.registerList}>No items found</div>}
    </div>
  );
};

export default RegisterTab;
