import React from 'react';
import { Modal, Table } from 'tt-ui-lib/core';
import styles from '../CertificatePage.module.scss';

const InfoModal = ({ open, setOpen, id, balance, offsetted, image, transactions }) => {
  const modalClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      key: 'hash',
      dataIndex: 'hash',
      title: 'Txn Hash',
      align: 'left',
      width: 130,
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: 'Date',
      align: 'left',
      width: 150,
    },
    {
      key: 'from',
      dataIndex: 'from',
      title: 'From',
      align: 'left',
      width: 100,
    },
    {
      key: 'to',
      dataIndex: 'to',
      title: 'To',
      align: 'left',
      width: 100,
    },
    {
      key: 'fee',
      dataIndex: 'fee',
      title: 'Tnx Fee',
      align: 'left',
      width: 100,
    },
    {
      key: 'value',
      dataIndex: 'value',
      title: 'Value',
      align: 'left',
      width: 140,
    },
  ];

  return (
    <Modal
      open={open}
      onClose={modalClose}
      title={`Certificate ${id}`}
      className={styles.infoModalRoot}
    >
      <div className={styles.infoModal}>
        <div className={styles.infoModalCertData}>
          <div className={styles.infoModalCertDataRows}>
            <p>Current balance: {balance || '-'}</p>
            <p>Offsetted: {offsetted || '-'}</p>
          </div>
          <div className={styles.infoModalImage}>
            <img src={image} alt="Certificate not found..." />
          </div>
        </div>

        <div
          className={
            transactions.length > 0
              ? styles.infoModalTableContainer
              : styles.infoModalTableContainerEmpty
          }
        >
          <p>Transaction history</p>

          <Table columns={columns} data={transactions} />
        </div>
      </div>
    </Modal>
  );
};

export default InfoModal;
