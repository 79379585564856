import React, { useEffect, useState } from 'react';
import { NewRegisterPage } from '../../../modules/tt-tokenized-assets';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CARBON_CREATE_REGISTER_ENTRY,
  CARBON_DELETE_REGISTER_ENTRY,
  CARBON_GET_REGISTER,
  CARBON_GET_REGISTER_ENTRIES_BY_ID,
  CARBON_GET_REGISTER_ENTRY,
  CARBON_UPDATE_REGISTER,
  CARBON_UPDATE_REGISTER_ENTRY,
} from '../../../api';
import { openNotification } from 'tt-ui-lib/core';

const RegisterPageView = () => {
  const navigate = useNavigate();
  const { id, certId } = useParams();

  const [getRegisterDataRequest] = useLazyQuery(CARBON_GET_REGISTER, {
    fetchPolicy: 'network-only',
  });
  const [getRegisterEntryDataRequest] = useLazyQuery(CARBON_GET_REGISTER_ENTRY, {
    fetchPolicy: 'network-only',
  });
  const [getRegisterEntriesRequest] = useLazyQuery(CARBON_GET_REGISTER_ENTRIES_BY_ID, {
    fetchPolicy: 'network-only',
  });
  const [createRegisterEntryRequest] = useMutation(CARBON_CREATE_REGISTER_ENTRY);
  const [updateRegisterEntryRequest] = useMutation(CARBON_UPDATE_REGISTER_ENTRY);
  const [deleteRegisterEntryRequest] = useMutation(CARBON_DELETE_REGISTER_ENTRY);
  const [saveUpdatesRegisterRequest] = useMutation(CARBON_UPDATE_REGISTER);

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const getRegisterData = (payload, onSuccess, onError) => {
    setIsLoading(true);

    getRegisterDataRequest({ variables: payload })
      .then((response) => {
        setData(response?.data?.getRegistry || null);

        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((e) => {
        if (onError) {
          openNotification({
            type: 'error',
            message: e?.message || 'Something went wrong, please try again',
          });

          onError(e);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getRegisterEntries = (payload, onSuccess, onError) => {
    getRegisterEntriesRequest({ variables: payload })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((e) => {
        if (onError) {
          onError(e);
        }
      });
  };

  const createNewRegisterEntity = (payload, onSuccess, onError) => {
    createRegisterEntryRequest({ variables: { input: payload } })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((e) => {
        if (onError) {
          onError(e);
        }
      });
  };

  const getRegisterEntryData = (payload, onSuccess, onError) => {
    getRegisterEntryDataRequest({ variables: payload })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((e) => {
        if (onError) {
          onError(e);
        }
      });
  };

  const updateRegisterEntity = (payload, onSuccess, onError) => {
    updateRegisterEntryRequest({ variables: { input: payload } })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((e) => {
        if (onError) {
          onError(e);
        }
      });
  };

  const deleteRegisterEntity = (payload, onSuccess, onError) => {
    deleteRegisterEntryRequest({ variables: payload })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((e) => {
        if (onError) {
          onError(e);
        }
      });
  };

  const getRegisterForm = (payload, onSuccess, onError) => {
    // TODO
  };

  const saveRegisterForm = (payload, onSuccess, onError) => {
    // TODO
  };

  const saveRegister = (payload, onSuccess, onError) => {
    saveUpdatesRegisterRequest({ variables: { input: payload } })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((e) => {
        if (onError) {
          onError(e);
        }
      });
  };

  const sendRegister = (payload, onSuccess, onError) => {
    // TODO
  };

  useEffect(() => {
    if (id) {
      getRegisterData({ id: id });
    }
  }, []);

  return (
    <>
      <NewRegisterPage
        navigate={navigate}
        id={id}
        certId={certId}
        data={data}
        isLoading={isLoading}
        getRegisterData={getRegisterData}
        getRegisterEntries={getRegisterEntries}
        createNewRegisterEntity={createNewRegisterEntity}
        getRegisterEntryData={getRegisterEntryData}
        updateRegisterEntity={updateRegisterEntity}
        deleteRegisterEntity={deleteRegisterEntity}
        getRegisterForm={getRegisterForm}
        saveRegisterForm={saveRegisterForm}
        saveRegister={saveRegister}
        sendRegister={sendRegister}
        mode="fin"
        backUrl={`/dashboard/financials/certificate/${certId}`}
      />
    </>
  );
};

export default RegisterPageView;
