import React from 'react';
import { Button, Modal, Input, Form } from 'tt-ui-lib/core';
import styles from './NewRegisterPage.module.scss';

const NewEntityModal = ({ registerName, open, setOpen, onSubmit, isLoading }) => {
  const [form] = Form.useForm();

  const submitForm = (data) => {
    onSubmit(data);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      title={`Add new customer to the ${registerName || 'Distribution List'}`}
    >
      <Form className={styles.entryModalGrid} form={form} onFinish={submitForm}>
        <div>
          <Form.Item name="id" rules={[{ required: true, message: 'ID is required' }]}>
            <Input label="ID*" />
          </Form.Item>
          <Form.Item
            name="amount"
            rules={[
              { required: true, message: 'Balance is required' },
              {
                pattern: /^\d+$/,
                message: 'Provide correct balance',
              },
            ]}
          >
            <Input label="Balance*" />
          </Form.Item>
        </div>
        <div>
          <Form.Item name="name" rules={[{ required: false }]}>
            <Input label="Name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Provide correct email',
              },
            ]}
          >
            <Input label="Email" />
          </Form.Item>
        </div>

        <Form.Item
          name="wallet"
          rules={[
            {
              pattern: /^(0x)?[0-9a-fA-F]{40}$/,
              message: 'Provide correct wallet address',
            },
          ]}
        >
          <Input label="Wallet" />
        </Form.Item>
      </Form>

      <div className={styles.modalRow}>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
          loading={isLoading}
        >
          Add
        </Button>
      </div>
    </Modal>
  );
};

export default NewEntityModal;
