import React, { useEffect, useState } from 'react';
import styles from './Cart.module.scss';

import { Button, Loader } from 'tt-ui-lib/core';
import { ShopCartIcon } from 'tt-ui-lib/icons';

import CartItem from '../CartItem';
import { useLocation, useNavigate } from 'react-router';

import CartActionMenu from '../CartActionMenu';

export const Cart = ({
  cartItems,
  deleteItemRequest,
  notMarket,
  cartIsLoading,
  fetchCartDataWidget,
  setCartDeleteTriggered,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get('activeTab');
  const [cartProducts, setCartProducts] = useState([]);
  const [outOfView, setOutOfView] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [allProductsId, setAllProductsId] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const endpointMarket = process.env.REACT_APP_MARKET || '';

  const redirectMarket = (url) => {
    window.location.href = `${endpointMarket}${url}`;
  };

  const sortCartList = () => {
    if (cartItems.length > 0) {
      const allProducts = cartItems.flatMap((cart) => cart.products);

      const allCartIds = cartItems
        .flatMap((cart) => cart.products)
        .flatMap((product) => product.carts)
        .map((cart) => cart.id);
      setAllProductsId(allCartIds);

      const viewProducts = allProducts.slice(-3).reverse();
      setCartProducts(viewProducts);
      if (allProducts.length > 3) {
        const restProducts = allProducts.length - 3;
        setOutOfView(restProducts);
      } else {
        setOutOfView(0);
      }

      const numbOfProducts = allProducts.reduce(
        (sum, product) => sum + product.carts[0].quantity,
        0
      );
      setTotalProducts(numbOfProducts);

      const tPrice = allProducts.reduce((sum, product) => {
        const cartItem = product.carts[0];
        const price =
          cartItem.shopProductPrice.productDiscountPrice ?? cartItem.shopProductPrice.productPrice;
        return sum + price * cartItem.quantity;
      }, 0);
      setTotalPrice(tPrice);
    }
  };

  useEffect(() => {
    if (cartItems.length === 0) {
      setCartProducts([]);
      setOutOfView(0);
      setTotalProducts(0);
      setTotalPrice(0);
    } else {
      sortCartList();
    }
  }, [cartItems]);

  const clearCart = async () => {
    const input = {
      id: allProductsId,
    };
    try {
      const response = await deleteItemRequest({
        variables: {
          input: input,
        },
      });

      if (response.data?.destroyUserCart) {
        setLoadingDelete(true);
        setCartDeleteTriggered(true);
        setCartProducts([]);
        setTotalProducts(0);
        setTotalPrice(0);
        await fetchCartDataWidget();
      }
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <div className={styles.cartContainer}>
      <div className={styles.cartHeader}>
        <div className={styles.cartHeaderTitle}>
          <ShopCartIcon style={{ width: '20px', height: '20px' }} />
          <p>My Cart</p>
        </div>
        <CartActionMenu clearCart={clearCart} />
      </div>
      {!cartIsLoading && !loadingDelete ? (
        <>
          <div className={styles.cartItemsContainer}>
            <div className={styles.cartItemsList}>
              {cartProducts.length > 0 ? (
                <>
                  {cartProducts.map((item) => (
                    <CartItem key={item.id} item={item} />
                  ))}
                  {outOfView !== 0 && (
                    <a
                      style={{ textDecoration: 'underline' }}
                      onClick={() =>
                        notMarket ? redirectMarket('/dashboard/cart') : navigate('/dashboard/cart')
                      }
                    >
                      {outOfView} more items
                    </a>
                  )}
                </>
              ) : (
                <p>Your cart is currently empty</p>
              )}
            </div>
          </div>
          <div className={styles.cartSummary}>
            <div className={styles.cartInfo}>
              <div className={styles.cartTotalProducts}>
                <p>Total:</p>
                <p>({totalProducts} products)</p>
              </div>
              <p>${totalPrice.toFixed(2)}</p>
            </div>
            {cartProducts.length > 0 ? (
              <div className={styles.cartButtonView}>
                <Button
                  type="primary"
                  onClick={() =>
                    notMarket ? redirectMarket('/dashboard/cart') : navigate('/dashboard/cart')
                  }
                >
                  View cart
                </Button>
              </div>
            ) : (
              <div className={styles.cartButtonStart}>
                <Button
                  onClick={() =>
                    notMarket
                      ? redirectMarket('/dashboard/marketplace/list/product')
                      : navigate('/dashboard/marketplace/list/product')
                  }
                >
                  Start shopping
                </Button>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      )}
    </div>
  );
};
