import React from 'react';
import { KycModalWrapper } from './modals/KycModal';
import { PolicyModalWrapper } from './modals/PolicyModal';
import { SignInUpModalWrapper } from './modals/SignInUpModal';
import { ModalInv } from './modals/InviteModal';
import YotiModal from './modals/KycModal/YotiModal';
import { ShareModalWrapper } from './modals/ShareModal';

export const SignInUpModal = ({
  open,
  openMode,
  closeModal,
  loginHandler,
  signUpHandler,
  signUpRefHandler,
  agreementSchema,
  signInWithGoogle,
}) => (
  <SignInUpModalWrapper
    open={open}
    openMode={openMode}
    closeModal={closeModal}
    loginHandler={loginHandler}
    signUpHandler={signUpHandler}
    signUpRefHandler={signUpRefHandler}
    agreementSchema={agreementSchema}
    signInWithGoogle={signInWithGoogle}
  />
);

export const KycModal = ({ isOpen, setIsOpen, userId, userKyc }) => (
  <KycModalWrapper isOpen={isOpen} setIsOpen={setIsOpen} userId={userId} userKyc={userKyc} />
);

export const PolicyModal = ({
  isModelOpen,
  setIsOpen,
  handleClose,
  setIsAgree,
  isAgree,
  checkboxText,
  text,
  loading,
}) => (
  <PolicyModalWrapper
    isModelOpen={isModelOpen}
    setIsOpen={setIsOpen}
    handleClose={handleClose}
    setIsAgree={setIsAgree}
    isAgree={isAgree}
    checkboxText={checkboxText}
    text={text}
    loading={loading}
  />
);

export const InviteModal = ({
  visible,
  title,
  onClose,
  roleQuery,
  sendInviteQuery,
  setInvitations = false,
}) => (
  <ModalInv
    visible={visible}
    title={title}
    onClose={onClose}
    roleQuery={roleQuery}
    sendInviteQuery={sendInviteQuery}
    setInvitations={setInvitations}
  />
);

export const ShareModal = ({ open, closeModal, link }) => (
  <ShareModalWrapper open={open} closeModal={closeModal} link={link} />
);

export const KYCYotiModal = ({ isModelOpen, setIsOpen, handleClose, yotiUrl }) => (
  <YotiModal
    isModelOpen={isModelOpen}
    setIsOpen={setIsOpen}
    handleClose={handleClose}
    yotiUrl={yotiUrl}
  />
);

export { default as AvatarCropModal } from './modals/AvatarCropModal/AvatarCropModal';
export { default as BlockedModal } from './modals/BlockedModal/BlockedModal';
export { default as GoogleAuthModal } from './modals/GoogleAuthModal/GoogleAuthModal';
export { default as AddProposalModal } from './modals/AddProposalModal';
export { default as ConsentModal } from './components/ConsentModal';

export { default as LocationModal } from './modals/LocationModal';
export { default as VerifyEmailModal } from './modals/VerifyEmailModal';

export { default as ModalContainer } from './components/ModalContainer';
export { default as UpgradeRoleModal } from './modals/UpgradeRoleModal';
export { default as ContactSupportModal } from './modals/ContactSupportModal/ContactSupportModal';
export { default as LeaveAlertModal } from './modals/LeaveAlertModal';
